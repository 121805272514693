import React, { FC, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../store'
import { getBankName, getBankColor, Banks, moneyPattern } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { Button, Form, Modal, InputNumber, Typography, Row, Col, message, Card, Flex } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import api from '../../helpers/api'
import { vizpayOrderInquiry } from '../../@types/vizpay'
import BankQrCodeCapture from './BankQrCodeCapture'
import { Member } from '../../@types/member'
import { CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import NumberWithCommas from '../../helpers/NumberWithCommas'
import { QRPaymentName } from '../../helpers/QRPaymentName'

// เพิ่ม plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// กำหนด timezone (เช่น Asia/Bangkok)
const timeZone = 'Asia/Bangkok';

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
	onOpen: () => void
}

const BankQrCodeOutTime: FC<Props> = ({ isOpen, onClose, onOpen }) => {
	const history = useHistory();
	const [messageApi, contextHolder] = message.useMessage();
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [form] = Form.useForm();
	const [qrCodeModal, setQrCodeModal] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [amount, setAmount] = useState<number>(0) // จำนวนเงินที่ต้องการเติม
	const [isNewProgress, setIsNewProgress] = useState<boolean>(false) // สถานะ : มีธุรกรรมที่กำลังดำเนินการอยู่
	const [isTimeOut, setIsTimeOut] = useState<boolean>(false)  // สถานะ : หมดเวลาทำรายการ
	const [isSuccess, setIsSuccess] = useState<boolean>(false)  // สถานะ : ทำรายการสำเร็จ
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info
	const [timer, setTimer] = useState<number>(0);
	const [stoppedTimer, setStoppedTimer] = useState<boolean>(false);
	const [depositQRCode, setDepositQRCode] = useState<any>()
	const [depositNumberPayment, setDepositNumberPayment] = useState<any>()
	const [depositNamePayment, setDepositNamePayment] = useState<string>("")

	const secondsToMinutes = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')} นาที`;
		return formattedTime;
	}

	const handleSubmit = async () => {
		setSubmitting(true)
		form.validateFields()
			.then(async (values: any) => {
				if (values.amount <= 0) {
					setSubmitting(false)
					return false
				}
				try {

					setAmount(values.amount)

					// const res: any = await api.post('/deposit/vizpay-qr', {
					// 	confirmGenerateQR: isNewProgress,
					// 	amount: values.amount
					// })

					// localStorage.setItem("qr-pay", JSON.stringify(res))

					setSubmitting(false)
					onClose()

					const res = {
						"data": {
							"ref_id": "bdba3d28-dbe60605-f0d86270-8656f0f9",
							"order_id": "PGS678202406192235462",
							"price": NumberWithCommas(values.amount, 2),
							"amount": NumberWithCommas(values.amount, 2),
							"redirect_url": "",
							"status": "CREATE",
							"image": "https://promptpay.io/0215566010596",
							// "timeout": {
							// 	"days": "2024-06-19",
							// 	"time": "23:05:46",
							// 	"date": "2024-06-19T23:05:46+07:00"
							// }
						},
						"message": "กรุณาสแกน QRCode ด้านล่างเพื่อทำการเติมเงิน",
						"phoneNumberOrID": "0215566010596"
					}

					const dateString = dayjs().tz(timeZone).add(30, 'minute').format();
					const inDate = new Date(dateString)

					const currentDate = new Date()

					// คำนวณระยะเวลาและแปลงให้อยู่ในรูปของวินาที
					const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
					const timeCurrent = timeInSeconds * -1
					const timeFinal = parseInt(timeCurrent.toString(), 10)

					setTimer(timeFinal)

					setDepositQRCode(res.data)
					setDepositNumberPayment(res.phoneNumberOrID)
					const NamePayment = QRPaymentName[res.phoneNumberOrID] || ""
					setDepositNamePayment(NamePayment)
					setQrCodeModal(true)
					setStoppedTimer(false)
				} catch (error: any) {
					toast.error(error.message as string)
					setSubmitting(false)
				}
			}).catch((errorInfo) => {
				setSubmitting(false)
			});
	}
	const handleTimeOut = () => {
		localStorage.removeItem("qr-pay")
		setQrCodeModal(false)
		setIsTimeOut(false)
		onOpen()
		setIsNewProgress(true)
	}

	const getStatusProcess = (orderId: any, refId: any) => {
		const res = api.post<vizpayOrderInquiry>('/deposit/vizpay-order-inquiry', {  // eslint-disable-line
			orderId: orderId,
			refId: refId
		}).then(data => {
			if (data.data.status === 'SUCCESS') {
				setStoppedTimer(true)
				setIsSuccess(true)
				localStorage.removeItem("qr-pay")
			}
		})
	}

	const getStatusNewProcess = (orderId: any, refId: any) => {
		const res = api.post<vizpayOrderInquiry>('/deposit/vizpay-order-inquiry', {  // eslint-disable-line
			orderId: orderId,
			refId: refId
		}).then(data => {
			if (data.data.status === 'CREATE') {
				const qrPay = localStorage.getItem("qr-pay")
				if (qrPay) {
					const parseQr = JSON.parse(qrPay)

					setSubmitting(false)
					onClose()

					const dateString = parseQr.data.timeout.date
					const inDate = new Date(dateString)

					const currentDate = new Date()

					// คำนวณระยะเวลาและแปลงให้อยู่ในรูปของวินาที
					const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
					const timeCurrent = timeInSeconds * -1
					const timeFinal = parseInt(timeCurrent.toString(), 10)

					setTimer(timeFinal)

					setDepositQRCode(parseQr.data)
					setDepositNumberPayment(parseQr.phoneNumberOrID)
					const NamePayment = QRPaymentName[parseQr.phoneNumberOrID] || ""
					setDepositNamePayment(NamePayment)
					setQrCodeModal(true)
					setStoppedTimer(false)
				} else {
					onCloseProcess()
				}
			}
			if (data.data.status === 'SUCCESS') {
				setStoppedTimer(true)
				setIsSuccess(true)
				localStorage.removeItem("qr-pay")
			}
			if (data.data.status === 'FAILED') {
				setStoppedTimer(true)
				setIsSuccess(true)
				localStorage.removeItem("qr-pay")
			}
		})
	}

	const onCloseProcess = () => {
		setStoppedTimer(true)
		setIsNewProgress(true)
		setIsTimeOut(false)
		localStorage.removeItem("qr-pay")
	}

	const changeAmount = (amount: number) => {
		form.setFieldsValue({ amount: amount })
	}

	const copyAccountNumber = (number: any) => {
		navigator.clipboard.writeText(number)
		messageApi.open({
			type: 'success',
			content: 'คัดลอกเลขพร้อมเพย์สำเร็จ',
		});
	}

	useEffect(() => {
		if (qrCodeModal && !stoppedTimer) {
			const countdown = setInterval(() => {
				if (timer <= 0) {
					clearInterval(countdown);
					setIsTimeOut(true)
				} else {
					setTimer(timer - 1);
				}
			}, 1000);

			return () => clearInterval(countdown);
		}
	}, [qrCodeModal, timer, stoppedTimer]); // eslint-disable-line

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': amount
			})
		}

		if (isOpen) {
			// const qrPay: any = localStorage.getItem("qr-pay")
			// if (qrPay) {
			// 	const parseQr = JSON.parse(qrPay)
			// 	getStatusNewProcess(parseQr.data.order_id, parseQr.data.ref_id)
			// } else {
			// 	setIsNewProgress(true)
			// }
			setIsNewProgress(true)
		}

		if (qrCodeModal) {
			const getStatusInterval = setInterval(() => {
				getStatusProcess(depositQRCode.order_id, depositQRCode.ref_id)
			}, 5000);

			return () => clearInterval(getStatusInterval);
		}
	}, [isOpen, amount, form, qrCodeModal]) // eslint-disable-line

	useEffect(() => {
		if (!qrCodeModal) return

		const canvas = canvasRef.current
		if (!canvas) return
		canvas.width = 280
		canvas.height = 200

		const ctx = canvas.getContext('2d');
		if (!ctx) return

		const image = new Image()
		image.crossOrigin = 'anonymous'

		image.onload = () => {
			ctx.drawImage(image, (canvas.width - 200) / 2, 0, 200, 200)

			ctx.save()
			ctx.translate((canvas.width / 2), (canvas.height / 2))
			ctx.rotate((-25 * Math.PI) / 180)

			ctx.font = '24px Arial'
			ctx.fillStyle = 'rgba(255, 0, 0, 0.6)'
			ctx.textAlign = 'center'
			ctx.fillText('ใช้สำหรับเติมเว็บพนันเท่านั้น', 0, 0)

			ctx.restore()
		};

		image.onerror = (err) => {
			console.error('Failed to load image', err);
		};

		if (depositQRCode?.image) {
			image.src = depositQRCode.image;
		} else {
			console.error('depositQRCode.image is not defined');
		}
	}, [qrCodeModal])

	return (
		<>
			{contextHolder}

			{/* Step 1 */}
			<Modal
				className="ant-modal-deposit"
				open={isOpen && isNewProgress}
				title="เติมเงินผ่าน พร้อมเพย์"
				centered
				onCancel={() => { !isSubmitting && onClose(); onCloseProcess() }}
				footer={null}
			>
				<Form
					form={form}
					name="truewalletVoucherForm"
					layout="vertical"
					hideRequiredMark
				>
					<Row>
						<Col span={24}>
							<Form.Item
								name="amount"
								label="จำนวนเงินที่ต้องการเติม"
								rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม' }]}
								style={{ marginBottom: 0 }}
							>
								<InputNumber
									placeholder="0"
									inputMode="numeric"
									prefix="฿"
									controls={false}
									formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={(value) => (typeof value === 'string' ? value.replace(/[^\d]/g, '') : '')}
									onKeyPress={(event) => {
										if (!/^[0-9.,\b]+$/.test(event.key) || event.key === '.' || event.key === ',') {
											event.preventDefault();
										}
									}}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: 10 }}>
							<Row gutter={[16, 8]}>
								{moneyPattern.map((value, index) => (
									<Col span={6} key={index}>
										<Button key={value} size='small' block onClick={() => changeAmount(value)}>{format(value, 0)}</Button>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
					<Button
						type="primary"
						htmlType="submit"
						loading={isSubmitting}
						disabled={isSubmitting}
						onClick={handleSubmit}
						style={{ margin: '16px 0 12px' }}
						block
					>
						เติมเงิน
					</Button>
				</Form>
			</Modal >

			{/* Step 2 */}
			<Modal
				open={qrCodeModal}
				title="เติมเงินผ่าน พร้อมเพย์"
				footer={null}
				onCancel={() => { setQrCodeModal(false); setIsNewProgress(false) }}
				centered
			>
				{!isSuccess && !isTimeOut && (
					<>
						<div className="payments-main">
							<img src={"promptPay/thai_qr_payment2.png"} alt="qr-thai" style={{ borderRadius: ' 12px 12px 0 0', width: "100%", display: 'block', margin: '0 0 -2px 0', objectFit: 'cover', zIndex: 5 }} />
							<Card
								title={null}
								style={{ width: "100%" }}
							>
								<div style={{ marginTop: 16, textAlign: 'center', lineHeight: 1.3 }}>
									<div style={{ color: '#666' }}>เบอร์พร้อมเพย์</div>
									<div style={{ color: '#0e3e68', fontSize: 20, fontWeight: 'bold' }} onClick={() => { copyAccountNumber(depositNumberPayment) }}>
										{depositNumberPayment} <i className="fa-sharp fa-regular fa-copy" style={{ fontSize: 16, marginLeft: 2 }}></i>
									</div>
									{depositNamePayment && (
										<div style={{ color: '#666', fontSize: 22 }}>
											{depositNamePayment}
										</div>
									)}
								</div>
								{/* <canvas ref={canvasRef} width={256} height={256} style={{ margin: '0 auto', display: 'block' }} /> */}
								<img src={depositQRCode?.image} alt={`qrCode`} style={{ display: 'block', margin: '0 auto', width: 180 }} />
								<div style={{ textAlign: 'center', marginTop: 4, marginBottom: 8, lineHeight: 1.3 }}>
									<div>
										<Text style={{ color: "#000", fontSize: 20 }}>ยอดเงิน <strong style={{ color: '#03ab47' }}>{depositQRCode?.amount}</strong> บาท</Text>
									</div>
									<div >
										<Text type="secondary" style={{ color: "#ff0000" }}><small>สแกนภายใน {secondsToMinutes(timer)}</small></Text>
									</div>
								</div>
								<div style={{ display: 'flex', margin: '12px -16px -16px -16px', borderTop: '1px solid #efefef' }}>
									<div style={{ color: '#212121', flex: '1', textAlign: 'center', padding: '12px 0' }} onClick={handleTimeOut}>
										<i className="fa-regular fa-refresh" style={{ fontSize: 16, marginRight: 4 }}></i> ทำรายการใหม่
									</div>
									<div style={{ color: '#212121', flex: '1', textAlign: 'center', padding: '12px 0', borderLeft: '1px solid #efefef' }}>
										<BankQrCodeCapture
											qrCodeUrl={depositQRCode?.image}
											bankLogoUrl={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
											info={info as Member}
											amount={format(depositQRCode?.amount || amount, 2)}
										/>
									</div>
								</div>
							</Card>
						</div>
						<div className="notice-card danger" style={{ marginTop: 8, marginBottom: 8 }}>
							<Text className="notice-card-text" style={{ paddingLeft: 0 }}>
								ใช้ <span style={{ color: '#dc4446' }}>ชื่อ/เลขบัญชี</span> ที่สมัคร <span style={{ color: '#dc4446' }}>สแกน QR Code </span>เท่านั้น
							</Text>
						</div>
						<div className="bank-card" style={{ marginBottom: 8 }}>
							<div
								className="bank-card-logo"
								style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info?.bankCode)}` }}
							>
								<img
									src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
									alt={getBankName(info?.bankCode)}
								/>
							</div>
							<div className="bank-card-info">
								<div><small>{getBankName(info?.bankCode)}</small></div>
								<div className="bank-deposite-account">{info?.bankNumber}</div>
								<div><small>{info?.firstName} {info?.lastName}</small></div>
							</div>
						</div>
					</>
				)}

				{isSuccess && (
					// ทำรายการสำเร็จ
					<div style={{ textAlign: 'center', padding: '18px 0', lineHeight: 1.5 }}>
						<CheckCircleOutlined rev={undefined} style={{ color: '#33cb00', fontSize: 56, marginBottom: 16 }} />
						<div>
							<Text style={{ fontSize: 20 }}>รับเงินเข้าสำเร็จ {amount} บาท สำเร็จ</Text>
						</div>
						<div style={{ marginBottom: 12 }}>
							<Text type="secondary" style={{ fontSize: 16 }}><small>ระบบกำลังเติมเงินเข้ากระเป๋า กรุณารอสักครู่</small></Text>
						</div>
						<Button className="ant-btn-dark" onClick={() => history.push(makePath('/wallet'))}>กลับหน้าแรก</Button>
					</div>
				)}

				{isTimeOut && (
					// หมดเวลาทำรายการ
					<div style={{ textAlign: 'center', padding: '18px 0' }}>
						<ExclamationCircleOutlined rev={undefined} style={{ color: '#dc0303', fontSize: 56, marginBottom: 16 }} />
						<div style={{ marginBottom: 12 }}>
							<Text>หมดเวลาทำรายการ</Text>
						</div>
						<Button className="ant-btn-dark" onClick={handleTimeOut}>ทำรายการใหม่</Button>
					</div>
				)}
			</Modal>
		</>
	)
}

export default BankQrCodeOutTime
