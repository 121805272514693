import React, { FC, MouseEvent, useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { IPGOONLoginResponse, IPGOONGame } from '../../../entities/PGOONType'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Empty, Input, Space, Typography, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
// import { findCategoryGame } from '../../../utils/subCategoryGame'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../../../helpers/path'
import { format } from '../../../helpers/number'
import { Games } from '../../../helpers/games'

const { Title } = Typography

interface GameListProps {
	games: IPGOONGame[] | any
	// gameType: string
	pathName: string
	loadingProvider: boolean

	// setProvider: (name: string) => void
	// setLoadingProvider: (e: boolean) => void
	setLoading: (e: boolean) => void
}

interface Favorites {
	[key: string]: string;
}

const GameList: FC<GameListProps> = ({
	games, pathName, setLoading, loadingProvider
}) => {
	const location = useLocation();
	// const ambkProviderTabContainerRef: any = useRef();
	// const ambkProviderTabRef: any = useRef();
	const ambkTopbarRef: any = useRef();
	const history = useHistory()
	const [messageApi, contextHolder] = message.useMessage();

	const [configGames, setConfigGames] = useState<any>(undefined)
	const [filterText, setFilterText] = useState('')
	// const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(['all']);
	// const [categoryGame, setCategoryGame] = useState([]);
	// const [activeFavorites, setActiveFavorites] = useState<any>({});
	// const [useEffectExecuted, setUseEffectExecuted] = useState(false);

	const getGameCover = (gameCode: number) => {
		const gameCover = Games.filter((game) => game.gameCode === +gameCode)[0]
		return gameCover ? gameCover.image.vertical : '';
	}

	const getGameRtp = (gameCode: number) => {
		const gameRtp = Games.filter((game) => game.gameCode === +gameCode)[0]
		return gameRtp ? gameRtp.rtp : 0;
	}

	const params = new URLSearchParams(location.search);
	const gameFilter = params.get('gameFilter');

	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, IPGOONLoginResponse>('/pgoon/login', {
				gameID: gameID,
				// isMobile: isMobile(),
			})
			history.push(makePath('play?game=' + btoa(res.url) + '&path=' + btoa(history.location.pathname + history.location.search)))
			setTimeout(() => {
				setLoading(false)
			}, 2000);
		} catch ({ message }: any) {
			toast.error(message as string)
			setLoading(false)
		}
	}

	// categories game filter
	// const handleSubCategoryClick = (type: string) => {
	// 	const params = new URLSearchParams(location.search);

	// 	if (type === 'all') {
	// 		setSelectedSubCategories(['all']);
	// 		params.set('gameFilter', 'all');
	// 		history.push({ search: params.toString() });
	// 	} else {
	// 		const updatedTypes = selectedSubCategories.includes(type)
	// 			? selectedSubCategories.filter((t) => t !== type)
	// 			: [...selectedSubCategories, type];

	// 		// ถ้ามีการเลือกประเภทอื่น ๆ ให้ in-active "ทั้งหมด"
	// 		if (updatedTypes.length > 1 && updatedTypes.includes('all')) {
	// 			const filteredTypes = updatedTypes.filter((t) => t !== 'all');
	// 			setSelectedSubCategories(filteredTypes);
	// 			params.set('gameFilter', filteredTypes.join(', '));
	// 			history.push({ search: params.toString() });
	// 		} else {
	// 			setSelectedSubCategories(updatedTypes);
	// 			params.set('gameFilter', updatedTypes.join(', '));
	// 			history.push({ search: params.toString() });
	// 		}
	// 	}
	// };

	// const getFavorite = async () => {
	// 	try {
	// 		const res = await api.get<void, any>('/ambking/favourites');

	// 		setActiveFavorites((prevActiveFavorites: Favorites) => {
	// 			const newFavorites = { ...prevActiveFavorites };

	// 			res.games.forEach((item: any) => {
	// 				newFavorites[item.id] = item.id;
	// 			});

	// 			return newFavorites;
	// 		});
	// 	} catch (error) {
	// 		toast.error(`Error fetching favorites: ${error}`)
	// 		// console.error('Error fetching favorites:', error);
	// 	}
	// }

	// const handleFavorite = async (gameId: string) => {

	// 	const isGameInFavorites = gameId in activeFavorites;

	// 	setActiveFavorites((prevFavorites: Favorites) => ({
	// 		...prevFavorites,
	// 		[gameId]: gameId,
	// 	}));

	// 	if (isGameInFavorites) {
	// 		const { [gameId]: removedGameId, ...newFavorites } = activeFavorites;
	// 		setActiveFavorites(newFavorites);
	// 	}

	// 	try {
	// 		if (isGameInFavorites) {
	// 			const res = await api.delete<void, any>(`/ambking/favourites/${gameId}`)
	// 			// toast.error(res.message as string)
	// 			messageApi.open({
	// 				type: 'success',
	// 				content: res.message as string,
	// 			});
	// 		} else {
	// 			const res = await api.post<void, any>(`/ambking/favourites/${gameId}`)
	// 			// toast.success(res.message as string)
	// 			messageApi.open({
	// 				type: 'success',
	// 				content: res.message as string,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		// console.error('Error sending API request:', error);
	// 		messageApi.open({
	// 			type: 'error',
	// 			content: `Error sending API request: ${error}`,
	// 		});
	// 	}
	// };

	// useEffect(() => {
	// 	if (pathName === "launch-game") {
	// 		getFavorite();
	// 	}
	// }, []) //eslint-disable-line

	// useEffect(() => {
	// 	const carouselHeight = document.querySelector('.carousel-root') as HTMLElement;

	// 	if (carouselHeight) {
	// 		const marginCarouselHeight = window.innerWidth >= 768 ? 32 : 1;
	// 		window.scrollTo({ top: carouselHeight.clientHeight + marginCarouselHeight, behavior: 'smooth' });
	// 	} else {
	// 		window.scrollTo({ top: 0, behavior: 'smooth' });
	// 	}
	// }, [selectedSubCategories])

	useEffect(() => {
		if (games) {
			let gameMain = games
			if (gameMain === undefined) {
				history.push(makePath(history.location.pathname))
			} else {
				// setCategoryGame(gameMain.gameType)

				let filteredItems = (gameMain as any).filter(
					(item: any) => (
						item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
						// (selectedSubCategories.includes('all') ?
						// 	(item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
						// 	:
						//  (item.gameType && selectedSubCategories.some(category => item.gameType.includes(category))) &&
						// 	(item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
						// )
					),
				);

				setConfigGames(filteredItems);
			}
		} else {
			history.push(makePath(history.location.pathname))
		}
	}, [games, filterText]) //eslint-disable-line

	// auto scroll provider tab
	// const funcScrollProviderTab = () => {
	// 	const scrollContainer = ambkProviderTabContainerRef?.current;
	// 	const scrollItems = ambkProviderTabRef?.current;

	// 	if (scrollContainer && scrollItems) {
	// 		const scrollItemsActive = scrollItems.querySelector('.amb-games-provider-tab-item.active');

	// 		if (scrollItemsActive) {
	// 			const containerRect = scrollItems.getBoundingClientRect();
	// 			const activeItemRect = scrollItemsActive.getBoundingClientRect();

	// 			const distanceFromTop = activeItemRect.top - containerRect.top;
	// 			scrollContainer.scrollTo({ top: distanceFromTop, behavior: 'smooth' });
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	funcScrollProviderTab();

	// 	if (!useEffectExecuted) {
	// 		const gameFilterSearch = gameFilter?.split(', ')
	// 		if (gameFilterSearch) {
	// 			if (gameFilterSearch.length > 0) {
	// 				setSelectedSubCategories(gameFilterSearch);
	// 			}
	// 		}

	// 		setUseEffectExecuted(true);
	// 	}
	// }, [gameFilter, selectedSubCategories, useEffectExecuted]);

	return (
		<>
			{contextHolder}
			<div className="amb-games-container">
				{/* <div className="amb-games-filter">
					<Button
						key={`all`}
						size='small'
						onClick={() => handleSubCategoryClick('all')}
						className={selectedSubCategories.includes('all') ? 'active' : ''}
					>
						{findCategoryGame('all')}
					</Button>
					{categoryGame && categoryGame.length > 0 &&
						categoryGame.map((subCategory) => (
							<Button
								key={`filrer-${subCategory}`}
								size='small'
								onClick={() => handleSubCategoryClick(subCategory)}
								className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
							>
								{findCategoryGame(subCategory)}
							</Button>
						))
					}
				</div> */}

				<div className="amb-games-content">

					{/* games */}
					{loadingProvider ? (
						<div className="ambbet-provider-skeleton-loading">
							{
								Array.from({ length: 24 }, (_, index) => (
									<div className="skeleton-body" key={index}>
										<div className="skeleton" />
									</div>
								))
							}
						</div>
					) : (
						<div style={{ flex: 'auto' }}>
							<div>
								<div className="ambking-games-topbar" ref={ambkTopbarRef}>
									<Space size={0}>
										<Title level={5} className="title-provider" style={{ cursor: 'pointer' }}>{``}</Title>
									</Space>
									<div className="search-games">
										<Input
											placeholder={`ค้นหาเกม`}
											onChange={(e: any) => setFilterText(e.target.value)}
											prefix={<SearchOutlined rev={undefined} style={{ fontSize: 18 }} />}
											style={{ padding: '8px 12px' }}
										/>
									</div>
								</div>
							</div>
							{(configGames && configGames.length > 0 ? (
								<div className="ambking-grid-row">
									{Object(configGames).map((game: any, key: number) => (
										game.id !== "31" && (
											<div key={`game_${key}_${game.id}`} className={`ambking-grid-col ${game.id}`}>
												{pathName === "launch-game" ? (
													<>
														<Link to="/" href="#pg-login" className="game-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
															<div className="game-card-img-container" />
															{game.id ? (
																<img src={getGameCover(game.id)} alt={`${game.id}-${game.name}`} className="game-card-img" />
															) : (
																<img src="/image-placeholder.png" alt={`${game.id}-${game.name}`} className="game-card-img" />
															)}
															{/* <div className="game-card-name">{game.name}</div> */}
															{getGameRtp(game.id) > 0 && (
																<div className="ambking-grid-col-rtp">
																	<span>RTP</span> {format(getGameRtp(game.id), 2)}%
																</div>
															)}
														</Link>
														{/* <div className={`ambking-grid-col-fav ${activeFavorites[game.id] ? 'active' : ''}`} onClick={() => handleFavorite(game.id)}>
														<img src="/icons/3d/favorite.svg" alt="fav icon" />
													</div> */}
													</>
												) : (pathName === "home" && (
													<>
														<Link to={makePath('/login')} key={game.id} className="game-card">
															<div className="game-card-img-container" />
															{game.id ? (
																<img src={getGameCover(game.id)} alt={`${game.id}-${game.name}`} className="game-card-img" />
															) : (
																<img src="/image-placeholder.png" alt={`${game.id}-${game.name}`} className="game-card-img" />
															)}
															{/* <div className="game-card-name">{game.name}</div> */}
															{getGameRtp(game.id) > 0 && (
																<div className="ambking-grid-col-rtp">
																	<span>RTP</span> {format(getGameRtp(game.id), 2)}%
																</div>
															)}
														</Link>
														{/* <div className={`ambking-grid-col-fav`} onClick={() => history.push(makePath('/login'))}>
														<img src="/icons/3d/favorite.svg" alt="fav icon" />
													</div> */}
													</>
												))}
											</div>
										)
									))}
								</div>
							)
								:
								<div style={{ display: 'flex', width: '100%' }}>
									<Empty
										description='ไม่พบเกมส์'
										image="/icons/new/empty-box.svg"
										imageStyle={{ height: 88 }}
										style={{ flex: 'auto', marginTop: 24 }}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default GameList
