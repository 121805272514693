import React, { FC } from 'react'
// import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import { Button, message, Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons';

const { Text } = Typography

interface WalletDepositItemProps {
	bank: DepositBank
}
const WalletDepositItem: FC<WalletDepositItemProps> = ({ bank }) => {
	const [messageApi, contextHolder] = message.useMessage();
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		// toast.success('คัดลอกเลขที่บัญชีสำเร็จ')
		messageApi.open({
			type: 'success',
			content: 'คัดลอกเลขที่บัญชีสำเร็จ',
		});
	}

	return (
		<>
			{contextHolder}
			<div className="bank-deposit-card">
				<div
					className="bank-card-logo"
					style={{
						backgroundColor: '#fff',
						boxShadow: '0 8px 12px -4px #ffffff'
					}}
				>
					<img
						width={56}
						src="/bank-logo/truewallet.svg"
						alt="ทรูมันนี่วอลเล็ท"
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>ทรูมันนี่วอลเล็ท</small></Text></div>
					<Text className="bank-deposite-account">{bank.accountNumber}</Text>
					<div><Text type="secondary"><small>{bank.accountName}</small></Text></div>
				</div>
				<Button
					type="primary"
					onClick={copyAccountNumber}
					style={{ display: 'block', height: 'auto', marginLeft: 'auto' }}
				>
					<CopyOutlined />
					<div><small>คัดลอก</small></div>
				</Button>
			</div>
		</>
	)
}

export default WalletDepositItem
