import actionCreatorFactory from 'typescript-fsa'
import { Prefix } from '../../@types/prefix'
import { ThunkDispatch } from '../../@types/store'
import api from '../../helpers/api'

const actionCreator = actionCreatorFactory('@@fcn/prefix')
export const requestPrefixDone = actionCreator<Prefix>('requestPrefixDone')
export const requestPrefixFailed = actionCreator('requestPrefixFailed')

export const requestPrefix = () => async (dispatch: ThunkDispatch) => {
	try {
		const prefix = await api.get<void, Prefix>('/prefix')

		if (!prefix.promotions) {
			prefix.promotions = []
		}
		prefix.promotions = prefix.promotions.sort((a, b) => {
			const aKey = a.gameType + a.amount + a.key
			const bKey = b.gameType + b.amount + b.key
			if (a.order !== 0) {
				if (b.order === 0) {
					return -1
				}
				if (a.order < b.order) {
					return -1
				} else {
					return 1
				}
			} else {
				if (aKey < bKey) {
					return 1
				} else {
					return -1
				}
			}
		})
		return dispatch(requestPrefixDone(prefix))
	} catch (e) {
		console.error(e)
		return dispatch(requestPrefixFailed())
	}
}
