import moment from 'moment'
import React, { FC } from 'react'
import { Transaction } from '../../@types/transaction'
import { format } from '../../helpers/number'
import { getTxTypeText } from '../../helpers/transaction'
import { Empty, Typography } from 'antd'

const { Text } = Typography

interface PromotionListItemProps {
	tx: Transaction
}
const PromotionListItem: FC<PromotionListItemProps> = ({ tx }) => (
	<div className="transaction-card" key={tx.id}>
		<div className="transaction-header">
			<div className="transaction-info">
				<div className="transaction-title">รับโบนัส</div>
			</div>
			<div className="transaction-title">฿ {format(tx.amount)}</div>
		</div>
		<div className="transaction-body-remark">
			<Text>{tx.remark}</Text>
		</div>
		<div className="transaction-body">
			<div className="tag-default-other">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</div>
			<div className="tag-status-other --warning">* ยอดเทิร์นเครดิต: {format(tx.totalTurn)}</div>
		</div>
	</div>
)

interface OtherListProps {
	data: Transaction[]
}
const OtherList: FC<OtherListProps> = ({ data }) => {
	if (data.length <= 0) {
		return (
			<div style={{ padding: '24px 0' }}>
				<Empty
					description='คุณไม่มีรายการอื่น ๆ'
					image="/icons/new/empty-box.svg"
					imageStyle={{ height: 88 }}
				/>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => {
				if (tx.type === 'promotion') {
					return <PromotionListItem tx={tx} key={tx.id} />
				}
				return (
					<div className="transaction-card" key={tx.id}>
						<div className="transaction-header">
							<div className="transaction-info">
								<div className="transaction-title">{getTxTypeText(tx.type)}</div>
							</div>
							<div className="transaction-title">฿ {format(tx.amount)}</div>
						</div>
						{tx.type === 'redeem' && (
							<div className="transaction-body-remark">
								<Text>{(tx.remark).substring(0, 5) + '*'.repeat((tx.remark).length - 5)}</Text>
							</div>
						)}
						<div className="transaction-body">
							<div className="tag-default-other">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</div>
						</div>
					</div>
				)
			})}
		</>
	)
}

export default OtherList
