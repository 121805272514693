export const mergedGame = (games: any) => {
    let mergedData: any = {}
    for (const typeGame of Object.entries(games)) {
        for (const item of typeGame[1] as any) {
            // const provider = item.provider ? item.provider : (typeGame[0] === "sport" ? "sport_game" : (typeGame[0] === "lotto" ? "lotto_game" : ""))
            const provider = item.provider ? item.provider : ""
            if (!mergedData[provider]) {
                // ถ้าไม่มี Provider ใน mergedData ให้เพิ่มข้อมูล
                mergedData[provider] = item;
            } else {
                // ถ้ามี Provider ใน mergedData ให้เพิ่ม games เข้าไป
                const setGameType = new Set([...mergedData[provider].gameType, ...item.gameType])
                // mergedData[provider].gameType =  new Set(mergedData[provider].gameType.concat(item.gameType));
                mergedData[provider].gameType = setGameType;
                mergedData[provider].games = mergedData[provider].games.concat(item.games);
            }
            // if (provider === "RECG") {
            //     console.log(item);
            // }
        }
    }
    return mergedData
}

export const mergedGameTypeFav = (games: any) => {
    let mergedData: any = []
    for (const typeGame of Object.values(games) as any) {
        const setGameType = new Set([...mergedData, ...typeGame.gameType]);
        mergedData = Array.from(setGameType);
    }

    return mergedData
}