// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { generateQRCodeURL } from '../../helpers/qrCode'
import { truewalletQRDeposit } from '../../services/deposit'
import { Button, Form, Modal, InputNumber, Typography } from 'antd'

const { Text } = Typography

interface TruewalletQRDialogProps {
	isOpen: boolean
	onClose: () => void
}
const TruewalletQRDialog: FC<TruewalletQRDialogProps> = ({ isOpen, onClose }) => {
	const [form] = Form.useForm();
	const [depositQRCode, setDepositQRCode] = useState('')
	const [accountName, setAccountName] = useState('')

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				try {
					const res = await truewalletQRDeposit({
						amount: parseFloat(values.amount.toString()),
					})

					setDepositQRCode(res.data.qrCode)
					setAccountName(res.data.accountName)
				} catch ({ message }: any) {
					toast.error(message as string)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	// watch open state
	useEffect(() => {
		if (isOpen) {
			setDepositQRCode('')
		}

		if (form) {
			form.setFieldsValue({
				'amount': '100'
			})
		}
	}, [isOpen, form])

	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="เติมเงิน ผ่าน QR Code"
			centered
			onCancel={() => !isSubmitting && onClose()}
			footer={[
				<Button
					type="primary"
					shape="round"
					htmlType="submit"
					loading={isSubmitting}
					disabled={isSubmitting}
					onClick={handleSubmit}
					block
				>
					เติมเงิน
				</Button>
			]}
		>
			{depositQRCode ? (
				<div className="truewallet-qr-deposit">
					<img src={generateQRCodeURL(depositQRCode)} alt="QR Code" />
					<h5>{accountName}</h5>
				</div>
			) : (

				<Form
					form={form}
					name="truewalletVoucherForm"
					layout="vertical"
					hideRequiredMark>
					<Form.Item
						name="amount"
						label="จำนวนเงินที่ต้องการเติม"
						rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม และต้องไม่ต่ำกว่า 50 บาท' }]}
						style={{ marginBottom: 0 }}
					>
						<InputNumber
							placeholder="จำนวนเงินที่ต้องการเติม"
							min={50}
							inputMode="numeric"
							controls={false}
							formatter={(value) => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							style={{ width: '100%' }} />
					</Form.Item>
					<Text type="secondary"><small>* {depositQRCode ? 'กรุณาสแกน QR Code ด้วยแอพทรูมันนี่วอลเล็ทเพื่อเติมเงิน' : 'กรุณากรอกจำนวนเงินที่ต้องการเติม'}</small></Text>
				</Form>
			)}
		</Modal>
	)
}

export default TruewalletQRDialog
