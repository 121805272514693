import React, { ChangeEvent, FC } from 'react'
import { bankNames } from '../../helpers/bank'
import { Button, Flex, Form, Input, Select, Typography } from 'antd'
import PatternNumber from '../../helpers/PatternNumber'

interface RegisterStep2Props {
	bankCode: string
	isSubmitting: boolean
	prefixInfo: any

	setBankCode: (e: any) => void
	handleSubmit: (e: ChangeEvent<any>) => void
}

const RegisterStep2: FC<RegisterStep2Props> = ({
	bankCode,
	prefixInfo,
	setBankCode,
	isSubmitting,
	handleSubmit,
}) => {
	return (
		<>
			<Form.Item
				name="bankCode"
				label="ธนาคาร"
				rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}
				style={{ marginBottom: 12 }}
				hasFeedback>
				<Select
					placeholder="เลือกธนาคารของท่าน ..."
					showSearch
					optionFilterProp="children"
					filterOption={(input: any, option: any) =>
						(option?.label ?? '').includes(input)
					}
					onSelect={setBankCode} >
					{
						Object.keys(bankNames).map((vBankCode) => (
							prefixInfo.functions.truewalletRegister ? (
								<Select.Option key={vBankCode} value={vBankCode} label={bankNames[vBankCode]}>
									<Flex gap={'small'} align='center'>
										<img
											src={`/bank-logo/${(vBankCode).toLowerCase()}.svg`}
											alt={vBankCode}
											height={24}
											width={24}
										/>
										{bankNames[vBankCode]}
									</Flex>
								</Select.Option>
							) : (
								vBankCode !== 'TRUEWALLET' && (
									<Select.Option key={vBankCode} value={vBankCode} label={bankNames[vBankCode]}>
										<Flex gap={'small'} align='center'>
											<img
												src={`/bank-logo/${(vBankCode).toLowerCase()}.svg`}
												alt={vBankCode}
												height={24}
												width={24}
											/>
											{bankNames[vBankCode]}
										</Flex>
									</Select.Option>
								)
							)
						))
					}
				</Select>
			</Form.Item>
			{bankCode !== "TRUEWALLET" ? (
				<Form.Item
					name="bankNumber"
					label="เลขที่บัญชี"
					rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
					normalize={(value, prevVal, prevVals) => value.replace(/-/g, "").trim()}
					style={{ marginBottom: 12 }}
					hasFeedback>
					<Input placeholder="เลขที่บัญชี" inputMode='numeric' />
				</Form.Item>
			) : (
				<>
					<div className="notice-card warning">
						<div className="notice-card-icon">
							<img src="/bank-logo/truewallet.svg" alt="notice icon" />
						</div>
						<Typography.Text className="notice-card-text">
							ทรูวอลเล็ทจะใช้เบอร์โทรศัพท์ของท่าน<Typography.Text type="warning"><strong>เป็นเลขที่บัญชี</strong></Typography.Text>
						</Typography.Text>
					</div>
				</>
			)}
			{/* , { pattern: /^[ก-๙ ]+$/g, message: 'ชื่อต้องเป็นภาษาไทยเท่านั้น' } */}
			<Form.Item
				name="firstName"
				label="ชื่อจริง"
				rules={[{ required: true, message: 'กรุณากรอกชื่อจริง' }]}
				style={{ marginBottom: 12 }}
				hasFeedback>
				<Input placeholder="ชื่อจริง" inputMode='text' />
			</Form.Item>
			{/* , { pattern: /^[ก-๙ ]+$/g, message: 'นามสกุลต้องเป็นภาษาไทยเท่านั้น' } */}
			<Form.Item
				name="lastName"
				label="นามสกุล"
				rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
				style={{ marginBottom: 16 }}
				hasFeedback>
				<Input placeholder="นามสกุล" inputMode='text' />
			</Form.Item>

			{/* {
				bankCode === 'SCB' && (
					<>
						<Form.Item
							name="firstNameEN"
							label="ชื่อจริง (ภาษาอังกฤษ)"
							rules={[{ required: true, message: 'กรุณากรอกชื่อจริง (ภาษาอังกฤษ)' }, { pattern: /^[a-zA-Z ]+$/g, message: 'ชื่อต้องเป็นภาษาอังกฤษเท่านั้น' }]}
							hasFeedback>
							<Input placeholder="ชื่อจริง (ภาษาอังกฤษ)" inputMode='text' />
						</Form.Item>
						<Form.Item
							name="lastNameEN"
							label="นามสกุล (ภาษาอังกฤษ)"
							rules={[{ required: true, message: 'กรุณากรอกนามสกุล (ภาษาอังกฤษ)' }, { pattern: /^[a-zA-Z ]+$/g, message: 'นามสกุลต้องเป็นภาษาอังกฤษเท่านั้น' }]}
							hasFeedback>
							<Input placeholder="นามสกุล (ภาษาอังกฤษ)" inputMode='text' />
						</Form.Item>
					</>
				)
			} */}

			<Form.Item style={{ marginBottom: 16 }}>
				<Button
					type="primary"
					htmlType="submit"
					loading={isSubmitting}
					onClick={handleSubmit}
					block
				>
					ถัดไป
				</Button>
			</Form.Item>
		</>
	)
}

export default RegisterStep2
