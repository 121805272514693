import React from 'react'
import './../../assets/scss/effects/moving-squares.scss';

const MovingSquares = () => {
    return (
        <div className="cube-container">
            <div className="cube" />
            <div className="cube" />
            <div className="cube" />
            <div className="cube" />
            <div className="cube" />
            <div className="cube" />
        </div>
    )
}

export default MovingSquares
