import React, { FC, MouseEvent } from 'react'
import { toast } from 'react-toastify'
import { AskmebetGameProviderConfig, AskmebetLoginResponse } from '../../../entities/askmebet'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Col } from 'antd'

interface AskmebetLinkProps {
	config: Record<string, AskmebetGameProviderConfig>
	type: string
	setLoading: (e: boolean) => void
}

const AskmebetLink: FC<AskmebetLinkProps> = ({ config, type, setLoading }) => {
	// const [redirecting, setRedirecting] = useState(false)
	const login = (provider: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setLoading(true)
		try {
			const res = await api.post<void, AskmebetLoginResponse>('/askmebet/login', {
				type: type,
				provider: provider,
				isMobile: isMobile(),
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{Object.values(config)
				.filter((provider) => provider.enable)
				.map((provider) => (
					<Col xs={6} sm={4} key={provider.id}>
						<a href={`#${provider.id}`} className='ambbet-provider-card' onClick={login(provider.id)}>
							<div className='ambbet-provider-img'>
								<img src={`https://cdn.cdnbet.co/assets/${type}-provider/logo-${type}-${provider.id}.png`} alt={provider.id} />
							</div>
						</a>
					</Col>
				))}
		</>
	)
}

export default AskmebetLink
