import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { format } from '../../helpers/number'
import { Button, Divider, Form, Modal, InputNumber, Typography, QRCode, Row, Col } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api from '../../helpers/api'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'
import Peer2PayQrCodeCapture from './Peer2PayQrCodeCapture'
import { useAppContext } from '../../contexts/AppContext';
import { moneyPattern } from '../../helpers/bank'
import { useHistory } from 'react-router'
import { makePath } from '../../helpers/path'


const { Link, Text } = Typography
interface Props {
    isOpen: boolean
    onClose: () => void
    onOpen: () => void
}

interface Peer2PayResponse {
    deeplink: string;
    qrContent: string;
    expiredTime: string;
    merchantName: string;
}

interface Peer2PayStatusResponse {
    ref: string
    Amount: any
    Status: string
    TransferredTime: string
}

const Peer2Pay: FC<Props> = ({ isOpen, onClose, onOpen }) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [form] = Form.useForm();
    const [qrCodeModal, setQrCodeModal] = useState<boolean>(false)
    const [isSubmitting, setSubmitting] = useState<boolean>(false)
    const [amount, setAmount] = useState<number>(0)
    const [pasteAmount, setPasteAmount] = useState<any>(0)
    const [depositQRCode, setDepositQRCode] = useState<any>()
    const [timer, setTimer] = useState<number>(0)
    const [stoppedTimer, setStoppedTimer] = useState<boolean>(false)
    const [isTimeOut, setIsTimeOut] = useState<boolean>(false)  // สถานะ : หมดเวลาทำรายการ
    const [fetching, setFetching] = useState(false)

    const { isModalSocket, setIsModalSocket } = useAppContext();

    const handleSubmit = async () => {
        setSubmitting(true)
        form.validateFields()
            .then(async (values: any) => {
                if (values.amount <= 0) {
                    setSubmitting(false)
                    return false
                }
                try {
                    setAmount(values.amount)

                    const res: Peer2PayResponse = await api.post('/deposit/peer2pay-qr', {
                        amount: values.amount
                    })

                    setSubmitting(false)
                    onClose()

                    // set timeout
                    const dateString = res.expiredTime;
                    const inDate = new Date(dateString)
                    const currentDate = new Date()
                    const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
                    const timeCurrent = timeInSeconds * -1
                    const timeFinal = parseInt(timeCurrent.toString(), 10)
                    setTimer(timeFinal)

                    setDepositQRCode(res)
                    setQrCodeModal(true)
                    setStoppedTimer(false)
                } catch (error: any) {
                    toast.error(error.message as string)
                    setSubmitting(false)
                }
            }).catch((errorInfo) => {
                setSubmitting(false)
            });
    }

    const refetchCredit = async () => {
        if (fetching) {
            return
        }
        setFetching(true)
        try {
            const res = await AuthService.getMemberInfo(true)
            dispatch(setUser(res))
        } catch ({ message }: any) {
            toast.error(message as string)
        } finally {
            setFetching(false)
        }
    }

    const toLinkPayment = (url: string) => {
        window.open(url)
    }

    const secondsToMinutes = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')} นาที`;
        return formattedTime;
    }

    const onCloseProcess = () => {
        setStoppedTimer(true)
        setIsTimeOut(false)
    }

    // const getStatusProcess = async () => {
    //     const res: Peer2PayStatusResponse = await api.get(`/deposit/peer2pay-qr/` + depositQRCode.ref)

    //     if (res.Status === 'S') {
    //         setStoppedTimer(true)
    //         setQrCodeModal(false)
    //         onCloseProcess()
    //     }
    // }

    const handleTimeOut = () => {
        setQrCodeModal(false)
        setIsTimeOut(false)
        onOpen()
    }

    const changeAmount = (amount: number) => {
        form.setFieldsValue({ amount: amount })
    }

    const handelClickPagePeer2Pay = () => {
        history.push(makePath('/peer2pay'))
    }

    useEffect(() => {
        if (pasteAmount) {
            form.setFieldsValue({ amount: pasteAmount })
        }
    }, [pasteAmount]); // eslint-disable-line

    useEffect(() => {
        if (qrCodeModal && !stoppedTimer) {
            const countdown = setInterval(() => {
                if (timer === 0) {
                    clearInterval(countdown);
                    setIsTimeOut(true)
                } else {
                    setTimer(timer - 1);
                }
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [qrCodeModal, timer, stoppedTimer]); // eslint-disable-line

    useEffect(() => {
        if (isModalSocket === false) {
            setQrCodeModal(false)
            setIsModalSocket(true)
            onCloseProcess()
        }
    }, [isModalSocket]) // eslint-disable-line

    useEffect(() => {
        if (form && isOpen) {
            setIsTimeOut(false)
            form.resetFields()
        }

        // if (qrCodeModal) {
        //     const getStatusInterval = setInterval(() => {
        //         getStatusProcess()
        //     }, 5000);

        //     return () => clearInterval(getStatusInterval);
        // }
    }, [form, isOpen, qrCodeModal]) //eslint-disable-line

    return (
        <>
            {/* Step 1 */}
            <Modal
                className="ant-modal-deposit p2p-modal"
                open={isOpen}
                title={<img src="/p2p/p2p-title.webp" width={180} alt="p2p title" />}
                centered
                onCancel={() => { !isSubmitting && onClose(); onCloseProcess() }}
                footer={null}>
                <Form
                    form={form}
                    name="peer2payForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label="จำนวนเงินที่ต้องการเติม"
                                rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม' }]}
                                style={{ marginBottom: 0 }}
                                className="ant-input-p2p">
                                <InputNumber
                                    placeholder="0.00"
                                    inputMode="decimal"
                                    prefix="฿"
                                    controls={false}
                                    style={{ width: '100%' }}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    onKeyPress={(event) => {
                                        const { key, target } = event;
                                        const { value } = target as HTMLInputElement;
                                        const decimalCount = value.split('.')[1]?.length ?? 0;

                                        if (!/^[0-9.,\b]+$/.test(key) || key === ',' || decimalCount === 2) {
                                            event.preventDefault();
                                        }

                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                            handleSubmit()
                                        }
                                    }}
                                    onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                                        const clipboardData = event.clipboardData;
                                        let pastedData = clipboardData?.getData('Text') ?? ''

                                        if (!/^[0-9,.]+$/.test(pastedData)) {
                                            event.preventDefault();
                                            return;
                                        }

                                        if (!/^\d+(\.\d{0,2})?$/.test(pastedData)) {
                                            const [integerPart, decimalPart] = pastedData.split('.')
                                            const sanitizedIntegerPart = integerPart.replace(/,/g, '');
                                            const sanitizedDecimalPart = decimalPart.replace(/,/g, '');
                                            pastedData = `${sanitizedIntegerPart}.${sanitizedDecimalPart?.substring(0, 2)}`
                                            setPasteAmount(pastedData)
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 10 }}>
                            <Row gutter={[16, 8]}>
                                {moneyPattern.map((value, index) => (
                                    <Col span={6} key={index}>
                                        <Button key={value} size='small' block onClick={() => changeAmount(value)}>{format(value, 0)}</Button>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        style={{ margin: '16px 0 12px' }}
                        block>
                        เติมเงิน
                    </Button>
                    <div style={{ textAlign: 'center', marginTop: 8 }}>
                        <img src="/p2p/p2pbottom.png" alt="p2p bottom" style={{ display: 'block', margin: '8px auto', width: '160px' }} />
                        <Text style={{ textAlign: 'center', fontSize: 22 }}>หากคุณยังไม่มีบัญชี Peer2Pay <Link onClick={handelClickPagePeer2Pay} style={{ fontSize: 22 }} underline >สมัครตอนนี้</Link></Text>
                    </div>
                </Form>
            </Modal >

            {/* Step 2 */}
            <Modal
                className="p2p-modal"
                open={isModalSocket && qrCodeModal ? true : false}
                title={<img src="/p2p/p2p-title.webp" width={180} alt="p2p title" />}
                footer={null}
                onCancel={(e) => { setQrCodeModal(false); onCloseProcess(); refetchCredit(); }}
                centered>
                {isTimeOut ? (
                    <div style={{ textAlign: 'center', padding: '24px 0' }}>
                        <ExclamationCircleOutlined rev={undefined} style={{ color: '#dc0303', fontSize: 56, marginBottom: 16 }} />
                        <div style={{ marginBottom: 12 }}>
                            <Text>หมดเวลาทำรายการ</Text>
                        </div>
                        <Button className="ant-btn-dark" onClick={handleTimeOut}>ทำรายการใหม่</Button>
                    </div>
                ) : (
                    <>
                        <div className="peer2pay-body">
                            <div className="peer2pay-top">
                                <img className="peer2pay-top-img" src="/p2p/p2ptop.png" alt="p2p top" style={{ width: '120px' }} />
                                <QRCode
                                    value={depositQRCode?.deeplink}
                                    color="#000"
                                    size={240}
                                    bordered={false}
                                    icon="/p2p/p2p-icon.png"
                                    style={{ margin: '0 auto' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: 216, margin: '0 auto 16px' }}>
                                    <div style={{ color: "#000", fontSize: 22, lineHeight: 0.9, marginTop: -1 }}>
                                        <div><small>สแกนผ่าน</small></div>
                                        <div style={{ color: '#172b68' }}><strong>PEER2PAY</strong></div>
                                    </div>
                                    <Peer2PayQrCodeCapture
                                        peer2payData={depositQRCode}
                                        amount={format(amount, 2)}
                                    />
                                </div>
                                <Divider>หรือ</Divider>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Text
                                        style={{ color: '#172b68' }}
                                        onClick={() => { toLinkPayment(depositQRCode?.deeplink) }}
                                        underline>
                                        <span className="p2p-desktop">
                                            ชำระผ่านเว็บ
                                        </span>
                                        <span className="p2p-mobile">
                                            ชำระผ่านแอปฯ
                                        </span>
                                    </Text>
                                </div>
                            </div>
                            <div className="peer2pay-bottom">
                                <div className="peer2pay-info">
                                    <div>ร้านค้า</div>
                                    <div>{depositQRCode?.merchantName}</div>
                                </div>
                                <div className="peer2pay-info">
                                    <div>จำนวน</div>
                                    <div className="peer2pay-info-amount">{format(amount, 2)} THBP</div>
                                </div>
                                <div className="peer2pay-info">
                                    <div>ชำระภายใน</div>
                                    <div>{secondsToMinutes(timer)}</div>
                                </div>
                            </div>
                            <img src="/p2p/p2pbottom.png" alt="p2p bottom" style={{ display: 'block', margin: '16px auto 4px', width: '160px' }} />
                        </div>
                        <div style={{ textAlign: 'center', marginTop: 8 }}>
                            <Text style={{ textAlign: 'center', fontSize: 22 }}>หากคุณยังไม่มีบัญชี Peer2Pay <Link onClick={handelClickPagePeer2Pay} style={{ fontSize: 22 }} underline >สมัครตอนนี้</Link></Text>
                        </div>
                    </>
                )}
            </Modal>
        </>
    )
}

export default Peer2Pay
