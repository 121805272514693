import React, { FC, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Typography } from 'antd';
import Notice from '../Home/Notice';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const { Link } = Typography

interface MainSliderProps {
    prefixState: any
}

const MainSlider: FC<MainSliderProps> = ({ prefixState }) => {
    const [loading, setLoading] = useState(true);
    const topgraphicsStatus = prefixState.info.topgraphics.enable
    const topgraphicsItems = prefixState.info.topgraphics.items ? prefixState.info.topgraphics.items.sort((a: any, b: any) => a.order - b.order) : prefixState.info.topgraphics.items

    const announce = prefixState.info.announce || ""

    // url top graphic
    const urlTgpTarget = ''; // "_blank" or "_self"
    const [urlTgp, setUrlTgp] = useState('/deposit')

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {announce && <Notice text={announce} />}
            {topgraphicsStatus && topgraphicsItems && (
                <Carousel
                    autoPlay={true}
                    showStatus={false}
                    showThumbs={false}
                    interval={2500}
                    infiniteLoop={true}
                    className="main-slider-container"
                >
                    {topgraphicsItems.map((item: any, key: any) => {
                        return (
                            <div key={`topgraphics-item-${key}`}>
                                {urlTgpTarget ? (
                                    <Link href={urlTgp} className="main-slide" key={`topgraphics-${key}`} target={urlTgpTarget}>
                                        {loading && <div className="skeleton-container" />}
                                        <img src={item.image} alt="" onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }} />
                                    </Link>
                                ) : (
                                    <div className="main-slide" key={`topgraphics-${key}`}>
                                        {loading && <div className="skeleton-container" />}
                                        <img src={item.image} alt="" onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </Carousel>
            )}
        </>
    )
}

export default MainSlider
