import React, { FC, useEffect } from 'react'

interface DefaultGameProps {
	gameURL: string
}
const DefaultGame: FC<DefaultGameProps> = ({ gameURL }) => {
	useEffect(() => {
		window.location.href = gameURL
	}, [gameURL])

	return <div>Redirecting ...</div>
}

export default DefaultGame
