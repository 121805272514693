import React, { FC } from 'react'
import NumberWithCommas from '../../helpers/NumberWithCommas';

interface Props {
    day: number
    data: any
    statusCheckIn: string
}

const CheckInItem: FC<Props> = ({ day, data, statusCheckIn }) => {
    return (
        <>
            {day < 7 ? (
                <div className={`check-in-item ${statusCheckIn}`}>
                    <div>วันที่ {day}</div>
                    <img className="check-in-coin" src="/icons/new/baht.svg" alt="Coin" />
                    <div className="check-in-item-reward">
                        <>฿ {NumberWithCommas(data.creditReward)}</>
                    </div>
                    <img className="check-in-checked" src="/icons/new/check.svg" alt="check" />
                </div>
            ) : (
                <div className={`check-in-item final ${statusCheckIn}`}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>วันที่ {day}</div>
                        <div className="check-in-item-reward">
                            <div style={{ fontSize: 16, fontWeight: 400, marginBottom: -2, lineHeight: 1 }}>รับรางวัลฟรี</div>
                            <div>฿ {NumberWithCommas(data.creditReward)}</div>
                        </div>
                    </div>
                    <img className="check-in-coin" src="/icons/gif/chest.gif" alt="Chest" />
                    <img className="check-in-checked" src="/icons/new/check.svg" alt="check" />
                </div>
            )}
        </>
    )
};

export default CheckInItem