import React, { FC, useEffect } from 'react'
import { Member } from '../../@types/member'
import { isMobile } from '../../helpers/utils'

interface SuperSlotGameProps {
	member: Member
}
const SuperSlotGame: FC<SuperSlotGameProps> = ({ member }) => {
	useEffect(() => {
		let authURL = 'https://desktop.ambsuperslot1.com'
		if (isMobile()) {
			authURL = 'https://mobile.ambsuperslot1.com'
		}

		window.location.href = `${authURL}?username=${member.game.username}&password=${member.game.password}`
	}, [member])

	return <div>Redirecting ...</div>
}

export default SuperSlotGame
