export const format = (v: number, digits: number = 2) =>
	v.toLocaleString(undefined, {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	})

export const formatCurrency = (v: number, digits: number = 2) =>
	v.toLocaleString("th-TH", {
		style: "currency",
		currency: "THB",
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	})
