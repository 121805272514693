export default function NumberWithCommas(num: any, digit?: number) {
    // if (num) {
    //     num = num.toFixed(digit ? digit : 0).toString();
    //     const pattern = /(-?\d+)(\d{3})/;
    //     while (pattern.test(num))
    //         num = num.replace(pattern, "$1,$2");
    //     return num;
    // }
    if (num !== undefined && num !== null) {
        if (num === 0 && digit !== undefined && digit !== null && digit > 0) {
            return `0.${"0".repeat(digit)}`;
        } else {
            num = num.toFixed(digit ?? 0).toString();
            const pattern = /(-?\d+)(\d{3})/;
            while (pattern.test(num))
                num = num.replace(pattern, "$1,$2");
            return num;
        }
    }
    return 0;
}