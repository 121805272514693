import React, { FC, useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import LoadingLayout from '../Layout/LoadingLayout';

interface LTM888Props { }

interface LTM888LoginResponse {
    url: string
}

const LTM888: FC<LTM888Props> = () => {
    const history = useHistory()
    const location = useLocation();
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const initLTM888 = useCallback(async () => {
        try {
            const { url } = await api.post<void, LTM888LoginResponse>('/login/ltm888')

            if (url) {
                const params = new URLSearchParams(location.search);
                params.set('path', '/wallet')
                history.push({ search: params.toString() });
                window.location.href = url
            } else {
                toast.error('login failed')
                history.push(makePath('/wallet'))
            }
        } catch ({ message }: any) {
            toast.error(message)
            history.push(makePath('/wallet'))
        }
    }, [history]) //eslint-disable-line

    // get query string
    // useEffect(() => {
    // 	const params = new URLSearchParams(location.search);
    // 	const path = params.get('path');
    // 	if (path) {
    // 		history.push(makePath('/wallet'))
    // 	}
    // }, [history, location.search]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const path = params.get('path');

        if (!path) {
            initLTM888()
        } else {
            setTimeout(() => {
                history.push(makePath('/wallet'))
            }, 1000);
        }
    }, [initLTM888, history, location.search])

    // return <div>Redirecting ...</div>
    return (
        <LoadingLayout prefix={prefixState} />
    )
}

export default LTM888
