import React, { FC, useEffect, useRef, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import ReactPlayer from 'react-player';

interface MainAudioProps {
    isSound: boolean
    setIsToggleSound: (e: any) => void
    elGame: any
    prefixState: any
}

const MainAudio: FC<MainAudioProps> = ({ prefixState, isSound, setIsToggleSound, elGame }) => {

    // https://www.youtube.com/watch?v=tQjd3yciezU&t=3s
    const urlSound = prefixState?.systemMedia ? prefixState?.systemMedia.url : ''
    const startTime = prefixState?.systemMedia ? parseInt(prefixState?.systemMedia.start) : 0
    const endTime = prefixState?.systemMedia ? parseInt(prefixState?.systemMedia.end) : 0
    // const urlSound = 'https://www.youtube.com/watch?v=tQjd3yciezU?t=3'
    // const startTime = 3
    // const endTime = 180

    const videoUrl = urlSound + '&t=' + startTime + 's'; // เวลาเริ่ม

    const [tabHasFocus, setTabHasFocus] = useState(true);
    const [onPlay, setOnPlay] = useState(true);

    const playerRef: any = useRef(null);

    const element: any = elGame

    var thisSound = localStorage.getItem('sound')

    const handleEnded = () => {
        // เมื่อวิดีโอจบให้เริ่มเล่นใหม่
        if (playerRef.current) {
            playerRef.current.seekTo(startTime); // เวลาเริ่ม
            setOnPlay(true);
            setIsToggleSound(!isSound);
        }
    };

    const handleProgress = (state: any) => {
        if (parseInt(state.playedSeconds) === endTime) { // เวลาจบ
            setOnPlay(false);
        }
        if (!onPlay) {
            handleEnded()
        }
    };
    
    useEffect(() => {

        const handleFocus = () => {
            setTabHasFocus(true)
        };

        const handleBlur = () => {
            setTabHasFocus(false)
        };

        const handleClickPage = () => {
            if (thisSound === 'On') {
                setIsToggleSound(true)
            } else {
                setIsToggleSound(false)
            }
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        if (element && !isSound && thisSound === 'On' && element.current) {
            element.current.addEventListener("click", handleClickPage)
        }

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
            if (element && !isSound && thisSound === 'On' && element.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                element.current.removeEventListener("click", handleClickPage)
            }
        };

    }, [element, isSound, setIsToggleSound, onPlay]) // eslint-disable-line

    return (
        <>
            <ReactPlayer
                ref={playerRef}
                style={{ display: 'none' }}
                url={videoUrl}
                playing={onPlay}
                controls={false}
                muted={isSound && tabHasFocus ? false : true}
                volume={1}
                onProgress={handleProgress}
                onEnded={handleEnded}
            />
            {/* <ReactAudioPlayer
                style={{ display: 'none' }}
                src="https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/sound/sound_01_02.mp3"
                autoPlay
                muted={isSound && tabHasFocus ? false : true}
                loop={true}
                volume={levelSound}
            /> */}
        </>
    )
}

export default MainAudio