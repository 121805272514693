import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import BankDepositItem from '../components/Deposit/BankDepositItem'
import { ApplicationState } from '../store'
import { Button, Modal, Typography } from 'antd'
import { Banks, getBankColor, getBankName } from '../helpers/bank'
import { useAppContext } from '../contexts/AppContext'
import UploadSlip from '../components/Deposit/UploadSlip'
import UploadSlipForm from '../components/Deposit/UploadSlipForm'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const BankSlipDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)

	const [openUploadSlip, setOpenUploadSlip] = useState<boolean>(false);

	const { isModalSocket, setIsModalSocket } = useAppContext();

	useEffect(() => {
		if (isModalSocket === false) {
			onClose()
			setIsModalSocket(true)
		}
	}, [isModalSocket]) //eslint-disable-line

	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	if (depositToBanks.length <= 0) {
		return <Redirect to='/deposit' />
	}

	const bankMember = Banks.filter(bankName => bankName.key === userState.info?.bankCode.toUpperCase())[0]
	const titleBankDeposit = prefix?.functions.scanqr && depositToList.length === 1 ? (depositToList[0].hasSMS ? 'โอนผ่านธนาคาร' : 'ธนาคาร (อัปสลิป)') : 'โอนผ่านธนาคาร'

	return (
		<>
			<Modal
				className='ant-modal-deposit'
				// open={isModalSocket && isOpen ? true : false}
				open={isOpen}
				title={titleBankDeposit}
				centered
				onCancel={onClose}
				footer={null}
			>
				<div className='bank-deposit-card bank-form' style={{ marginBottom: 8 }}>
					<div
						className='bank-card-logo'
						style={{ boxShadow: `0 8px 12px -4px ${getBankColor(userState.info?.bankCode)}` }}
					>
						<img
							src={bankMember.image}
							alt={getBankName(userState.info?.bankCode)}
						/>
					</div>
					<div className='bank-card-info'>
						<div><Text type='secondary'><small>{getBankName(userState.info?.bankCode)}</small></Text></div>
						<Text className='bank-deposite-account'>{userState.info?.bankNumber}</Text>
						<div><Text type='secondary'><small>{userState.info?.firstName} {userState.info?.lastName}</small></Text></div>
					</div>
				</div>
				<div className='bank-deposit-arrow'>
					<div className='arrow arrow-first'></div>
					<div className='arrow arrow-second'></div>
				</div>
				{depositToBanks.map((bank) => (
					<BankDepositItem bank={bank} key={bank.accountNumber} />
				))}

				{depositToBanks[0].hasSMS ? (
					<>
						<div className='notice-card danger'>
							<div className='notice-card-icon'>
								<img src='/icons/new/danger.svg' alt='notice icon' />
							</div>
							<Text className='notice-card-text'>
								เมื่อโอนเงินเสร็จแล้ว กรุณา <Text type='danger'><strong>กดปุ่มยืนยันการโอน</strong></Text> เพื่ออัพเดทยอดทันที
							</Text>
						</div>
						<Button
							type='primary'
							block
							onClick={() => setOpenUploadSlip(true)}
							style={{ marginTop: 8 }}>ยืนยันการโอน</Button>
					</>
				) : (
					<UploadSlipForm bankNumber={depositToBanks[0].accountNumber} onClose={() => onClose()} />
				)}
			</Modal >

			<UploadSlip isOpen={openUploadSlip} onClose={() => setOpenUploadSlip(false)} />

		</>
	)
}

export default BankSlipDeposit