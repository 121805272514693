import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { NavLink } from 'react-router-dom'
import BankInfoWidget from '../components/Withdraw/BankInfoWidget'
import Peer2PayInfoWidget from '../components/Withdraw/Peer2PayInfoWidget'
import TruewalletInfoWidget from '../components/Withdraw/TruewalletInfoWidget'
import { ApplicationState } from '../store'
import { Col, Empty, Result, Radio, Row, Button } from 'antd'
import { Banks, getBankName } from '../helpers/bank'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../helpers/path'
// import { makePath } from '../helpers/path'
// import { Button, Result, Row } from 'antd'
interface PaymentMethods {
	[key: string]: boolean;
}

const Withdraw = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const memberInfo = userState.info
	const history = useHistory()
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true) //eslint-disable-line
	const [typeWithdraw, setTypeWithdraw] = useState<string>('bank') //eslint-disable-line

	const handleTypeWithdraw = (e: any) => {
		setTypeWithdraw(e.target.value)
		const params = new URLSearchParams(location.search);
		params.set('type', e.target.value);
		history.push({ search: params.toString() });
	}

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	useEffect(() => {
		const paramsTypeWithdraw: any = params.get('type') ? params.get('type') : 'bank';
		let type = 'bank'

		if (userState.paymentMethods) {
			if ((userState.paymentMethods as PaymentMethods)[paramsTypeWithdraw]) {
				setTypeWithdraw(paramsTypeWithdraw)
			} else {
				if (userState.paymentMethods.bank) {
					type = 'bank'
				} else if (userState.paymentMethods.peer2pay) {
					type = 'peer2pay'
				} else if (userState.paymentMethods.truewallet) {
					type = 'truewallet'
				} else {
					type = 'notfound'
				}
				if (paramsTypeWithdraw !== type) {
					setTypeWithdraw(type)
					params.set('type', type);
					history.push({ search: params.toString() });
				}
			}
		}
	}, [params]) //eslint-disable-line

	useEffect(() => {
		if (userState.paymentMethods) {
			const isNotAllFalse = Object.values(userState.paymentMethods).some(value => value !== false);
			const params = new URLSearchParams(location.search);
			let type = 'bank'

			if (isNotAllFalse) {
				setTypeWithdraw('bank')
				if (userState.paymentMethods.bank) {
					setTypeWithdraw('bank')
				} else if (userState.paymentMethods.peer2pay) {
					type = 'peer2pay'
					setTypeWithdraw('peer2pay')
				} else if (userState.paymentMethods.truewallet) {
					type = 'truewallet'
					setTypeWithdraw('truewallet')
				}
			} else {
				setTypeWithdraw('notfound')
			}

			if (!params.get('type')) {
				params.set('type', type);
				history.push({ search: params.toString() });
			}
		}
	}, [userState])

	// if (!memberInfo) {
	// 	return <Empty description="Undefined member" />
	// }

	if (!memberInfo || typeWithdraw === 'notfound') {
		return <Result
			status="warning"
			title="ระบบไม่สามารถถอนเงินได้ในขณะนี้"
			extra={[
				<NavLink to={makePath('/support')} className="link-contact" key={"contact"}>
					<Button type="primary" shape="round">ติดต่อเรา</Button>
				</NavLink>
			]}
		/>
	}


	return (
		<div>
			<Row justify="center" className="margin-form-card">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div className="withdraw-radio-group-wrapper">
								<Radio.Group
									className="withdraw-radio-group"
									defaultValue={typeWithdraw}
									value={typeWithdraw}
									onChange={handleTypeWithdraw}
									buttonStyle="solid"
								>
									<Radio.Button value="bank" disabled={!userState.paymentMethods.bank}>ธนาคาร <br /> <small style={{ lineHeight: '25px !important' }}>{!userState.paymentMethods.bank ? `(ปิดชั่วคราว)` : ``}</small></Radio.Button>
									<Radio.Button value="peer2pay" disabled={!userState.paymentMethods.peer2pay}>Peer2Pay <br /> <small style={{ lineHeight: '25px !important' }}>{!userState.paymentMethods.peer2pay ? `(ปิดชั่วคราว)` : ``}</small></Radio.Button>
									<Radio.Button value="truewallet" disabled={!userState.paymentMethods.truewallet}>ทรูวอลเล็ท <br /> <small style={{ lineHeight: '25px !important' }}>{!userState.paymentMethods.truewallet ? `(ปิดชั่วคราว)` : ``}</small></Radio.Button>
								</Radio.Group>
							</div>
							{typeWithdraw === 'peer2pay' && <Peer2PayInfoWidget credit={userState.credit} info={memberInfo} paymentGateway={typeWithdraw} user={userState} />}
							{typeWithdraw === 'bank' && <BankInfoWidget credit={userState.credit} info={memberInfo} paymentGateway={typeWithdraw} user={userState} />}
							{typeWithdraw === 'truewallet' && <TruewalletInfoWidget credit={userState.credit} info={memberInfo} paymentGateway={typeWithdraw} user={userState} />}
						</div>
					</div>
				</Col>
			</Row>
			{/* {memberInfo.bankCode !== "TRUEWALLET" ? (
				<BankInfoWidget credit={userState.credit} info={memberInfo} />
			) : (
				<Row justify="center" align="middle" style={{ height: '100%' }}>
					<Result
						status="warning"
						title="ระบบไม่สามารถถอนเงินให้กับบัญชีทรูมันนี่วอลเล็ท"
						extra={[
							!showMenuSupport && (
								<NavLink to={makePath('/support')} className="link-contact">
									<Button type="primary" shape="round">ติดต่อเรา</Button>
								</NavLink>
							),
							prefix?.lineLink && showMenuSupport && (
								<a href={prefix.lineLink} target="_blank" rel="noreferrer" className="link-contact">
									<Button type="primary" shape="round">ติดต่อเรา</Button>
								</a>
							)
						]}
					/>
				</Row>
			)} */}
		</div>
	)
}

export default Withdraw
