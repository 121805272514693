import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import UserService, { MemberResponse } from '../../services/auth'

const actionCreator = actionCreatorFactory('@@fcn/user')

export const setUser = actionCreator<MemberResponse>('setUser')

export const requestUserDone = actionCreator<MemberResponse>('requestUserDone')
export const requestUserFailed = actionCreator('requestUserFailed')

export const requestUser = () => async (dispatch: ThunkDispatch) => {
	try {
		const user = await UserService.getMemberInfo(true)
		return dispatch(requestUserDone(user))
	} catch (e) {
		console.error(e)
		return dispatch(requestUserFailed())
	}
}
