import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { format } from '../../helpers/number'
import { requestUser } from '../../store/user/user.actions'
import { Button, Space, Typography } from 'antd'
import { ApplicationState } from '../../store'
import ModalTerms from '../../components/Elements/ModalTerms';
import { makePath } from '../../helpers/path'
import { useHistory } from 'react-router'

const { Text } = Typography

interface CashbackWidgetProps {
	cashback: number
}
const CashbackWidget: FC<CashbackWidgetProps> = ({ cashback }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const [openTerms, setOpenTerms] = useState<boolean>(false)
	const [isRollingBalance, setIsRollingBalance] = useState<number>(0)
	const handleModalTerms = () => setOpenTerms(!openTerms)

	const acceptCashback = async () => {
		setLoading(true)
		try {
			const res = await api.post<void, APIResponse>('/member/cashback')

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }: any) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}

	const getRollingBalance = async () => {
		try {
			const res: any = await api.get<void, APIResponse>('/member/rolling/balance')
			setIsRollingBalance(res.balance)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	useEffect(() => {
		if (prefixState?.info?.rolling) {
			getRollingBalance()
		}
	}, [prefixState?.info?.rolling])

	return (
		<>
			{prefixState?.info?.cashback &&
				<div className="wallet-other-card">
					<div className='wallet-other-card-inner'>
						<Space size={0}>
							<Text type="secondary">
								{prefixState.info?.termandcondition?.cashbackDescription && (
									<Button
										type="link"
										size={'small'}
										onClick={handleModalTerms}
										icon={<i className="fa-light fa-circle-info" style={{ color: "#fff", fontSize: 18 }}></i>}>
									</Button>
								)}
								ยอดแคชแบ็ก
							</Text>
						</Space>
						<div className="wallet-other-card-amount">
							<span>฿ {format(cashback)}</span>
						</div>
					</div>
					<Button
						type="primary"
						className="ant-btn-secondary reward-cashback"
						icon={<>รับ</>}
						loading={loading}
						onClick={acceptCashback}
					/>
					<ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} description={prefixState.info?.termandcondition?.cashbackDescription || ""} />
				</div>
			}

			{prefixState?.info?.rolling && (
				<div className="wallet-other-card">
					<div className='wallet-other-card-inner'>
						<Space size={0}>
							<Text type="secondary">
								คืนยอดเดิมพัน (อัพเดททุก 10 นาที)
							</Text>
						</Space>
						<div className="wallet-other-card-amount">
							<span>฿ {format(isRollingBalance)}</span>
						</div>
					</div>
					<Button
						type="primary"
						className="ant-btn-secondary reward-cashback"
						icon={<>รับ</>}
						onClick={() => history.push(makePath('/rolling'))}
					/>
					<ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} description={prefixState.info?.termandcondition?.cashbackDescription || ""} />
				</div>
			)}
		</>
	)
}

export default CashbackWidget
