import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ApplicationState } from '../../store'
import { Transaction } from '../../@types/transaction'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { makePath } from '../../helpers/path'
import StatusBadge from './StatusBadge'
import { Button, Empty, Flex, Tag, Typography } from 'antd'

const { Text } = Typography

interface WithdrawListProps {
	data: Transaction[]
}

const WithdrawList: FC<WithdrawListProps> = ({ data }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)

			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	if (data.length <= 0) {
		return (
			<div style={{ padding: '24px 0' }}>
				<Empty
					description='คุณไม่มีรายการถอนเงิน'
					image="/icons/new/empty-box.svg"
					imageStyle={{ height: 88 }}
				/>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => (
				<div className="transaction-card" key={tx.id}>
					<div className="transaction-header">
						<div className="transaction-info">
							<div className="transaction-title">ถอนเงิน</div>
						</div>
						<div className="transaction-title">฿ {format(tx.amount)}</div>
					</div>
					<div className="transaction-body-remark">
						<Flex gap={'small'} justify='space-between' align='center'>
							<Flex gap={'small'} align='center'>
								<Text>ไปยัง</Text>
								<img
									src={`/bank-logo/${(tx.transferTo).toLowerCase()}.svg`}
									alt={tx.transferTo}
									height={24}
									width={24}
								/>
								<Text>{getBankName(tx.transferTo)}</Text>
							</Flex>
							{tx.transferTo === 'PEER2PAY' && tx.status === 'success' && (
								<Button
									type="primary"
									onClick={() => window.open(`https://peer2pay.com/?action=requestSell&amt=${tx.amount}&t=THBP&c=THB`, '_blank')}
									style={{ minWidth: 120, fontSize: 22 }}
									icon={<i className="fa-solid fa-money-simple-from-bracket" style={{ fontSize: 16 }}></i>}
									size='small'
									disabled={tx.status === 'success' ? false : true}>
									<span>ถอนเงินสด</span>
								</Button>
							)}
						</Flex>
					</div>
					<div className="transaction-body">
						<div className="tag-default">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</div>
						<StatusBadge status={tx.status} />
					</div>

					{tx.status === 'reject' && tx.remark
						&& (
							<>
								{showMenuSupportLine && (
									<div className="transaction-footer">
										<a href={prefixState.info?.socialMedia['line']} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
											<Button type="primary" className="support-btn" icon={<i className="fa-sharp fa-solid fa-headset" style={{ fontSize: 20 }}></i>}>
												<span>ติดต่อฝ่ายบริการลูกค้า</span>
											</Button>
										</a>
									</div>
								)}

								{!prefix?.lineLink && !showMenuSupport && !showMenuSupportLine && (
									<div className="transaction-footer">
										<NavLink to={makePath('/support')} style={{ width: '100%' }}>
											<Button type="primary" className="support-btn" icon={<i className="fa-sharp fa-solid fa-headset" style={{ fontSize: 20 }}></i>}>
												<span>ติดต่อฝ่ายบริการลูกค้า</span>
											</Button>
										</NavLink>
									</div>
								)}

								{prefix?.lineLink && !showMenuSupportLine && (
									<div className="transaction-footer">
										<a href={prefix.lineLink} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
											<Button type="primary" className="support-btn" icon={<i className="fa-sharp fa-solid fa-headset" style={{ fontSize: 20 }}></i>}>
												<span>ติดต่อฝ่ายบริการลูกค้า</span>
											</Button>
										</a>
									</div>
								)}
							</>
						)
					}
				</div>
			))}
		</>
	)
}

export default WithdrawList
