import React, { FC } from 'react'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { Avatar, Typography } from 'antd';
import TopbarWidget from './TopbarWidget';
import { Member } from '../../@types/member';
import { Link } from 'react-router-dom'

const { Text } = Typography

interface TopbarMobileProps {
	pathname: string
	providerName?: string
	prefix: any
	member?: Member
}

const TopbarMobile: FC<TopbarMobileProps> = ({ pathname, providerName, prefix, member }) => {

	return (
		<>
			<div className="topbar-mobile" style={{ justifyContent: 'space-between' }}>
				<div className="topbar-logo">
					<Link to={makePath('/wallet')}>
						{prefix?.logo ? (
							<Avatar
								className="avatar-logo avatar-logo-topbar"
								src={prefix?.logo}
								shape="square"
							/>
						) : (
							<div className="prefix-name">{prefix?.prefix}</div>
						)}
					</Link>
				</div>
				<div className="topbar-mobile-title">
					<Text>
						{providerName
							? providerName
							: (topbarText[pathname] ?? '-')
						}
					</Text>
				</div>
				<TopbarWidget prefix={prefix} member={member} />
			</div>
		</>
	)
}

export default TopbarMobile
