export const findCategoryGame = (type: string) => {
    switch (type) {
        case "all":
            return "ทั้งหมด"

        case "CARD":
            return "เกมไพ่"

        case "ARCADE":
            return "อาร์เคด"

        case "TABLE":
            return "เกมโต๊ะ"

        case "BACCARAT":
            return "บาคาร่า"

        case "DRAGON_TIGER":
            return "เสือมังกร"

        case "ROULETTE":
            return "รูเล็ต"

        case "4POINT":
            return "4Point"

        case "COWCOW":
            return "CowCow"

        case "SUPER6":
            return "Super6"

        case "CASINO":
            return "คาสิโน"

        case "SICBO":
            return "ไฮโล"

        case "DICEDUEL":
            return "ลูกเต๋า"

        case "LUCKY":
            return "โชคดี"

        case "POKER":
            return "โป๊กเกอร์"

        case "SIC_BO":
            return "ไฮโล"

        case "ESPORT":
            return "อีสปอร์ต"

        case "TRADE":
            return "ซื้อขาย"

        case "JACKPOTS":
            return "แจ็คพอต"

        case "FISH":
            return "ยิงปลา"

        case "ATOM":
            return "อะตอม"

        case "KENO":
            return "คีโน่"

        case "NUMBER":
            return "ตัวเลข"

        case "RNGWAR":
            return "สุ่มตัวเลข"

        case "BUY_FREE_SPINS":
            return "ซื้อฟรีสปิน"

        case "MEGAWAYS":
            return "เมกาเวย์"

        case "SLOT":
            return "สล็อต"

        case "FH":
            return "ยิงปลา"

        case "Video Slots":
            return "สล็อต"

        case "Classic Slots":
            return "สล็อต"

        case "BINGO":
            return "บิงโก"

        case "FISHING":
            return "ยิงปลา"

        case "ECASINO":
            return "คาสิโน"

        case "ARCADE_BINGO":
            return "บิงโก"

        case "CRASH":
            return "CRASH"

        case "TABLES":
            return "เกมโต๊ะ"

        case "BLACKJACK":
            return "แบล็คแจ็ค"

        case "BACCARAT NEW":
            return "บาคาร่าใหม่"

        case "SCRATCH CARD":
            return "ขูดบัตร"

        case "LIVE GAMES":
            return "คาสิโนสด"

        case "SLOTS":
            return "สล็อต"

        case "VIDEO SLOTS":
            return "สล็อต"

        case "CLASSIC SLOTS":
            return "คลาสสิค สล็อต"

        case "SCRATCH-CARDS":
            return "ขูดบัตร"

        case "TABLE-GAMES":
            return "เกมโต๊ะ"

        case "INSTANT-WIN":
            return "ชนะทันที"

        case "VIDEO POKER":
            return "โป๊กเกอร์"

        case "EGAME":
            return "อิเล็กทรอนิกส์เกม"

        case "SLOT GAME":
            return "สล็อต"

        case "TABLE GAME":
            return "เกมโต๊ะ"

        case "FISH GAME":
            return "ยิงปลา"

        case "CARD GAME":
            return "เกมการ์ด"

        case "VIDEO BINGO":
            return "บิงโก"

        case "AVALANCHE":
            return "คลาสสิค สล็อต"

        case "ACTION":
            return "แอคชั่น"

        case "TABLEGAME":
            return "เกมโต๊ะ"

        case "INSTANTWIN":
            return "ชนะทันที"

        case "LOTTERY":
            return "ลอตเตอรี่"

        case "SHOOTING":
            return "ยิงปลา"

        case "VIRTUAL_SPORTS":
            return "กีฬา"

        case "SCRATCHCARD":
            return "ขูดการ์ด"

        default:
            return type
    }
}

export const findCategoryLotto = (type: string) => {
    switch (type) {
        case "all":
            return "ทั้งหมด"

        case "LOTTO":
            return "หวย"

        case "YEEKEE":
            return "หวยยี่กี"

        case "THAI":
            return "หวยไทย"

        case "STOCK":
            return "หวยหุ้น"

        case "MALAY":
            return "หวยมาเลย์"

        case "PINGPONG":
            return "หวยปิงปอง"

        case "VIETNAM":
            return "หวยเวียดนาม"

        case "LAOS":
            return "หวยลาว"

        default:
            return type
    }
}

export const typeSlot = [
    "ARCADE",
    "4POINT",
    "COWCOW",
    "SUPER6",
    "LUCKY",
    "ESPORT",
    "JACKPOTS",
    "ATOM",
    "BUY_FREE_SPINS",
    "MEGAWAYS",
    "SLOT",
    "Video Slots",
    "Classic Slots",
    "SLOTS",
    "VIDEO SLOTS",
    "CLASSIC SLOTS",
    "INSTANT-WIN",
    "EGAME",
    "SLOT GAME",
    "AVALANCHE",
    "ACTION",
    "INSTANTWIN",
]

export const typeFish = [
    "FISH",
    "FH",
    "FISHING",
    "FISH GAME",
    "SHOOTING",
]

export const typeCasino = [
    "CARD",
    "TABLE",
    "BACCARAT",
    "DRAGON_TIGER",
    "ROULETTE",
    "CASINO",
    "SICBO",
    "DICEDUEL",
    "POKER",
    "SIC_BO",
    "TRADE",
    "KENO",
    "NUMBER",
    "RNGWAR",
    "BINGO",
    "CRASH",
    "ECASINO",
    "ARCADE_BINGO",
    "TABLES",
    "BLACKJACK",
    "BACCARAT NEW",
    "LIVE GAMES",
    "TABLE-GAMES",
    "VIDEO POKER",
    "TABLE GAME",
    "CARD GAME",
    "VIDEO BINGO",
    "TABLEGAME",
    "LOTTERY",
    "VIRTUAL_SPORTS",
    "SCRATCHCARD",
]