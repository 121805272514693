import { Col, Modal, Row, Space, Typography, } from 'antd'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import 'react-lazy-load-image-component/src/effects/blur.css';

const { Text } = Typography

interface ModalTermsProps {
    isOpen: boolean
    description: string
    onClose: () => void
}
const ModalTerms: FC<ModalTermsProps> = ({ isOpen, onClose, description }) => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const prefix = prefixState.info

    useEffect(() => {

    }, [prefix])

    return (
        <Modal
            className="modal-terms"
            open={isOpen}
            title="เงื่อนไขและกติกา"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <Space direction="vertical">
                {description.split('\n').map((line, index) => <Text key={index} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
            </Space>
        </Modal>
    )
}

export default ModalTerms
