// import React, { FC, useEffect, useRef, useState } from 'react'
// import { useHistory } from 'react-router'
// import api from '../../helpers/api'
// import { toast } from 'react-toastify'
// import { Member } from '../../@types/member'
// import { makePath } from '../../helpers/path'

// interface SuperlotProps {
// 	member: Member
// }

// interface SuperlotLoginResponse {
// 	token: string
// }

// const Superlot: FC<SuperlotProps> = ({member}) => {
// 	const formRef = useRef<HTMLFormElement>(null)
// 	const history = useHistory()

// 	const [superlotTokenLogin, setSuperlotTokenLogin] = useState<SuperlotLoginResponse | null>(null)

// 	useEffect(() => {
// 		try {
// 			api.get<void, SuperlotLoginResponse>('/superlot').then((res) => {
// 				setSuperlotTokenLogin(res)

// 				formRef.current && formRef.current!.submit()
// 			})
// 		} catch ({ message }) {
// 			toast.error(message as string)
// 			history.push(makePath('/wallet'))
// 		}
// 	}, [superlotTokenLogin])

// 	return (
// 		<form
// 			method="POST"
// 			action="https://www.superlot999.com/login"
// 			accept-charset="UTF-8"
// 			ref={formRef}>
// 			<input type="hidden" name="username" value={member.game.username} />
// 			<input type="hidden" name="password" value={member.game.password} />
// 			<input type="hidden" name="_token" value={superlotTokenLogin?.token} />
// 		</form>
// 	)
// }

// export default Superlot


import React, { FC, useEffect } from 'react'
import { Member } from '../../@types/member'

interface SuperlotProps {
	member: Member
}

const Superlot: FC<SuperlotProps> = ({ member }) => {

	useEffect(() => {
		window.location.href = "https://www.superlot999.com/login"
	}, []);

	// console.log(member.game.username);
	// console.log(member.game.password);
	// U99994692008
	// Aa123456

	return (
		<>
			Redirect...
		</>
	)
}

export default Superlot
