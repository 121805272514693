import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Avatar, Col, Row, Space, Typography } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import EffectsContainer from "../components/Effects/EffectsContainer";
import SetTheme from "../utils/SetTheme";
import store from '../store'

const { Link, Text, Title } = Typography

const Seller = () => {
    const prefixState = store.getState().prefix

    return (
        <>
            <EffectsContainer />
            <Row
                justify="center"
                align="middle"
                style={{ height: '100%' }}
                className='hero'
            >
                <SetTheme />
                <div className="auth-container-seller">
                    {prefixState.info?.logo ? (
                        <Avatar
                            className="avatar-logo avatar-logo-login"
                            style={{
                                width: prefixState.info?.prefix === "782S" ? '150px' : prefixState.info?.prefix === "ALD88" ? '240px' : undefined,
                                height: prefixState.info?.prefix === "782S" ? '200px' : undefined
                            }}
                            src={prefixState.info?.logo}
                            shape="square"
                        />
                    ) : (
                        <Avatar
                            className="avatar-logo"
                            shape="square"
                            size={125}>
                            {prefixState.info?.prefix}
                        </Avatar>
                    )}
                    <div className="wrapper">
                        <div className="form-card-seller">
                            <div className="form-card-seller-body">
                                <div className="contact-text" style={{ marginBottom: 20, textAlign: 'center' }}>
                                    <Title level={3} style={{ marginTop: 10 }}>Telegram Seller</Title>
                                </div>
                                <Row gutter={[{ xs: 16, sm: 24 }, { xs: 16, sm: 24 }]}>
                                    <Col xs={24} sm={12}>
                                        <Link href={"https://t.me/csfcn01"} target="_blank" rel="noreferrer">
                                            <div className="contact-card">
                                                <div className="contact-icon">
                                                    {/* <img src={`contacts/telegram.svg`} alt="" /> */}
                                                    <LazyLoadImage
                                                        alt={'telegram'}
                                                        effect={'blur'}
                                                        src={`contacts/telegram.svg`}
                                                    />
                                                </div>
                                                <div className="contact-text">
                                                    <Space direction="vertical">
                                                        <Text strong>Telegram CS#01</Text>
                                                        @csfcn01
                                                    </Space>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Link href={"https://t.me/csfcn07"} target="_blank" rel="noreferrer">
                                            <div className="contact-card">
                                                <div className="contact-icon">
                                                    {/* <img src={`contacts/telegram.svg`} alt="" /> */}
                                                    <LazyLoadImage
                                                        alt={'telegram'}
                                                        effect={'blur'}
                                                        src={`contacts/telegram.svg`}
                                                    />
                                                </div>
                                                <div className="contact-text">
                                                    <Space direction="vertical">
                                                        <Text strong>Telegram CS#07</Text>
                                                        @csfcn07
                                                    </Space>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Link href={"https://t.me/csfcn66"} target="_blank" rel="noreferrer">
                                            <div className="contact-card">
                                                <div className="contact-icon">
                                                    {/* <img src={`contacts/telegram.svg`} alt="" /> */}
                                                    <LazyLoadImage
                                                        alt={'telegram'}
                                                        effect={'blur'}
                                                        src={`contacts/telegram.svg`}
                                                    />
                                                </div>
                                                <div className="contact-text">
                                                    <Space direction="vertical">
                                                        <Text strong>Telegram CS#66</Text>
                                                        @csfcn66
                                                    </Space>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Link href={"https://t.me/csfcn99"} target="_blank" rel="noreferrer">
                                            <div className="contact-card">
                                                <div className="contact-icon">
                                                    {/* <img src={`contacts/telegram.svg`} alt="" /> */}
                                                    <LazyLoadImage
                                                        alt={'telegram'}
                                                        effect={'blur'}
                                                        src={`contacts/telegram.svg`}
                                                    />
                                                </div>
                                                <div className="contact-text">
                                                    <Space direction="vertical">
                                                        <Text strong>Telegram CS#99</Text>
                                                        @csfcn99
                                                    </Space>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>



    );
};

export default Seller;
