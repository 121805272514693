import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Spin, Typography } from 'antd'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import { requestPrefix } from '../../store/prefix/prefix.actions'
import { requestUser } from '../../store/user/user.actions'
import api from '../../helpers/api'
import CheckInItem from './CheckInItem';
import NumberWithCommas from '../../helpers/NumberWithCommas'
import { useAppContext } from '../../contexts/AppContext';
import { ApplicationState } from '../../store'
import { requestCheckIn } from '../../store/checkIn/checkIn.action'
import { makePath } from '../../helpers/path'
import { useHistory } from 'react-router-dom'

const { Title } = Typography

interface CheckInContentProps {
    modal?: boolean
}

const CheckInContent: FC<CheckInContentProps> = ({ modal = false }) => {
    const dispatch: ThunkDispatch = useDispatch()
    const history = useHistory()
    const [checkIn, setCheckIn] = useState<any>([])
    const [dayNow, setDayNow] = useState<any>([])
    const [dataDayChecked, setDataDayChecked] = useState<any>([])
    const [disableChecked, setDisableChecked] = useState<boolean>(false)
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const checkInState = useSelector((state: ApplicationState) => state.checkIn)

    const {
        setIsOpenRedeemSuccess,
        setRedeemAmount,
        setIsLoadedImagesRedeemSuccess,
        setIsModalCheckin,
        setExtraRedeemSuccess
    } = useAppContext();

    const claimReward = async () => {
        try {
            await api.post<void, any>('/member/checkin')
            setDisableChecked(true)
            dispatch(requestCheckIn(prefixState.info?.checkin.enable))
            dispatch(requestPrefix())
            dispatch(requestUser())

            const result = checkIn?.days[checkIn?.key];
            setDayNow(result)
            setIsModalCheckin(false)
            setRedeemAmount(result.creditReward)
            setExtraRedeemSuccess(checkIn.displayTurnWithdrawDisable ? `` : `เทิร์นเครดิต ` + result.turn + ` เท่า ถอนได้ ` + result.withdrawLimit + ` เท่า`)
            setIsLoadedImagesRedeemSuccess(true)
            setIsOpenRedeemSuccess(true)
        } catch ({ message }: any) {
            toast.error(message as string)
        }
    }

    useEffect(() => {
        // getCheckInMember()
        if (checkInState.info) {
            setCheckIn(checkInState.info?.checkin)
        }
    }, [checkInState])

    useEffect(() => {
        // เช็คอินวันที่ระบุ
        setDataDayChecked([])
        if (checkIn?.key) {
            let dataDayCheckIn: any[] = []; // ประกาศเป็นอาร์เรย์
            for (let i = 1; i <= checkIn?.key; i++) {
                if (i === checkIn?.key) {
                    if (checkIn?.daysCheckedIn[0]) {
                        setDisableChecked(true)
                        dataDayCheckIn.push("checked")
                    } else {
                        setDisableChecked(false)
                        dataDayCheckIn.push("pending")
                    }
                } else {
                    setDisableChecked(true)
                    dataDayCheckIn.push("checked")
                }
            }
            setDataDayChecked(dataDayCheckIn)
        }
    }, [checkIn])

    return (
        <>
            {modal && checkIn?.days && Object.keys(checkIn?.days).length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
                    <div>
                        <Title level={5} style={{ margin: 0 }}>เช็คอิน</Title>
                        <div style={{ fontSize: 20 }}>วันที่ {checkIn?.key} : ฝากสะสม {NumberWithCommas(checkIn?.days[checkIn?.key].depositTotal)} บาทขึ้นไป รับฟรี {NumberWithCommas(checkIn?.days[checkIn?.key].creditReward)} บาท</div>
                    </div>
                </div>
            )}

            <Spin tip="กำลังโหลด..." spinning={checkInState.isLoaded ? false : true}>
                <div className="check-in-container">
                    {Object.entries(checkIn?.days || []).map(([key, data]: [string, any]) => {
                        const day = parseInt(key, 10);
                        return <CheckInItem key={day} day={day} data={data} statusCheckIn={dataDayChecked[day - 1]} />;
                    })}
                </div>
                <Button
                    disabled={disableChecked}
                    type="primary"
                    onClick={claimReward}
                    style={{ width: '100%', margin: '16px 0 0' }}
                >
                    รับรางวัล
                </Button>
                {!modal && (
                    <div className="check-in-condition" >
                        <p style={{ marginBottom: 10 }}><u>เงื่อนไขการเช็คอิน</u></p>
                        {Object.entries(checkIn?.days || []).map(([key, data]: [string, any]) => {
                            const day = parseInt(key, 10);
                            return (
                                <div key={`div-${day}`}>
                                    <p key={`condition-${day}`}>วันที่ {day} : ฝากสะสม {NumberWithCommas(data.depositTotal)} บาทขึ้นไป รับฟรี {NumberWithCommas(data.creditReward)} บาท</p>
                                </div>
                            )
                        })}
                    </div>
                )}
            </Spin>
        </>
    )
}

export default CheckInContent
