import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { toast } from 'react-toastify'
import { JokerTechGame as _JokerTechGame } from '../../@types/jokertech'
import api from '../../helpers/api'

interface JokerTechResponse {
	token: string
	games: _JokerTechGame[]
}

const JokerTechGame = () => {
	const tokenRef = useRef('')
	const [games, setGames] = useState<_JokerTechGame[] | null>(null)

	const forwardToGame = (code: string) => (e: MouseEvent<any>) => {
		e.preventDefault()

		window.location.href = `http://www.gwc688.net?token=${tokenRef.current}&game=${code}&redirectUrl=${window.location.origin}`
	}

	const initJokerTechGame = useCallback(async () => {
		try {
			const { token, games } = await api.get<void, JokerTechResponse>(
				'/joker-tech'
			)

			tokenRef.current = token
			setGames(games)
		} catch ({ message }) {
			toast.error(message)
		}
	}, [])
	useEffect(() => {
		initJokerTechGame()
	}, [initJokerTechGame])

	if (!games) {
		return (<div className="loading-stage">
				<i className="fad fa-spinner fa-spin" />
			</div>)
	}
	return (
		<div className="wrapper">
			<div className="title-bar title-bar--sub-title">
				<h2>สล็อตออนไลน์</h2>
			</div>

			<div className="slot-wrapper">
				{games.map((game) => (
					<a
						href="#joker-tech"
						className="slot-card"
						onClick={forwardToGame(game.code)}
						key={game.code}>
						<img
							src={game.image}
							alt={game.name}
							className="slot-card__img"
						/>
						<span>{game.name}</span>
					</a>
				))}
			</div>
		</div>
	)
}

export default JokerTechGame
