import React, { FC } from 'react'
import dayjs from 'dayjs';
import { format } from '../../helpers/number'
import { Segmented } from 'antd';

interface DateFilterTabsProps {
    onChangeDate: (e: any) => void
    onDateActive: any
    isFetching: boolean
}

const DateFilterTabs: FC<DateFilterTabsProps> = ({ onChangeDate, onDateActive, isFetching }) => {
    const sDateActive = onDateActive?.start ? dayjs(onDateActive?.start).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const eDateActive = onDateActive?.end ? dayjs(onDateActive?.end).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');

    const onDateSearch = (date: any) => {
        if (date !== null) {
            onChangeDate && onChangeDate({
                start: dayjs(date[0]).format('YYYY-MM-DD'),
                end: dayjs(date[1]).format('YYYY-MM-DD'),
            })
        }
    }

    let activeClass: string = "";
    switch (`${sDateActive}-${eDateActive}`) {
        case `${dayjs().format('YYYY-MM-DD')}-${dayjs().format('YYYY-MM-DD')}`:
            // activeClass = "today"
            activeClass = `${dayjs().format('YYYY-MM-DD')}-${dayjs().format('YYYY-MM-DD')}-today`
            break;

        case `${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}`:
            // activeClass = "yesterday"
            activeClass = `${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-yesterday`
            break;

        case `${dayjs().startOf('month').format('YYYY-MM-DD')}-${dayjs().endOf('month').format('YYYY-MM-DD')}`:
            // activeClass = "this_month"
            activeClass = `${dayjs().startOf('month').format('YYYY-MM-DD')}-${dayjs().endOf('month').format('YYYY-MM-DD')}-this_month`
            break;

        case `${dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')}-${dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')}`:
            // activeClass = "last_month"
            activeClass = `${dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')}-${dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')}-last_month`
            break;

        default:
            break;
    }

    const setValue = (value: any) => {
        switch (value) {
            // case 'today':
            case `${dayjs().format('YYYY-MM-DD')}-${dayjs().format('YYYY-MM-DD')}-today`:
                onDateSearch([dayjs(), dayjs()])
                break;

            // case 'yesterday':
            case `${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-yesterday`:
                onDateSearch([dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')])
                break;

            // case 'seven_days':
            case `${dayjs().subtract(6, 'days').format('YYYY-MM-DD')}-${dayjs().format('YYYY-MM-DD')}-seven_days`:
                onDateSearch([dayjs().subtract(6, 'days'), dayjs()])
                break;

            // case 'this_week':
            case `${dayjs().startOf('week').format('YYYY-MM-DD')}-${dayjs().endOf('week').format('YYYY-MM-DD')}-this_week`:
                onDateSearch([dayjs().startOf('week'), dayjs().endOf('week')])
                break;

            // case 'this_month':
            case `${dayjs().startOf('month').format('YYYY-MM-DD')}-${dayjs().endOf('month').format('YYYY-MM-DD')}-this_month`:
                onDateSearch([dayjs().startOf('month'), dayjs().endOf('month')])
                break;

            // case 'last_month':
            case `${dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')}-${dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')}-last_month`:
                onDateSearch([dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')])
                break;

            default:
                break;
        }
    }

    const arrSegmented: any = [
        {
            label: (
                "วันนี้"
            ),
            // value: 'today',
            value: `${dayjs().format('YYYY-MM-DD')}-${dayjs().format('YYYY-MM-DD')}-today`,
        },
        {
            label: (
                "เมื่อวานนี้"
            ),
            // value: 'yesterday',
            value: `${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-${dayjs().subtract(1, 'days').format('YYYY-MM-DD')}-yesterday`,
        },
        {
            label: (
                "เดือนนี้"
            ),
            // value: 'this_month',
            value: `${dayjs().startOf('month').format('YYYY-MM-DD')}-${dayjs().endOf('month').format('YYYY-MM-DD')}-this_month`,
        },
        {
            label: (
                "เดือนที่แล้ว"
            ),
            // value: 'last_month',
            value: `${dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD')}-${dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD')}-last_month`,
        },
    ];

    return (
        <Segmented
            options={arrSegmented}
            value={activeClass}
            onChange={setValue}
            disabled={isFetching}
        />
    )
}

export default DateFilterTabs