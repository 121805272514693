import React, { FC, MouseEvent, useState, useRef } from 'react'
import api from '../../helpers/api'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import { requestPrefix } from '../../store/prefix/prefix.actions'
import { requestUser } from '../../store/user/user.actions'

import { useAppContext } from '../../contexts/AppContext';

interface Props {
    isSuccess?: boolean
}
interface IBonusFree {
    amount: number
    message: string
}

const RedeemFree: FC<Props> = ({ isSuccess }) => {
    const redeemFreeRef = useRef<HTMLDivElement>(null)
    const dispatch: ThunkDispatch = useDispatch()

    const {
        setIsOpenRedeemSuccess,
        setRedeemAmount,
        setIsLoadedImagesRedeemSuccess,
    } = useAppContext();

    const handleOpenRedeemSuccess = async (e: MouseEvent<any>) => {
        e.preventDefault()
        try {
            const res = await api.post<void, IBonusFree>('/member/bonus-free')

            setRedeemAmount(res.amount)
            dispatch(requestPrefix())
            dispatch(requestUser())
            setIsOpenRedeemSuccess(true)
            setIsLoadedImagesRedeemSuccess(true)
        } catch ({ message }: any) {
            toast.error(message as string)
            setIsOpenRedeemSuccess(false)
            setIsLoadedImagesRedeemSuccess(false)
        }
    }

    const handleCloseReddemFree = () => {
        redeemFreeRef.current?.remove()
    }

    return (
        <>
            <div ref={redeemFreeRef} className={`redeem-free-container`}>
                <div className="redeem-free" onClick={handleOpenRedeemSuccess}>
                    <img className="gift-bottom" src="/redeem-success/gift-bottom.webp" alt="gift-bottom" />
                    <img className="gift-top" src="/redeem-success/gift-top.webp" alt="gift-top" />
                    <div className="redeem-free-label">
                        <span style={{ position: 'relative', zIndex: 1 }}>เครดิตฟรี</span>
                    </div>
                </div>
                <div className="redeem-free-close" onClick={handleCloseReddemFree}>
                    <i className="fa-regular fa-circle-xmark"></i>
                </div>
            </div>

            {/* <RedeemSuccess amount={redeemAmount} isOpen={isOpenRedeemSuccess} isLoadedImages={isLoadedImages} onClose={handleCloseRedeemSuccess} /> */}
        </>
    )
}

export default RedeemFree
