import { getDomainWithoutSubdomain } from "../utils/domainWithoutSubdomain"

export const generateQRCodeURL = (data: string) => {
	// const APIEndPoint = process.env.REACT_APP_API_ENDPOINT || ''
	let APIEndPoint = process.env.REACT_APP_API_ENDPOINT || ''
	if (window.location.hostname !== 'localhost') {
		APIEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2` || ''
	}
	// const APIEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2` || ''
	return (APIEndPoint + '/generate-qr?data=' + encodeURIComponent(data)).replace('/v2/', '/')
}
