import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DepositBank } from '../../@types/prefix'
import { FetchDepositToListResponse } from '../../services/deposit'
import { requestDepositToDone, requestDepositToFailed } from './deposit.actions'

export interface State {
	isLoaded: boolean

	truewalletQREnable: boolean
	truewalletVoucherEnable: boolean
	redeemEnable: boolean
	ambPayEnable: boolean
	depositToList: DepositBank[]
}

export const defaultState: State = {
	isLoaded: false,

	truewalletQREnable: false,
	truewalletVoucherEnable: false,
	redeemEnable: false,
	ambPayEnable: false,
	depositToList: [],
}

export default reducerWithInitialState(defaultState)
	.case(requestDepositToDone, (state, payload: FetchDepositToListResponse) => ({
		...state,
		isLoaded: true,

		truewalletQREnable: payload.truewalletQREnable,
		truewalletVoucherEnable: payload.truewalletVoucherEnable,
		ambPayEnable: payload.ambPayEnable,
		redeemEnable: payload.redeemEnable,
		depositToList: payload.depositBanks,
	}))
	.case(requestDepositToFailed, (state, _) => ({
		...state,
		isLoaded: true,

		truewalletQREnable: false,
		truewalletVoucherEnable: false,
		redeemEnable: false,
		ambPayEnable: false,
		depositToList: [],
	}))
	.default((state) => state)

