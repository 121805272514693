import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { Button, Form, Input } from 'antd'

interface TransferFormProps {
	initAmount: number
}
const TransferForm: FC<TransferFormProps> = ({ initAmount, }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				try {
					const res = await api.post<void, APIResponse>(
						'/member/commission/withdraw',
						values
					)

					dispatch(requestUser())
					toast.success(res.message)
				} catch ({ message }: any) {
					toast.error(message as string)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.resetFields()
			form.setFieldsValue({
				'amount': initAmount
			})
		}
	}, [form, initAmount])

	return (
		<Form
			form={form}
			name="affiliatewithdrawForm"
			layout="vertical"
			style={{ marginTop: 8 }}
			hideRequiredMark>
			<Form.Item
				name="amount"
				style={{ display: 'none' }}
				rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการโยก' }]}
				hidden
			>
				<Input type="hidden" placeholder="จำนวนเงินที่ต้องการโยก" />
			</Form.Item>
			<div className="btn-submit">
				<Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} block>
					โยกเงินเข้ากระเป๋า
				</Button>
			</div>
		</Form>
	)
}

export default TransferForm
