import React, { FC } from 'react'
import { AskmebetGameProviderConfig, AskmebetGame } from '../../../entities/askmebet'
import { Col } from 'antd'

interface AmbmebetHasGameProps {
    config: Record<string, AskmebetGameProviderConfig>
    games: Record<string, AskmebetGame[]>
    type: string

    setProvider: (name: string) => void
    setGameType: (name: string) => void
}
const AmbmebetHasGame: FC<AmbmebetHasGameProps> = ({ config, games, type, setProvider, setGameType }) => {
    const dynamicEnabledProviders = Object.values(config).filter((provider) => provider.enable)
    return (
        <>
            {dynamicEnabledProviders
                .filter((item) => games.hasOwnProperty(item.id))
                .map((item) => (
                    games[item.id].length > 0 && (
                        <Col xs={6} sm={4} key={item.id}>
                            <div className='ambbet-provider-card'
                                onClick={() => {
                                    setProvider(item.id)
                                    setGameType(type)
                                }}>
                                    <div className='ambbet-provider-img'>
                                        <img src={`https://cdn.cdnbet.co/assets/${type}-provider/logo-${type}-${item.id}.png`} alt={item.id} />
                                    </div>
                            </div>
                        </Col>
                    )
                ))
            }
            {/* Providers */}
            {/* <div className={`ambbet-provider-has-games ${provider === '' ? '' : 'hide-provider'}`}>
                <Row gutter={[{ xs: 12, sm: 12, md: 24 }, { xs: 12, sm: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }}>
                    {dynamicEnabledProviders
                        .filter((item) => games.hasOwnProperty(item.id))
                        .map((item) => (
                            <Col xs={6} sm={4} key={item.id}>
                                <div className='ambbet-provider-card' onClick={() => setProvider(item.id)}>
                                    <img src={`/${type}-provider/logo-${type}-${item.id}.png`} alt={item.id} />
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div> */}

            {/* Games */}
            {/* <div className={`ambbet-games ${provider ? 'show-games' : ''}`}>
                <Row gutter={[{ xs: 12, sm: 12, md: 24 }, { xs: 12, sm: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }}>
                    <Col span={24}>
                        <h5 className="ant-typography" style={{ color: '#fff', marginBottom: 0 }}>{provider}</h5>
                    </Col>

                    {games.hasOwnProperty(provider)
                        && games[provider]
						.filter((game) => game.name !== 'Bali Vacation')
						.map((game) => (
                            <Col xs={6} sm={4} key={game.name}>
                                <a href={`#ambbet-${type}`} className={`ambmet-game-card`} onClick={triggerSlotLogin(game.id)} >
                                    <img src={game.imageURL} alt={game.name} className="ambmet-game-card-img" />
                                    <span>{game.name}</span>
                                </a>
                            </Col>
						))}
                </Row>
            </div> */}
        </>
    )
}

export default AmbmebetHasGame
