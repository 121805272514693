import React, { FC, useEffect, useState } from 'react'
import { Form, Modal, Input, Button, Divider } from 'antd'
import { PatternFormat } from 'react-number-format'
import PatternNumber from '../../helpers/PatternNumber'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import Turnstile from "react-turnstile";

interface ModalForgotPasswordProps {
    isOpen: boolean
    onClose: () => void
}


interface dataReq {
    phone: string
    bankNumber: string
    password: string
    confirm?: string
    captcha?: string
}

const ForgotPassword: FC<ModalForgotPasswordProps> = ({ isOpen, onClose }) => {
    const [form] = Form.useForm();
    const [resetCounter, setResetCounter] = useState(0);
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const prefix = prefixState.info
    const [tokenTurnstile, setTokenTurnstile] = useState<string>('')


    const handleSubmit = async () => {
        form.validateFields()
            .then(async (values: dataReq) => {
                setSubmitting(true)
                delete values.confirm
                values.phone = values.phone.replace(/-|_/g, '')
                if (values.phone.length !== 10) {
                    toast.error('เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร')
                    setSubmitting(false)
                    return
                }

                if (tokenTurnstile === "") {
                    toast.error('กรุณารอ Turnstile โหลดเสร็จสิ้น')
                    setResetCounter((prevCounter) => prevCounter + 1);
                    setSubmitting(false)
                    return
                }
                values.captcha = tokenTurnstile
                try {
                    const res = await api.post<void, APIResponse>('/forgotpassword', values)
                    toast.success(res.message as string)
                    setSubmitting(false)
                    onClose()
                } catch ({ message }: any) {
                    toast.error(message as string)
                    setResetCounter((prevCounter) => prevCounter + 1);
                    setSubmitting(false)
                }
            }).catch();
    }

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            setSubmitting(false)
        }
    }, [form, isOpen])

    return (
        <Modal
            className=""
            open={isOpen}
            title="ลืมรหัสผ่าน"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <Form
                form={form}
                name="forgotPasswordForm"
                layout="vertical"
                requiredMark={false}>
                <Form.Item
                    name="phone"
                    label="เบอร์โทรศัพท์"
                    rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                    style={{ marginBottom: 12 }}
                >
                    <PatternFormat
                        className="ant-input"
                        format="###-###-####"
                        mask="_"
                        type="tel"
                        placeholder="เบอร์โทรศัพท์"
                    />
                </Form.Item>
                <Form.Item
                    name="bankNumber"
                    label="เลขที่บัญชี"
                    rules={[
                        { required: true, message: 'กรุณากรอกเลขที่บัญชี' },
                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                    ]}
                    hasFeedback>
                    <Input placeholder="เลขที่บัญชี" inputMode='numeric' />
                </Form.Item>
                <Divider />
                <Form.Item
                    name="password"
                    label="รหัสผ่านใหม่"
                    rules={[
                        { required: true, message: 'กรุณากรอกรหัสผ่านใหม่' },
                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                    ]}
                    hasFeedback>
                    <Input.Password placeholder="รหัสผ่านใหม่" inputMode='numeric' maxLength={6} minLength={6} showCount />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="ยืนยันรหัสผ่าน"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        { required: true, message: 'กรุณายืนยันรหัสผ่าน!' },
                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="ยืนยันรหัสผ่าน" inputMode='numeric' maxLength={6} minLength={6} showCount />
                </Form.Item>
                {prefix?.recaptcha?.enable && (
                    <div style={{ marginTop: "5px", marginBottom: "10px" }}>
                        <Turnstile
                            key={resetCounter}
                            sitekey={prefix?.recaptcha.key}
                            theme="dark"
                            onVerify={(token) => setTokenTurnstile(token)}
                            retry='auto'
                            autoResetOnExpire={true}
                        />
                    </div>
                )}
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    style={{ marginBottom: 16 }}
                    block
                >
                    ยืนยัน
                </Button>
            </Form>

        </Modal>
    )
}

export default ForgotPassword
