import React, { useEffect, useRef, useState } from 'react'
import { Skeleton } from 'antd';
import { toast } from 'react-toastify';
import api from '../../helpers/api';

interface SeoResponse {
    htmlContent: string
}

const SeoContent = () => {
    const contentContaninerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [seoContent, setSeoContent] = useState<string>("");

    const getSEOContent = async () => {
        setLoading(true)
        try {
            const res = await api.get<void, SeoResponse>(
                `/seo-editor`
            )
            setSeoContent(res.htmlContent)
        } catch ({ message }: any) {
            toast.error(message as string)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSEOContent()
        if (contentRef.current) {
            contentRef.current.style.height = isCollapsed ? '0' : `${contentRef.current.scrollHeight}px`;
        }
    }, [isCollapsed]);

    const toggleCollapse = () => {
        if (contentContaninerRef.current) {
            contentContaninerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {seoContent && seoContent !== "" && (
                <div ref={contentContaninerRef} className="section-content-container">
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: seoContent }} ref={contentRef} className={`section-content-body ${!isCollapsed ? 'show' : ''}`} />
                    )}
                    <div className={`section-content-btn ${!isCollapsed ? 'add-padding' : ''}`} onClick={toggleCollapse}>
                        <strong>{isCollapsed ? 'ดูรายละเอียด' : 'ซ่อนรายละเอียด'}</strong>
                    </div>
                </div>
            )}
        </>
    )
}

export default SeoContent
