import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TransferForm from '../components/Commission/TransferForm'
import { ApplicationState } from '../store'
import { format } from '../helpers/number'
import { Button, Col, Divider, Input, Row, Statistic, Typography, theme, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { hexToHsl } from '../helpers/hexToHsl'
import api from "../helpers/api";
import useSWR from 'swr'
import { Redirect } from "react-router";

const { Text } = Typography;
const { useToken } = theme;

interface ICommissionBalance {
	balance: number
	message: string
	minWithdraw: string
}

const Commission = () => {
	const { token } = useToken()
	const defalutColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 8}%)`
	const lightColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 30}%)`

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info
	const getCommission = async () => {
		if (!prefix || !prefix.commission) {
			return 0
		}
		try {
			const res = await api.get<void, ICommissionBalance>(`/member/commission/balance`)
			// setCommissionBalance(res.balance)
			return res
		} catch ({ message }: any) {
			toast.error(message as string)
			// setCommissionBalance(0)
			return 0
		}
	}

	const { data: commissionBalance }: any = useSWR([`/member/commission/balance`], () => getCommission());

	const userState = useSelector((state: ApplicationState) => state.user);
	const [messageApi, contextHolder] = message.useMessage();
	const copyAffiliateLink = () => {
		navigator.clipboard.writeText(userState.affiliate.link);
		// toast.success("คัดลอกลิงก์ชวนเพื่อนสำเร็จ");
		messageApi.open({
			type: 'success',
			content: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
		});
	};

	const qrRef = useRef(null);
	const [qrRender, setQrRender] = useState(false);

	const qrCode = new QRCodeStyling({
		width: 150,
		height: 150,
		data: userState.affiliate.link,
		// image: prefixState?.info?.prefix !== 'VIPBET88' ? logo : '',
		margin: 0,
		type: "svg",
		qrOptions: {
			typeNumber: 0,
			mode: "Byte",
			errorCorrectionLevel: "Q",
		},
		imageOptions: {
			hideBackgroundDots: true,
			imageSize: 0.4,
			margin: 0,
		},
		dotsOptions: {
			type: "rounded",
			gradient: {
				type: "linear",
				rotation: 0.8726646259971648,
				colorStops: [
					{
						offset: 0,
						color: lightColorPrimary
					}, {
						offset: 1,
						color: defalutColorPrimary
					}
				],
			},
		},
		backgroundOptions: {
			color: token.colorBgContainer,
		},
		cornersSquareOptions: {
			type: "extra-rounded",
			gradient: {
				type: "linear",
				rotation: 1.5707963267948966,
				colorStops: [
					{
						offset: 0,
						color: lightColorPrimary
					}, {
						offset: 1,
						color: defalutColorPrimary
					}
				],
			},
		},
		cornersDotOptions: {
			gradient: {
				type: "linear",
				rotation: 1.5707963267948966,
				colorStops: [
					{
						offset: 0,
						color: lightColorPrimary
					}, {
						offset: 1,
						color: defalutColorPrimary
					}
				],
			},
		},
	});

	useEffect(() => {
		if (!qrRender && qrRef.current && userState.info) {
			qrCode.append(qrRef.current!);
			setQrRender(true);
		}
	}, [userState, qrRender]); //eslint-disable-line

	if (!prefix || !prefix.commission) {
		return <Redirect to="/affiliate" />
	}

	return (
		<>
			{contextHolder}
			<Row justify="center" className="margin-form-card">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div className="invite-qrcode-container" style={{ marginBottom: 16 }}>
								<div className="invite-qrcode" ref={qrRef} />
							</div>

							<div>
								<Text type="secondary" style={{ display: 'block', marginBottom: 8 }}>ลิงก์ชวนเพื่อน</Text>
								<Input.Group
									style={{ display: 'flex' }}
									compact
								>
									<Input
										defaultValue={userState.affiliate.link}
										style={{ background: '#0d0f10', color: '#fff' }}
										disabled
									/>
									<Button
										type='primary'
										icon={<CopyOutlined style={{ fontSize: 18, marginTop: 1 }} rev={undefined} />}
										style={{ height: 'auto' }}
										onClick={copyAffiliateLink}
									>
										<Text style={{ fontSize: 22 }}>คัดลอก</Text>
									</Button>
								</Input.Group>
								<Divider style={{ borderBlockStart: '1px solid rgba(255, 255, 255, 0.1)' }} />
							</div>

							<Row gutter={16} style={{ marginBottom: 16 }}>
								<Col xs={12}>
									<Statistic title="ยอดคอมมิชชั่น" value={format(commissionBalance?.balance || 0)} prefix="฿" />
								</Col>
								<Col xs={12}>
									<Statistic title="รหัสชวนเพื่อน" value={userState.affiliate.code} />
								</Col>
							</Row>
							<Text type="danger" style={{ display: 'block', marginBottom: 8 }}>**ยอดคอมมิชชั่นขั้นต่ำ : ฿ {format(commissionBalance?.minWithdraw || 0)}</Text>
							<TransferForm initAmount={commissionBalance?.balance || 0} />
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Commission;
