import React from 'react'
import { makePath } from '../helpers/path';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';

const Peer2Pay = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix);

    const goBack = () => {
        window.location.href = makePath(`/deposit`);
    };

    return (
        <>
            <div className="topbar-game">
                <div
                    onClick={goBack}
                    style={{ display: 'flex', fontSize: 20, padding: '8px 8px 8px 4px', cursor: 'pointer' }}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <Link to={makePath('/wallet')}>
                    {prefixState.info?.logo ? (
                        <Avatar
                            className="avatar-logo avatar-logo-topbar"
                            src={prefixState.info?.logo}
                            shape="square"
                        />
                    ) : (
                        <div className="prefix-name">{prefixState.info?.prefix}</div>
                    )}
                </Link>
            </div>
            <iframe
                src={`https://peer2pay.com/th/auth/register/`}
                title="Game"
                allowFullScreen
                className={`iframe-game`}
            ></iframe>
        </>
    )
}

export default Peer2Pay