import React, { FC, useState } from 'react'
import { format } from '../../helpers/number'
import { Statistic, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { requestUser, setUser } from '../../store/user/user.actions'
import AuthService from '../../services/auth'
import { toast } from 'react-toastify'
const { Text } = Typography

interface TurnWidgetProps {
	totalTurn: number
	currentTurnover: number
	turnover: number
	showTurnover: boolean
}

const TurnWidget: FC<TurnWidgetProps> = ({ totalTurn, currentTurnover, turnover, showTurnover }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const dispatch = useDispatch()

	const refetchCredit = async () => {
		setIsLoading(true)
		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }: any) {
			toast.error(message as string)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			{!showTurnover ? (
				<>
					<div className="turnover-stat">
						<Text type="secondary">ยอดเทิร์นเครดิต</Text>
						<div className="turnover-stat-amount">
							<span style={{ fontSize: 28, color: "#fff" }}>{format(totalTurn)}</span>
						</div>
					</div>
				</>

			) : (
				<>
					<div className="turnover-stat">
						<Text type="secondary">
							<span>ยอดเทิร์นโอเวอร์ </span>
							<span>
								{isLoading ? (
									<LoadingOutlined style={{ fontSize: 18, marginLeft: 6 }} rev={undefined} />
								) : (
									<i className="fa-sharp fa-regular fa-arrows-rotate" style={{ fontSize: 18, marginLeft: 6 }} onClick={refetchCredit}></i>
								)}
							</span>
						</Text>
						<div className="turnover-stat-amount">
							<span style={{ fontSize: 28, color: "#fff" }}>{format(currentTurnover)}</span>
							<span style={{ fontSize: 20, color: "#fff", marginLeft: 4 }}>/ {format(turnover)}</span>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default TurnWidget


