import React from 'react'
import './../../assets/scss/effects/particles-wrap.scss';

const ParticlesWrap = () => {
    const numberOfBubbles: number = 100;

    const bubbles = Array.from({ length: numberOfBubbles }, (_, index) => (
        <div key={index} className="bubble"></div>
    ));

    return (
        <div className="bubble-wrap-container">
            <div className="bubble-wrap">{bubbles}</div>
        </div>
    )
}

export default ParticlesWrap
