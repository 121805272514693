import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FloatButton, message, Tooltip, theme } from 'antd'
import { UpOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { requestProvider, requestProviderInPromotion } from '../../store/provider/provider.action'
import { ApplicationState } from '../../store';

interface Props {
    prefixState: any
    setIsToggleSound?: (e: any) => void
}

const { useToken } = theme

const ButtonFooter: FC<Props> = ({ prefixState, setIsToggleSound }) => {
    const { token } = useToken()
    const dispatch = useDispatch()
    const history = useHistory();
    const loc = useLocation()
    const pathname = loc.pathname
    const [tooltipOpen, setTooltipOpen] = useState(true);
    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
    const [isSound, setIsSound] = useState(false);
    const [scrollY, setScrollY] = useState<number>(0);
    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 768);
    const translateY = isDesktop ? (scrollY >= 400 ? -72 : 0) : (scrollY >= 400 ? -56 : 0);
    const offsetButton = isDesktop ? 72 : 56;
    const [messageApi, contextHolder] = message.useMessage();

    const userState = useSelector((state: ApplicationState) => state.user)
    const isGetBonus = userState.info?.bonusAvailable ? false : true
    const isGetRedeem = userState.info?.redeemAvailable ? false : true

    const params = new URLSearchParams(loc.search);
    const filterQuery = params.get('filter');

    const [isGamePromotion, setIsGamePromotion] = useState<boolean>(filterQuery === 'on' ? true : false);

    const onChangeSound = (checked: boolean) => {
        localStorage.setItem('sound', checked ? 'On' : 'Off')
        if (checked) {
            setIsSound(true)
            setIsToggleSound && setIsToggleSound(true)
        } else {
            setIsSound(false)
            setIsToggleSound && setIsToggleSound(true)
        }
    };

    const onGetLocalSound = () => {
        const getLocalSound = localStorage.getItem('sound')
        if (getLocalSound === 'On') {
            setIsSound(true)
        }
        if (getLocalSound === null) {
            localStorage.setItem('sound', 'On')
            setIsSound(true)
        }
    };

    const handleGamePromotion = () => {
        const newIsGamePromotion = (filterQuery === 'off') ? true : false;
        setIsGamePromotion(newIsGamePromotion);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const params = new URLSearchParams(loc.search);
        if (filterQuery === 'off') {
            params.set('filter', 'on');
            history.push({ search: params.toString() });
        } else {
            params.set('filter', 'off');
            history.push({ search: params.toString() });
        }

        if (newIsGamePromotion) {
            dispatch(requestProviderInPromotion(prefixState.info?.game, newIsGamePromotion))
            localStorage.setItem('isGamePromotion', 'on')
            messageApi.open({
                type: 'success',
                content: 'แสดงเฉพาะเกมตามโปรโมชั่น',
            });
        } else {
            dispatch(requestProvider(prefixState.info?.game))
            localStorage.setItem('isGamePromotion', 'off')
            messageApi.open({
                type: 'success',
                content: 'แสดงเกมทั้งหมด',
            });
        }
    };

    useEffect(() => {
        localStorage.setItem('isGamePromotion', filterQuery === 'on' ? 'on' : 'off')
    }, [filterQuery])

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;
            setShowMenuSupport(isNullishSocialMedia)
        }
    }, [prefixState])

    useEffect(() => {
        onGetLocalSound()
    }, [isSound])

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (history.location.pathname === '/launch-game' && tooltipOpen) {
            const timeoutId = setTimeout(() => {
                setTooltipOpen(false);
            }, 5000);

            return () => clearTimeout(timeoutId);
        }
    }, [history.location.pathname]); // eslint-disable-line

    return (
        <>
            {contextHolder}
            {pathname !== '/play' &&
                <div className="button-footer-container">
                    {prefixState?.info?.systemMedia && prefixState.info.systemMedia.url && (
                        isSound ?
                            <FloatButton icon={<i className="fa-solid fa-volume fa-beat" style={{ color: "#fff" }}></i>} onClick={() => { onChangeSound(false) }} style={{ transform: `translateY(${translateY}px)` }} />
                            :
                            <FloatButton icon={<i className="fa-solid fa-volume-xmark " style={{ color: "#FF0000" }}></i>} onClick={() => { onChangeSound(true) }} style={{ transform: `translateY(${translateY}px)` }} />
                    )}

                    {pathname === '/launch-game' && (isGetBonus || isGetRedeem) && (
                        <Tooltip title="แสดงเฉพาะเกมโปรโมชั่น / โค้ดคูปอง" placement="left" open={tooltipOpen} color={`var(--color-secondary)`} overlayInnerStyle={{ color: 'var(--color-text-secondary-bg)' }}>
                            {isGamePromotion ?
                                <FloatButton
                                    icon={<i className="fa-duotone fa-grid-2" style={{ color: token.colorSuccess }}></i>}
                                    onClick={handleGamePromotion}
                                    style={{
                                        transform: `translateY(${translateY - offsetButton + (!prefixState?.info?.systemMedia.url ? offsetButton : 0)}px)`,
                                        border: `1px solid ${token.colorSuccess}`,
                                    }}
                                />
                                :
                                <FloatButton
                                    icon={<i className="fa-light fa-grid-2" style={{ color: "#fff" }}></i>}
                                    onClick={handleGamePromotion}
                                    style={{
                                        transform: `translateY(${translateY - offsetButton + (!prefixState?.info?.systemMedia.url ? offsetButton : 0)}px)`,
                                        animation: tooltipOpen ? 'pulse 2s infinite' : 'none',
                                        border: tooltipOpen ? `1px solid var(--color-secondary)` : '1px solid transparent'
                                    }}
                                />
                            }
                        </Tooltip>
                    )}

                    {!showMenuSupport && (
                        <FloatButton.Group
                            trigger="click"
                            style={{ transform: `translateY(${translateY - (pathname === '/launch-game' && ((isGetBonus || isGetRedeem)) ? offsetButton : 0) + (!prefixState?.info?.systemMedia.url ? offsetButton : 0)}px)` }}
                            icon={<img src={`/icons/new/help.svg`} alt="icon" />}
                        >
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.phoneNumber &&
                                <FloatButton icon={<img src={`/contacts/phone.svg`} alt="icon" />} href={`tel:${prefixState?.info?.socialMedia.phoneNumber}`} />
                            }
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.facebook &&
                                <FloatButton icon={<img src={`/contacts/facebook.svg`} alt="icon" />} href={prefixState?.info?.socialMedia.facebook} target="_blank" />
                            }
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.x &&
                                <FloatButton icon={<img src={`/contacts/x.svg`} alt="icon" />} href={prefixState?.info?.socialMedia.x} target="_blank" />
                            }
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.instagram &&
                                <FloatButton icon={<img src={`/contacts/ig.svg`} alt="icon" />} href={prefixState?.info?.socialMedia.instagram} target="_blank" />
                            }
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.line &&
                                <FloatButton icon={<img src={`/contacts/line.svg`} alt="icon" />} href={prefixState?.info?.socialMedia.line} target="_blank" />
                            }
                            {prefixState?.info?.socialMedia && prefixState?.info?.socialMedia.telegram &&
                                <FloatButton icon={<img src={`/contacts/telegram.svg`} alt="icon" />} href={prefixState?.info?.socialMedia.telegram} target="_blank" />
                            }
                        </FloatButton.Group>
                    )}

                    {prefixState?.info?.lineLink && showMenuSupport && (
                        <FloatButton icon={<img src={`/contacts/line.svg`} alt="icon" />} href={prefixState?.info?.lineLink} target="_blank" style={{ transform: `translateY(${translateY - (pathname === '/launch-game' && isGetBonus ? 2 * offsetButton : offsetButton)}px)` }} />
                    )}

                    <FloatButton.BackTop className="ant-btn-primary" tooltip={"กลับสู่ด้านบน"} icon={<UpOutlined rev={undefined} />} />
                </div>
            }
        </>
    )
}

export default ButtonFooter
