import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { format } from '../../helpers/number';
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setUser } from '../../store/user/user.actions'
import AuthService from '../../services/auth'

interface Props {
    amount: number;
    isOpen: boolean;
    isLoadedImages: boolean;
    extraText?: string;
    onClose: () => void;
}

const RedeemSuccess: FC<Props> = ({ amount, isOpen, isLoadedImages, extraText = "", onClose }) => {
    const [fetching, setFetching] = useState(false)
    const dispatch = useDispatch()

    const refetchCredit = async () => {
        if (fetching) {
            return
        }
        setFetching(true)
        try {
            const res = await AuthService.getMemberInfo(true)
            dispatch(setUser(res))
        } catch ({ message }: any) {
            toast.error(message as string)
        } finally {
            setFetching(false)
        }
    }
    return (
        <>
            <Modal
                className="ant-popup ant-redeem-success-popup"
                open={isOpen}
                title=""
                centered
                onCancel={onClose}
                footer={null}
                mask={!isLoadedImages ? false : true}
                wrapClassName={!isLoadedImages ? 'loaded-images' : ''}
            >
                <div className="redeem-success-container">
                    <img className="polar animated" src="/redeem-success/polar.webp" alt="polar" />
                    <img className="gift-bottom animated" src="/redeem-success/gift-bottom.webp" alt="gift-bottom" />
                    <img className="light-in-box animated" src="/redeem-success/light-in-box.webp" alt="light-in-box" />
                    <img className="flare-4 animated" src="/redeem-success/flare-4.webp" alt="flare-4" />
                    <img className="flare-3 animated" src="/redeem-success/flare-3.webp" alt="flare-3" />
                    <img className="flare-2 animated" src="/redeem-success/flare-2.webp" alt="flare-2" />
                    <div className="coin-container animated">
                        <img className="coin" src="/redeem-success/coin.webp" alt="coin" />
                        <div className={`coin-amount ${amount >= 100 ? 'text-small' : ''}`}>{amount}</div>
                    </div>
                    <img className="flare-1 animated" src="/redeem-success/flare-1.webp" alt="flare-1" />
                    <img className="gift-top animated" src="/redeem-success/gift-top.webp" alt="gift-top" />
                    <img className="congrat animated" src="/redeem-success/congrat.gif" alt="congrat" />
                    <div className="redeem-success-title">ยินดีด้วย!</div>
                    <div className={`redeem-success-result ${extraText ? "has-extra" : ""}`}>คุณได้รับ {format(amount)} บาท</div>
                    <div className="redeem-success-extra">{extraText}</div>
                    <div className="reddem-success-close" onClick={() => {onClose(); refetchCredit()}}>
                        <CloseCircleOutlined rev={undefined} style={{ fontSize: 48  }}/>
                    </div>
                </div>

            </Modal >
        </>
    );
};

export default RedeemSuccess;