import React from 'react'
import './../../assets/scss/effects/galaxy.scss';

const Galaxy = () => {
    return (
        <div className="star-field">
            <div className="layer"></div>
            <div className="layer"></div>
            <div className="layer"></div>
        </div>
    )
}

export default Galaxy
