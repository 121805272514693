import React from 'react'
import './../../assets/scss/effects/firefly.scss';

const FireFly = () => {
    const quantity: number = 30;

    const fireflies = Array.from({ length: quantity }, (_, index) => (
        <div key={index} className="firefly"></div>
    ));

    return <div className="fireflies-container">{fireflies}</div>
}

export default FireFly
