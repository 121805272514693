import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import { Promotion } from '../../@types/prefix'
import api from '../../helpers/api'
import WalletPromotion from './WalletPromotion'
import { Typography, Row } from 'antd';

const { Title } = Typography;

interface WalletPromotionContainerProps {
	promotions?: Promotion[]
}

const WalletPromotionContainer: FC<WalletPromotionContainerProps> = ({
	promotions,
}) => {
	const [acceptingBonus, setAcceptingBonus] = useState('')
	const acceptBonus = async (key: string) => {
		setAcceptingBonus(key)

		try {
			const res = await api.post<void, APIResponse>(
				`/member/promotions/${key}`
			)
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setAcceptingBonus('')
		}
	}

	return (
		<div className="promotion-wallet-container">
			<Typography.Title level={4} style={{ margin: '8px 0 12px' }}>
				โปรโมชั่น
			</Typography.Title>
			<Row gutter={[16, 16]}>
				{promotions?.map((promo) => (
					<WalletPromotion
						data={promo}
						acceptingBonus={acceptingBonus}
						acceptBonus={acceptBonus}
						key={promo.key}
					/>
				))}
			</Row>
		</div>
	)
}

export default WalletPromotionContainer
