import React, { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { Member } from '../../@types/member'
import api from '../../helpers/api'
import { isMobile } from '../../helpers/utils'
import { makePath } from '../../helpers/path'

interface UFABetLoginResponse {
	viewState: string
	viewStateGenerator: string
	eventValidation: string

	url?: string
}

interface UFABetGameProps {
	member: Member
}
const UFABetGame: FC<UFABetGameProps> = ({ member }) => {
	const [ufaBetLogin, setUFABetLogin] = useState<UFABetLoginResponse | null>(null)

	const history = useHistory()
	const formRef = useRef<HTMLFormElement>(null)
	useEffect(() => {
		if (!ufaBetLogin) {
			try {
				api.get<void, UFABetLoginResponse>('/ufabet', {
					params: {
						mode: isMobile() ? 'mobile' : 'desktop',
					},
				}).then((res) => {
					if (res.url) {
						window.location.href = res.url
					} else {
						setUFABetLogin(res)

						formRef.current && formRef.current!.submit()
					}
				})
			} catch ({ message }) {
				toast.error(message as string)
				history.push(makePath('/wallet'))
			}
		}
	}, [ufaBetLogin, history])

	if (!ufaBetLogin) {
		return null
	}
	if (isMobile()) {
		return (
			<form method="POST" action="https://betufa.com/HomeSmart.aspx?lang=EN-GB" ref={formRef}>
				<input type="hidden" name="__EVENTTARGET" value="btnSignIn" />
				<input type="hidden" name="__EVENTARGUMENT" value="" />
				<input type="hidden" name="__VIEWSTATE" value={ufaBetLogin.viewState} />
				<input type="hidden" name="__VIEWSTATEGENERATOR" value={ufaBetLogin.viewStateGenerator} />
				<input type="hidden" name="__EVENTVALIDATION" value={ufaBetLogin.eventValidation} />
				<input type="hidden" name="txtUserName" value={member.game.username} />
				<input type="hidden" name="password" value={member.game.password} />
			</form>
		)
	}
	return (
		<form method="POST" action="https://betufa.com/Default8.aspx?lang=EN-GB" ref={formRef}>
			<input type="hidden" name="__EVENTTARGET" value="btnLogin" />
			<input type="hidden" name="__EVENTARGUMENT" value="" />
			<input type="hidden" name="__VIEWSTATE" value={ufaBetLogin.viewState} />
			<input type="hidden" name="__VIEWSTATEGENERATOR" value={ufaBetLogin.viewStateGenerator} />
			<input type="hidden" name="__EVENTVALIDATION" value={ufaBetLogin.eventValidation} />
			<input type="hidden" name="txtUserName" value={member.game.username} />
			<input type="hidden" name="password" value={member.game.password} />
			<input type="hidden" name="lstLang" value="Default8.aspx?lang=EN-GB" />
		</form>
	)
}

export default UFABetGame
