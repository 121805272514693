import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Progress } from 'antd'
import { format } from '../helpers/number'
import { maskPhoneNumber } from '../helpers/maskPhoneNumber'
import api from '../helpers/api'
import { toast } from 'react-toastify'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import useSWR from 'swr'

interface IRanking {
	data: IRank
}

interface IRank {
	myRankPosition: number
	topRank: ITopRank[]
}

interface ITopRank {
	rank: {
		name: string
		point: number
	}
	firstName: string
}

const Ranking = () => {
	const prefix: any = useSelector((state: ApplicationState) => state.prefix.info)
	const userState = useSelector((state: ApplicationState) => state.user)
	const [listRanking, setListRanking] = useState<any>()

	const getRanking = async () => {
		try {
			const res = await api.get<void, IRanking>(`/member/toprank`)
			return res?.data
		} catch ({ message }: any) {
			toast.error(message as string)
			return []
		}
	}
	const { data } = useSWR([`/member/toprank`], () => getRanking(), { refreshInterval: 30000 });

	const myName: string = userState.info ? userState.info.firstName : ""
	const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

	useEffect(() => {
		if (data) {
			setListRanking(data);
		}
		return () => {

		}
	}, [data])

	if (prefix.ranks === null || prefix.ranks === undefined) {
		return <Redirect to="wallet" />
	}

	const entries = Object.entries(prefix.ranks)
	entries.sort((a: any, b: any) => a[1] - b[1])
	const sortedDataRanks = Object.fromEntries(entries);


	const keysArrayRanks = Object.keys(sortedDataRanks)
	const valuesArrayRanks = Object.values(sortedDataRanks)

	const currentRanks = myRank.name !== "" ? myRank.name : keysArrayRanks[0];
	const finalRanks = keysArrayRanks[keysArrayRanks.length - 1];

	const indexOfCurrent = keysArrayRanks.indexOf(currentRanks);

	const nextKeyRanks: any = keysArrayRanks[indexOfCurrent + 1];
	const nextValueRanks: any = valuesArrayRanks[indexOfCurrent + 1];
	const percentNextRanks: any = (myRank.point / nextValueRanks) * 100;




	if (!prefix?.functions.rank) {
		return <Redirect to="wallet" />
	}

	return (
		<Row justify="center">
			<Col xs={24} md={16} xl={12}>
				<div style={{ color: '#fff', marginBottom: 8 }}>อันดับของฉัน</div>
				<div className="ranking-card">
					<div className="ranking-card-body">
						<div className="ranking-card-icon">
							<img src={`/ranks/${myRank.name === '' ? 'bronze' : myRank.name}.svg`} alt={myRank.name === '' ? 'bronze' : myRank.name} />
						</div>
						<div className="ranking-card-name">
							<div>{myName}</div>
							<div><small>อันดับ {format(listRanking?.myRankPosition ? listRanking.myRankPosition : 0, 0)}</small></div>
						</div>
						<div className="ranking-card-point">
							<span>{format(myRank.point, 0)}</span>
							<img src={'/icons/new/trophy.svg'} alt="icon" />
						</div>
					</div>

					{/* ---------- */}
					{/* เงื่อนไข ถ้าถึงแรงค์ Supreme แล้วตรงนี้จะไม่ขึ้น */}
					<div className="ranking-card-footer">
						{myRank.name === finalRanks ?
							<>
								<div className="ranking-card-progress-info">
									<div style={{ margin: '0 auto' }}>{finalRanks.toUpperCase()}</div>
								</div>
								<Progress percent={100} className="supreme" showInfo={false} />
							</>
							:
							<>
								<div className="ranking-card-progress-info">
									<div>{currentRanks.toUpperCase()}</div> {/* ชื่อแรงค์ปัจจุบัน */}
									<div>{format(myRank.point, 0)} / {format(nextValueRanks, 0)}</div> {/* (แต้มปัจจุบัน) / (แต้มที่จะขึ้นแรงค์ต่อไป) */}
									<div style={{ opacity: 0.75 }}>{nextKeyRanks.toUpperCase()}</div> {/* ชื่อแรงค์ต่อไป */}
								</div>
								<Progress percent={percentNextRanks} status="active" showInfo={false} />
							</>
						}
					</div>
					{/* ---------- */}

				</div>
				<Divider style={{ margin: '16px 0' }} />
				<div style={{ color: '#fff', marginBottom: 8 }}>ตารางอันดับ</div>
				<Row gutter={[12, 16]}>
					{(listRanking !== undefined && listRanking?.topRank.length > 0) && (listRanking.topRank).map((item: any, index: number) => (
						<Col span={24} className="ranking-col" key={index}>
							<div className="ranking-card top-ten">
								<div className="ranking-card-body">
									<div className="ranking-card-num">{index + 1}</div>
									<div className="ranking-card-icon">
										<img src={`/ranks/${item?.rank.name}.svg`} alt={item?.rank.name} />
									</div>
									{/* {prefix.prefix === "MUMMY" ? (
										<div className="ranking-card-name">{maskPhoneNumber(item.phone)}</div>
									) : ( */}
									<div className="ranking-card-name">{item.firstName}</div>
									{/* )} */}
									<div className="ranking-card-point">
										<img src={'/icons/new/trophy.svg'} alt="icon" />
										<span>{format(item?.rank.point ? item.rank.point : 0, 0)}</span>
									</div>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</Col >
		</Row >
	)
}

export default Ranking
