import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import Snowfall from '../Effects/Snowfall'
import FireFly from '../Effects/FireFly'
import Galaxy from '../Effects/Galaxy'
import Rainny from '../Effects/Rainny'
import ParticlesWrap from '../Effects/ParticlesWrap'
import MovingSquares from '../Effects/MovingSquares'

const EffectsContainer = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    return <>
        {prefixState.info?.bgImageEffect === 'snowfall' && <Snowfall />}
        {prefixState.info?.bgImageEffect === 'firefly' && <FireFly />}
        {prefixState.info?.bgImageEffect === 'galaxy' && <Galaxy />}
        {prefixState.info?.bgImageEffect === 'rainny' && <Rainny />}
        {prefixState.info?.bgImageEffect === 'particles-wrap' && <ParticlesWrap />}
        {prefixState.info?.bgImageEffect === 'moving-squares' && <MovingSquares />}
    </>
}

export default EffectsContainer
