import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../@types/api'
import PromotionListItem from '../components/Promotion/PromotionListItem'
import api from '../helpers/api'
import { ApplicationState } from '../store'
import { requestUser } from '../store/user/user.actions'
import { Divider, Empty, Radio, RadioChangeEvent, Row } from 'antd'

const Promotion = () => {
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = prefixState.info, user = userState.info

	const [acceptingBonus, setAcceptingBonus] = useState('')
	const [autoBonusValue, setAutoBonusValue] = useState(user?.autoBonus)

	const acceptBonus = async (key: string) => {
		setAcceptingBonus(key)

		try {
			const res = await api.post<void, APIResponse>(
				`/member/promotions/${key}`
			)

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }: any) {
			toast.error(message as string)
		} finally {
			setAcceptingBonus('')
		}
	}

	const changeAutoBonus = async (key: RadioChangeEvent) => {
		try {
			const res = await api.patch<void, APIResponse>(
				`/member/promotions/${key.target.value}`
			)

			dispatch(requestUser())
			setAutoBonusValue(key.target.value)
			toast.success(res.message)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}

	if (!prefix || !user) {
		return (
			<div style={{ display: 'flex', width: '100%' }}>
				<Empty
					description='ไม่สามารถใช้งานโปรโมชั่นได้ในขณะนี้'
					image="/icons/new/empty-box.svg"
					imageStyle={{ height: 88 }}
					style={{ flex: 'auto', marginTop: 24 }}
				/>
			</div>
		)
	}

	return (
		<>
			<Radio.Group
				className="ant-radio-promotion"
				onChange={changeAutoBonus}
				defaultValue={autoBonusValue}
				value={autoBonusValue}
				style={{ width: '100%' }}
			>
				<Radio value="off" style={{ alignItems: 'center', width: '100%', marginTop: 8 }}>ไม่รับโบนัสอัตโนมัติ</Radio>
				<Divider />
				<Row gutter={[{ xs: 16, sm: 16, xl: 32 }, { xs: 16, sm: 16, xl: 32 }]}>
					{prefix.promotions.map((promotion) => (
						<PromotionListItem
							promotion={promotion}
							acceptingBonus={acceptingBonus}
							acceptBonus={acceptBonus}
							key={promotion.key}
						/>
					))}
				</Row>
			</Radio.Group>
		</>
	)
}

export default Promotion
