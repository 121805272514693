// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { redeemDeposit } from '../../services/deposit'
import { Button, Form, Modal, Input } from 'antd'
import { useAppContext } from '../../contexts/AppContext';

interface RedeemDialogProps {
	isOpen: boolean
	onClose: () => void
}

const RedeemDialog: FC<RedeemDialogProps> = ({ isOpen, onClose }) => {
	const [form] = Form.useForm();
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const {
		setIsOpenRedeemSuccess,
		setRedeemAmount,
		setIsLoadedImagesRedeemSuccess,
	} = useAppContext();

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				try {

					const res = await redeemDeposit({
						code: values.redeemCode,
					})

					onClose()
					setRedeemAmount(res.amount)
					setIsLoadedImagesRedeemSuccess(true)
					setIsOpenRedeemSuccess(true)
				} catch ({ message }: any) {
					toast.error(message as string)
					setSubmitting(false)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	const handlePaste = async (e: any) => {
		e.preventDefault();
		try {
			setLoading(true)
			// const pastedText = await navigator.clipboard.readText();
			// form.setFieldsValue({ 'redeemCode': pastedText });
			setTimeout(async () => {
				const text = await navigator.clipboard.readText();
				form.setFieldsValue({ 'redeemCode': text });
				setLoading(false)
			}, 500);
		} catch (error) {
			console.error('Failed to read clipboard data:', error);
			setLoading(false)
		}
	};

	useEffect(() => {
		if (form && isOpen) {
			form.resetFields()
			form.setFieldsValue({
				'redeemCode': ''
			})
			setLoading(false)
		}
	}, [form, isOpen])

	return (
		<>
			<Modal
				className="ant-modal-deposit"
				open={isOpen}
				title="เติมโค้ดคูปอง"
				centered
				onCancel={() => !isSubmitting && onClose()}
				footer={[
					<Button
						key={"btn-redeem"}
						type="primary"
						htmlType="submit"
						loading={isSubmitting}
						disabled={isSubmitting}
						onClick={handleSubmit}
						block
					>
						เติมโค้ดคูปอง
					</Button>
				]}
			>
				<Form
					form={form}
					name="redeemDialogForm"
					layout="vertical"
					hideRequiredMark>
					<div style={{ position: 'relative', overflow: 'hidden' }} >
						<Form.Item
							name="redeemCode"
							noStyle
							rules={[{ required: true, message: 'กรุณากรอกโค้ดคูปอง' }]}>
							<Input placeholder="กรอกโค้ดคูปอง" inputMode='text' />
						</Form.Item>
						<Button type="link" className="paste-btn" onClick={handlePaste} onTouchEnd={handlePaste} loading={loading} disabled={loading} style={{ height: '100%' }}>วาง</Button>
					</div>
				</Form>
			</Modal>

			{/* <RedeemSuccess amount={redeemAmount} isOpen={isOpenRedeemSuccess} isLoadedImages={isLoadedImages} onClose={handleCloseRedeemSuccess} /> */}
		</>
	)
}

export default RedeemDialog
