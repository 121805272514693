export const getImgProviderBetflix = (name: string) => {
    switch (name) {
        case "joker":
            return "njk"

        case "pg":
            return "pgs"

        case "pp":
            return "pmts"

        case "sn":
            return "spn"

        case "sa":
            return "sag"

        case "sexy":
            return "sex"

        case "netent":
            return "nete"

        case "funky":
            return "fk"

        case "ep":
            return "evo"

        case "mg":
            return "mgs"

        case "sp":
            return "smp"

        case "swg":
            return "sw"

        case "wm":
            return "wm"

        case "bg":
            return "bigg"

        case "ag":
            return "ag"

        case "km":
            return "kmk"

        case "hab":
            return "hbnr"

        case "rlx":
            return "rlg"

        case "hak":
            return "hsd"

        case "bng":
            return "bog"

        case "red":
            return "rtg"

        case "dg":
            return "dgm"

        case "pp":
            return "pmt"

        case "ps":
            return "psr"

        default:
            return name
    }
}

export const getImgGameTypeBetflix = (name: string) => {
    switch (name) {
        case "slot":
            return "icons/3d/slot.svg"

        case "fish":
            return "icons/3d/fish.svg"

        case "casino":
            return "icons/3d/casino.svg"

        case "sport":
            return "icons/3d/sport.svg"

        default:
            return name
    }
}