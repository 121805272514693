import React, { FC, MouseEvent, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AskmebetLoginResponse, AskmebetGame, AskmebetConfig } from '../../../entities/askmebet'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Col, Row, Typography } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons';
// import { config } from 'process'

const { Title } = Typography

interface AskmebetSlotProps {
	games: AskmebetConfig | any
	provider: string
	gameType: string

	setProvider: (name: string) => void
	setLoading: (e: boolean) => void
}
const AskmebetSlot: FC<AskmebetSlotProps> = ({ games, provider, gameType, setProvider, setLoading }) => {
	// const [redirecting, setRedirecting] = useState(false)
	const [configGames, setConfigGames] = useState<AskmebetGame | undefined>(undefined)
	const [providerName, setProviderName] = useState('')

	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambbet ใช้เส้นนี้นะครับ
			const res = await api.post<void, AskmebetLoginResponse>('/askmebet/login', {
				type: gameType,
				gameID: gameID,
				isMobile: isMobile(),
				productCode: provider,
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}


	useEffect(() => {
		if (gameType) {
			setConfigGames(games[gameType].games[provider])
			setProviderName(games[gameType].config[provider].name)
		}
	}, [gameType, games, provider])

	return (
		<Row gutter={[{ xs: 16, sm: 16, xl: 32 }, { xs: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }}>
			<Col span={24}>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 8
				}}>
					<Title level={5} style={{ color: '#fff', marginBottom: 0 }}>{providerName}</Title>
					<Button
						icon={<AppstoreOutlined />}
						onClick={() => setProvider('')}
					>
						<span>เลือกค่าย</span>
					</Button>
				</div>
			</Col>
			{configGames && Object(configGames).filter((game: any) => game.name !== 'Bali Vacation').map((game: any) => (
				<Col xs={8} sm={8} md={6} xl={4} className="col-4 col-md-3 col-xl-2" key={`${game.id}-${game.name}`}>
					<a href="#ambbet-slot" className="slot-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
						<img src={game.imageURL} alt={game.name} className="slot-card-img" />
						<span>{game.name}</span>
					</a>
				</Col>
			))}
		</Row>
	)
}

export default AskmebetSlot
