// import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from '../helpers/api'
import { Button, Col, Form, Modal, InputNumber, Row, Statistic, Typography } from 'antd'

const { Text } = Typography

interface AMBPayResponse {
	qrCode: string
}

const AMBPay = () => {
	const [form] = Form.useForm();
	const [qrCode, setQRCode] = useState('')
	const [isOpen, setIsOpen] = useState(false)

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				try {
					const res = await api.post<void, AMBPayResponse>('/member/ambpay', {
						amount: parseFloat(values.amount.toString()),
					})

					setQRCode(res.qrCode)
					setIsOpen(true)
				} catch ({ message }) {
					toast.error(message)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': 200
			})
		}
	}, [form])

	return (
		<Fragment>
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div style={{ marginBottom: 24 }}><Text>รายละเอียดการเติมเงิน</Text></div>
							<Row gutter={16} style={{ marginBottom: 24 }}>
								<Col span={12}>
									<Statistic title="ยอดเติมเงินขั้นต่ำ" value={200} prefix="฿" />
								</Col>
								<Col span={12}>
									<Statistic title="ยอดเติมเงินสูงสุด" value={500000} prefix="฿" />
								</Col>
							</Row>
							<Form
								form={form}
								name="ambpayForm"
								layout="vertical"
								hideRequiredMark>
								<Form.Item
									name="amount"
									label='จำนวนเงินที่ต้องการเติม'
									rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม' }]}>
									<InputNumber
										placeholder="จำนวนเงินที่ต้องการเติม"
										min={200}
										max={500000}
										inputMode="numeric"
										controls={false}
										formatter={(value) => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										style={{ width: '100%' }}/>
								</Form.Item>
								<div className="btn-submit">
									<Button type="primary" shape="round" htmlType="submit" loading={isSubmitting} onClick={handleSubmit} block>
										ยืนยันเติมเงิน
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</Col>
			</Row>

			<Modal
				open={isOpen}
				title="เติมเงิน"
				footer={null}
				onCancel={() => setIsOpen(false)}
				centered
			>
				<div className="ambpay-qrcode">
					<img src={qrCode} alt="QR Code" />
				</div>
				<div style={{ textAlign: 'center' }}>ชำระเงินผ่าน QR Code ด้านบน เพื่อเติมเงิน</div>
			</Modal>
		</Fragment>
	)
}

export default AMBPay
