import { Button, Modal, Space, Typography } from 'antd'
import React, { FC } from 'react'
import { makePath } from '../../helpers/path'

interface AlertProps {
    isOpen: boolean
    onClose: () => void
}
const Alert: FC<AlertProps> = ({ isOpen, onClose }) => {
    return (
        <Modal
            className="ant-modal-verify-phone"
            open={isOpen}
            title="กรุณาเข้าสู่ระบบ หรือ สมัครสมาชิก"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <Space direction='vertical' style={{ display: "flex" }}>
                <Button key="login" type="primary" onClick={() => makePath('/login')} block>
                    เข้าสู่ระบบ
                </Button>
                <Button key="register" onClick={() => makePath('/register')} block>
                    สมัครสมาชิก
                </Button>
            </Space>

        </Modal>
    )
}

export default Alert
