import React, { FC, useState, useEffect, useRef } from 'react'
import { Member } from '../../@types/member'
import { createPortal } from 'react-dom'


interface GClubGameProps {
    member: Member
}
const RenderInWindow: FC<GClubGameProps> = ({ member }) => {
    const [container, setContainer] = useState<HTMLDivElement | any>(null);
    const newWindow = useRef<any>(window);
    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        const div = document.createElement("div");
        setContainer(div);
    }, []);

    useEffect(() => {
        if (container) {
            newWindow.current = window.open(
                "",
                "",
                "width=600,height=400,left=200,top=200"
            );
            newWindow.current.document.body.appendChild(container);
            formRef.current && formRef.current!.submit()
            const curWindow = newWindow.current;
            setTimeout(() => {
                curWindow.close();
                window.location.href = "https://bbbs.bacc1688.com/Home/Index"
            }, 1000)
        }
    }, [container]);

    return container && createPortal(
        <form method="POST" action="https://bbbs.bacc1688.com/Api/LoginApi/MemberLogin/" ref={formRef}>
            <input type="hidden" name="G_Account" value={member.game.username} />
            <input type="hidden" name="G_Password" value={member.game.password} />
            <input type="hidden" name="G_MD5" value="true" />
        </form>
        , container);
};

const GClubGame: FC<GClubGameProps> = ({ member }) => {

    return (
        <>
            <RenderInWindow member={member} />
        </>
    )
}

export default GClubGame