import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { requestCheckInDone, requestCheckInFailed, setCheckIn } from './checkIn.action'

export interface State {
	isLoaded: boolean
	info?: any
}

export const defaultState: State = {
	isLoaded: false,
	info: undefined,
}

export default reducerWithInitialState<State>(defaultState)
	.cases([setCheckIn, requestCheckInDone], (state, payload) => ({
		...state,
		isLoaded: true,
		info: payload,
	}))
	.case(requestCheckInFailed, (state) => ({
		...state,
		isLoaded: true,
		info: undefined,
	}))
	.default((state) => state)