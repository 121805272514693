import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import api from '../../helpers/api'

const actionCreator = actionCreatorFactory('@@fcn/gamesHome')

export const setGamesHome = actionCreator<any>('setGamesHome')

export const requestGamesHomeDone = actionCreator<any>('requestGamesHomeDone')
export const requestGamesHomeFailed = actionCreator('requestGamesHomeFailed')

export const requestGamesHome = (game: any) => async (dispatch: ThunkDispatch) => {
	try {
		if (game === 'ambking') {
			const res = await api.get<void, any>('/listgame/ambking')
			return dispatch(requestGamesHomeDone(res))
		} else if (game === 'betflix') {
			// const res = await api.get<void, any>('/listgame/betflix')
			const res = await api.get<void, any>('/listgame/betflix/direct')
			return dispatch(requestGamesHomeDone(res))
		} else if (game === 'pgoon') {
			const res = await api.get<void, any>('/public/pgoon')
			return dispatch(requestGamesHomeDone(res))
		} else {
			return dispatch(requestGamesHomeFailed())
		}
	} catch (e) {
		console.error(e)
		return dispatch(requestGamesHomeFailed())
	}
}