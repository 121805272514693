import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import api from '../../helpers/api'

const actionCreator = actionCreatorFactory('@@fcn/checkIn')

export const setCheckIn = actionCreator<any>('setCheckIn')

export const requestCheckInDone = actionCreator<any>('requestCheckInDone')
export const requestCheckInFailed = actionCreator('requestCheckInFailed')

export const requestCheckIn = (checkInEnable: any) => async (dispatch: ThunkDispatch) => {
	try {
		if (checkInEnable) {
			const res = await api.get<void, any>('/member/checkin')
			return dispatch(requestCheckInDone(res))
		} else {
			return dispatch(requestCheckInFailed())
		}
	} catch (e) {
		console.error(e)
		return dispatch(requestCheckInFailed())
	}
}