import React, { FC } from 'react'

interface Props {
    prefix: any
}

const LoadingLayout: FC<Props> = ({ prefix }) => {
    return (
        <div className="loader-wrapper">
            <div className="loader-body">
                {prefix.info?.logo ? (
                    <div className="loader">
                        <div className="logo">
                            <img src={prefix.info?.logo} height="120" alt="logo" />
                        </div>
                    </div>
                ) : (
                    <div className='glowing-btn'>
                        <span className='glowing-txt'>{prefix.info?.prefix}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoadingLayout
