import React, { FC } from 'react'
import { Promotion } from '../../@types/prefix'
import WalletPromotion from './WalletPromotion'
import { Typography, Row } from 'antd';

interface WalletPromotionContainerProps {
	promotions?: Promotion[]
	prefix?: string
}

const WalletPromotionContainer: FC<WalletPromotionContainerProps> = ({
	promotions,
	prefix
}) => {


	return (
		<div className="promotion-wallet-container" style={{ display: 'block', marginTop: prefix === "PGOK" ? 0 : 24 }}>
			{prefix !== "PGOK" && (
				<Typography.Title level={4} style={{ margin: '8px 0 12px' }}>
					โปรโมชั่น
				</Typography.Title>
			)}
			<Row gutter={[16, 16]}>
				{promotions?.map((promo) => (
					<WalletPromotion
						data={promo}
						key={promo.key}
					/>
				))}
			</Row>
		</div>
	)
}

export default WalletPromotionContainer
