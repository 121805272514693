import React, { useLayoutEffect } from 'react'
import HomeLayout from '../../components/Layout/HomeLayout'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import Footer from '../../components/Layout/Footer'

const WhyUs = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    useLayoutEffect(() => {
        setTimeout(() => {
            document.title = 'รีวิวเกมสล็อตแตกง่าย จาก PG SLOT สุดฮิต';
        }, 300);
    }, []);

    return (
        <HomeLayout>
            <div className="achieve-container">
            <h1>รีวิวเกมสล็อตแตกง่าย จาก PG SLOT สุดฮิต</h1>
            <p>รีวิว<b>สล็อตแตกง่าย</b> เล่นง่าย ได้เงินจริง ต้องเว็บ <b>VEGA98</b> เกมสล็อตออนไลน์จาก PG SLOT ถือเป็นตัวเลือกยอดนิยมสำหรับนักเดิมพัน ด้วยความสนุกและวิธีการเล่นที่ไม่ซับซ้อน อีกทั้งยังมีโอกาสชนะรางวัลใหญ่อย่างง่ายดายแบบไม่จำกัด วันนี้เราขอแนะนำเกมสล็อตที่มีค่า RTP สูง, Payline มากมาย, โบนัสจุใจ และมีระดับความผันผวนตั้งแต่กลางถึงต่ำ เหมาะสำหรับทั้งผู้เริ่มต้นและนักเดิมพันที่มีประสบการณ์</p>

                <img src="/vega98/mahjong-ways.webp" alt="Mahjong Ways" style={{ marginTop: 24 }}/>
                <h2>Mahjong Ways</h2>
                <p>เส้นทางมาจอง ต้นแบบของ Mahjong Ways 2 ที่มี RTP 96.92% และ Payline 1,024 เส้น ฟีเจอร์คอมโบทำให้รางวัลของคุณเพิ่มขึ้นเรื่อย ๆ ความผันผวนปานกลาง ทำให้เกมนี้เป็นอีกหนึ่งทางเลือกที่น่าสนใจ</p>

                <img src="/vega98/mahjong-ways-2.webp" alt="Mahjong Ways 2" style={{ marginTop: 24 }}/>
                <h2>Mahjong Ways 2</h2>
                <p>เส้นทางมาจอง 2 สานต่อความสำเร็จจากเกมแรก Mahjong Ways 2 มาพร้อม RTP 96.95% และ Payline 2,000 เส้น โดดเด่นด้วยฟีเจอร์การคูณรางวัลและฟรีสปิน เพิ่มโอกาสทำกำไรได้ง่าย ความผันผวนอยู่ที่ระดับกลางถึงต่ำ เหมาะสำหรับทุกประเภทผู้เล่น</p>

                <img src="/vega98/treasures-of-aztec.webp" alt="Treasures of Aztec" style={{ marginTop: 24 }}/>
                <h2>Treasures of Aztec</h2>
                <p>สาวถ้ำ เกมสล็อตธีมขุมทรัพย์แอซเทค ที่มี RTP สูงถึง 96.71% พร้อม Payline ถึง 32,400 เส้น ด้วยความผันผวนปานกลาง เกมนี้จึงมีโบนัสที่แตกง่ายและฟีเจอร์ฟรีสปิน ช่วยเพิ่มโอกาสในการทำกำไรอย่างเต็มที่</p>

                <img src="/vega98/ways-of-the-qilin.webp" alt="Ways of the Qilin" style={{ marginTop: 24 }}/>
                <h2>Ways of the Qilin</h2>
                <p>กิเลน เกมสล็อตธีมกิเลนในตำนาน มี RTP สูงถึง 96.69% และ Payline 46,656 เส้น ความผันผวนระดับกลางถึงต่ำและฟีเจอร์โบนัสที่หลากหลาย ช่วยให้โอกาสชนะสูงและการจ่ายเงินที่ต่อเนื่อง</p>

                <img src="/vega98/lucky-neko.webp" alt="Lucky Neko" style={{ marginTop: 24 }}/>
                <h2>Lucky Neko</h2>
                <p>แมวนำโชค ธีมญี่ปุ่นที่น่ารัก เกมนี้มี RTP 96.73% พร้อม Payline 40 เส้น สัญลักษณ์แมวทองเพิ่มโอกาสในการชนะรางวัลใหญ่ ความผันผวนระดับกลางและโบนัสที่แตกบ่อย ทำให้เป็นเกมที่ทำกำไรได้ต่อเนื่อง</p>
            </div>
            <Footer prefixState={prefixState} />
        </HomeLayout>
    )
}

export default WhyUs
