import React, { FC, useState } from 'react'
import { AMBKingGameProviderConfig } from '../../../entities/AMBKing'
import { useHistory } from 'react-router-dom';
import { makePath } from '../../../helpers/path';
import api from '../../../helpers/api'
import { getImgProviderBetflix } from '../../../utils/imgProviderBetFlix'

interface ImageProps {
    src: string;
    alt: string;
    className?: string;
    placeholderSrc: string;
}

const ImageWithPlaceholder: FC<ImageProps> = ({ src, alt, className, placeholderSrc }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(src);

    const handleImageError = () => {
        // ถ้ารูปโหลดไม่ได้ ให้ใช้รูปทดแทน
        setImageSrc(placeholderSrc);
    };

    return (
        <img
            src={imageSrc as string}
            alt={alt}
            className={className}
            onError={handleImageError}
        />
    );
};

interface ProviderListProps {
    config: Record<string, AMBKingGameProviderConfig> | any
    type: string

    setProvider: (name: string) => void
    setGameType: (name: string) => void
}

interface BetflixLoginResponse {
    url: string
}

const ProviderList: FC<ProviderListProps> = ({ config, type, setProvider, setGameType }) => {
    const history = useHistory();

    const initBetflixGame = async (isListGame: boolean, provider: string) => {
        try {
            if (!isListGame) {
                const { url } = await api.post<void, BetflixLoginResponse>(`/betflix/login?provider=${provider}&gamecode=none`)
                history.push(makePath('play?game=' + btoa(url) + '&path=' + btoa(history.location.pathname)))
            }
        } catch ({ message }: any) {
            history.push(makePath('/login'))
        }
    } //eslint-disable-line

    return (
        <>
            {type !== "sport" ?
                <div className="ambking-grid-row-bf">
                    {Object.values(config).map((itemProvider: any, indexProvider: any) => (
                        itemProvider.gameType.includes(type) && (
                            <div
                                key={`${itemProvider.provider}-${indexProvider}-${itemProvider.providerKey}`}
                                className="game-card"
                                onClick={() => {
                                    setProvider(itemProvider.games.length > 0 ? itemProvider.providerKey : "")
                                    initBetflixGame(itemProvider.games.length > 0 ? true : false, itemProvider.provider)
                                }}
                            >
                                {/* <div>{console.log("itemProvider.provider", itemProvider)}</div> */}
                                {/* <div>{itemProvider.providerKey}</div>
                            <div> {type}</div> */}
                                <div className="game-card-img-container">
                                    {itemProvider.providerKey === "pp" && type === "casino" ? (
                                        <ImageWithPlaceholder src={`/provider-gif/provider_bf/pmt.gif`} alt={itemProvider.providerName} className="game-card-logo" placeholderSrc="/image-placeholder.png" />
                                    ) : (
                                        <ImageWithPlaceholder src={`/provider-gif/provider_bf/${getImgProviderBetflix(itemProvider.providerKey)}.gif`} alt={itemProvider.providerName} className="game-card-logo" placeholderSrc="/image-placeholder.png" />
                                    )}
                                    <span className="game-card-name">{itemProvider.providerName}</span>
                                </div>
                            </div>
                        )
                    ))}
                </div>
                :
                Object.values(config).map((itemProvider: any, indexProvider: any) => (
                    itemProvider.gameType.includes(type) && (
                        <div className="ambking-grid-row-sport" key={`${itemProvider.provider}-${indexProvider}-${itemProvider.providerKey}`}>
                            <div
                                className="game-card-sport"
                                onClick={() => {
                                    setProvider(itemProvider.games.length > 0 ? itemProvider.providerKey : "")
                                    initBetflixGame(itemProvider.games.length > 0 ? true : false, itemProvider.provider)
                                }}
                            >
                                <div className="game-card-img-container">
                                    <ImageWithPlaceholder src={`/provider-gif/provider_bf/${getImgProviderBetflix(itemProvider.providerKey)}.gif`} alt={itemProvider.providerName} className="game-card-img" placeholderSrc="/image-placeholder.png" />
                                    {/* <span className="game-card-name">{itemProvider.providerName}</span> */}
                                </div>
                            </div>
                        </div>
                    )
                ))
            }
        </>
    )
}

export default ProviderList
