import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Member } from '../../@types/member'
import { requestUserDone, requestUserFailed, setUser } from './user.actions'

export interface State {
	isLoaded: boolean
	info?: Member
	credit: number
	creditOutStanding: number
	currentTurnover: number
	turnover: number
	showTurnover: boolean
	isPartner: boolean
	cashback: {
		enable: boolean
		available: boolean
		total: number
	}
	affiliate: {
		code: string
		link: string
	}
	myPromotion: {
		key: string,
		name: string,
		description: string,
		image: string,
		delete: boolean
	}
	vizpayWithdrawSetting?: {
		displayFeeMemberStatus?: boolean
	}

	paymentMethods: {
		bank: boolean
		peer2pay: boolean
		truewallet: boolean
	}
}

export const defaultState: State = {
	isLoaded: false,
	info: undefined,
	credit: 0,
	creditOutStanding: 0,
	currentTurnover: 0,
	turnover: 0,
	showTurnover: false,
	isPartner: false,
	cashback: {
		enable: false,
		available: false,
		total: 0,
	},
	affiliate: {
		code: '',
		link: '',
	},
	myPromotion: {
		key: '',
		name: '',
		description: '',
		image: '',
		delete: false
	},
	paymentMethods: {
		bank: false,
		peer2pay: false,
		truewallet: false,
	}
}

export default reducerWithInitialState<State>(defaultState)
	.cases([setUser, requestUserDone], (state, payload) => ({
		...state,
		isLoaded: true,
		info: payload.member,
		credit: payload.credit,
		creditOutStanding: payload.creditOutStanding,
		currentTurnover: payload.currentTurnover,
		isPartner: payload.isPartner,
		cashback: payload.cashback,
		showTurnover: payload.showTurnover || false,
		turnover: payload.turnover,
		affiliate: payload.affiliate,
		myPromotion: payload.myPromotion,
		vizpayWithdrawSetting: payload.vizpayWithdrawSetting,
		paymentMethods: payload.paymentMethods
	}))
	.case(requestUserFailed, (state) => ({
		...state,
		isLoaded: true,
		info: undefined,
	}))
	.default((state) => state)
