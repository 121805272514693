import React, { useEffect } from 'react'
import './../../assets/scss/effects/snowfall.scss';

const Snowfall = () => {
    const flakes: number = 88;

    useEffect(() => {
        const snowContainer: Element | null = document.querySelector('.snow');

        if (snowContainer) {
            for (let i = 0; i < flakes; i++) {
                const flake: HTMLDivElement = document.createElement('div');
                flake.classList.add('flake');
                snowContainer.appendChild(flake);
            }
        }
    }, []);

    return <div className="snow" />
}

export default Snowfall
