import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import TagManager from "react-gtm-module";

const useGaTracker = () => {
    // const TRACKING_ID = 'G-1LXWHZHGZM'
    // const TRACKING_ID = 'GTM-WXVQTH56'
    // const location = useLocation();
    // const [initialized, setInitialized] = useState(false);

    // useEffect(() => {
    //     if (!window.location.href.includes("localhost")) {
    //         ReactGA.initialize(TRACKING_ID);
    //     }
    //     setInitialized(true);
    // }, []);

    // useEffect(() => {
    //     if (initialized) {
    //         ReactGA.pageview(location.pathname + location.search);
    //     }
    // }, [initialized, location]);


    // 	if (TRACKING_ID && history.location.pathname) {
    // 		const tagManagerArgs = {
    // 			gtmId: TRACKING_ID
    // 		}
    // 		TagManager.initialize(tagManagerArgs)
    // 	}

    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const TRACKING_ID = prefixState.info?.googleAnalytic

    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (TRACKING_ID && !window.location.href.includes("localhost")) {
            TagManager.initialize({ gtmId: TRACKING_ID });
            setInitialized(true);
        }
    }, [TRACKING_ID]);

    useEffect(() => {
        if (initialized) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'pageview',
                    page: location.pathname + location.search
                },
            });
        }
    }, [initialized, location]);
};

export default useGaTracker;