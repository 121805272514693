import React, { FC, MouseEvent, useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { AMBKingLoginResponse, AMBKingConfig, AMBKingGameProviderGames } from '../../../entities/AMBKing'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Empty, Input, Space, Typography, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { findCategoryGame } from '../../../utils/subCategoryGame'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../../../helpers/path'
import { format } from '../../../helpers/number'
import { ApplicationState } from '../../../store'
import { useSelector } from 'react-redux'

const { Title } = Typography

interface GameListProps {
	games: AMBKingConfig | any
	provider: string
	gameType: string
	pathName: string
	loadingProvider: boolean

	setProvider: (name: string) => void
	setLoadingProvider: (e: boolean) => void
	setLoading: (e: boolean) => void
}

interface Favorites {
	[key: string]: string;
}

const GameList: FC<GameListProps> = ({
	games, provider, gameType, pathName, loadingProvider,
	setProvider, setLoadingProvider, setLoading
}) => {
	const location = useLocation();
	const ambkProviderTabContainerRef: any = useRef();
	const ambkProviderTabRef: any = useRef();
	const ambkTopbarRef: any = useRef();
	const history = useHistory()
	const [messageApi, contextHolder] = message.useMessage();

	const [configGames, setConfigGames] = useState<AMBKingGameProviderGames | any>(undefined)
	const [providerName, setProviderName] = useState('')
	const [filterText, setFilterText] = useState('')
	const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(['all']);
	const [categoryGame, setCategoryGame] = useState([]);
	const [activeFavorites, setActiveFavorites] = useState<any>({});
	const [useEffectExecuted, setUseEffectExecuted] = useState(false);

	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	const params = new URLSearchParams(location.search);
	const gameFilter = params.get('gameFilter');

	const domain = window.location.origin
	let domainCDN = domain.replace("https://", "https://cdn.");
	
	if(window.location.hostname === 'localhost') {
		domainCDN = "https://cdn.fcn.bet";
	}


	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, AMBKingLoginResponse>('/ambking/login', {
				tab: gameType,
				gameID: gameID,
				isMobile: isMobile(),
				provider: provider,
				redirectURL: btoa(history.location.pathname + history.location.search)
			})

			// window.location.href = 'play?game=' + btoa(res.uri)
			if (gameType === 'casino' || gameType === 'sport' || gameType === 'lotto' || provider === 'JOK' || provider === 'NJK') {
				window.location.href = res.uri
			} else {
				history.push(makePath('play?game=' + btoa(res.uri) + '&path=' + btoa(history.location.pathname + history.location.search)))
			}
			setTimeout(() => {
				setLoading(false)
			}, 2000);
		} catch ({ message }: any) {
			toast.error(message as string)
			setLoading(false)
		}
	}

	// categories game filter
	const handleSubCategoryClick = (type: string) => {
		const params = new URLSearchParams(location.search);

		if (type === 'all') {
			setSelectedSubCategories(['all']);
			params.set('gameFilter', 'all');
			history.push({ search: params.toString() });
		} else {
			const updatedTypes = selectedSubCategories.includes(type)
				? selectedSubCategories.filter((t) => t !== type)
				: [...selectedSubCategories, type];

			// ถ้ามีการเลือกประเภทอื่น ๆ ให้ in-active "ทั้งหมด"
			if (updatedTypes.length > 1 && updatedTypes.includes('all')) {
				const filteredTypes = updatedTypes.filter((t) => t !== 'all');
				setSelectedSubCategories(filteredTypes);
				params.set('gameFilter', filteredTypes.join(', '));
				history.push({ search: params.toString() });
			} else {
				setSelectedSubCategories(updatedTypes);
				params.set('gameFilter', updatedTypes.join(', '));
				history.push({ search: params.toString() });
			}
		}
	};

	const onChangeProvider = (provider: string) => {
		setLoadingProvider(true)
		setProvider(provider)
		setFilterText('')

		setTimeout(() => {
			setLoadingProvider(false)
		}, 500);
	}

	const getFavorite = async () => {
		try {
			const res = await api.get<void, any>('/ambking/favourites');

			setActiveFavorites((prevActiveFavorites: Favorites) => {
				const newFavorites = { ...prevActiveFavorites };

				res.games.forEach((item: any) => {
					newFavorites[item.id] = item.id;
				});

				return newFavorites;
			});
		} catch (error) {
			toast.error(`Error fetching favorites: ${error}`)
			// console.error('Error fetching favorites:', error);
		}
	}

	const handleFavorite = async (gameId: string) => {

		const isGameInFavorites = gameId in activeFavorites;

		setActiveFavorites((prevFavorites: Favorites) => ({
			...prevFavorites,
			[gameId]: gameId,
		}));

		if (isGameInFavorites) {
			const { [gameId]: removedGameId, ...newFavorites } = activeFavorites;
			setActiveFavorites(newFavorites);
		}

		try {
			if (isGameInFavorites) {
				const res = await api.delete<void, any>(`/ambking/favourites/${gameId}`)
				// toast.error(res.message as string)
				messageApi.open({
					type: 'success',
					content: res.message as string,
				});
			} else {
				const res = await api.post<void, any>(`/ambking/favourites/${gameId}`)
				// toast.success(res.message as string)
				messageApi.open({
					type: 'success',
					content: res.message as string,
				});
			}
		} catch (error) {
			// console.error('Error sending API request:', error);
			messageApi.open({
				type: 'error',
				content: `Error sending API request: ${error}`,
			});
		}
	};

	useEffect(() => {
		if (pathName === "launch-game") {
			getFavorite();
		}
	}, []) //eslint-disable-line

	useEffect(() => {
		const carouselHeight = document.querySelector('.carousel-root') as HTMLElement;

		if (carouselHeight) {
			const marginCarouselHeight = window.innerWidth >= 768 ? 32 : 1;
			window.scrollTo({ top: carouselHeight.clientHeight + marginCarouselHeight, behavior: 'smooth' });
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [provider, gameType, selectedSubCategories])

	useEffect(() => {
		if (games && provider && gameType) {
			let gameMain = games.find((game: any) => game.provider === provider)
			if (gameMain === undefined) {
				setProvider("")
				history.push(makePath(history.location.pathname))
			} else {
				setCategoryGame(gameMain.gameType)
				setProviderName(games.find((game: any) => game.provider === provider).providerName)

				let filteredItems = (gameMain.games as any).filter(
					(item: any) => (
						(selectedSubCategories.includes('all') ?
							(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
							:
							(item.gameType && selectedSubCategories.some(category => item.gameType.includes(category))) &&
							(item.gameName && item.gameName.toLowerCase().includes(filterText.toLowerCase()))
						)
					),
				);

				setConfigGames({ ...gameMain, games: filteredItems });
			}
		} else {
			setProvider("")
			history.push(makePath(history.location.pathname))
		}
	}, [games, provider, gameType, filterText, selectedSubCategories]) //eslint-disable-line

	// auto scroll provider tab
	const funcScrollProviderTab = () => {
		const scrollContainer = ambkProviderTabContainerRef?.current;
		const scrollItems = ambkProviderTabRef?.current;

		if (scrollContainer && scrollItems) {
			const scrollItemsActive = scrollItems.querySelector('.amb-games-provider-tab-item.active');

			if (scrollItemsActive) {
				const containerRect = scrollItems.getBoundingClientRect();
				const activeItemRect = scrollItemsActive.getBoundingClientRect();

				const distanceFromTop = activeItemRect.top - containerRect.top;
				scrollContainer.scrollTo({ top: distanceFromTop, behavior: 'smooth' });
			}
		}
	};

	useEffect(() => {
		funcScrollProviderTab();

		if (!useEffectExecuted) {
			const gameFilterSearch = gameFilter?.split(', ')
			if (gameFilterSearch) {
				if (gameFilterSearch.length > 0) {
					setSelectedSubCategories(gameFilterSearch);
				}
			}

			setUseEffectExecuted(true);
		}
	}, [gameFilter, provider, selectedSubCategories, useEffectExecuted]);

	return (
		<>
			{contextHolder}
			<div className="amb-games-container">
				<div className="amb-games-filter">
					<Button
						key={`all`}
						size='small'
						onClick={() => handleSubCategoryClick('all')}
						className={selectedSubCategories.includes('all') ? 'active' : ''}
					>
						{findCategoryGame('all')}
					</Button>
					{categoryGame && categoryGame.length > 0 &&
						categoryGame.map((subCategory) => (
							<Button
								key={`filrer-${subCategory}`}
								size='small'
								onClick={() => handleSubCategoryClick(subCategory)}
								className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
							>
								{findCategoryGame(subCategory)}
							</Button>
						))
					}
				</div>

				<div className="amb-games-content">
					{/* provider tab */}
					{games && games.length > 1 && (
						<div className="amb-games-provider-tab-container" ref={ambkProviderTabContainerRef}>
							<div className="amb-games-provider-tab" ref={ambkProviderTabRef}>
								{games.map((game: any) => (
									<div
										key={`tab-provider-${game.provider}`}
										onClick={() => onChangeProvider(game.provider)}
										className={`amb-games-provider-tab-item ${game.provider === provider ? 'active' : ''}`}
									>
										{/* {game.provider === "POPG" ? (
											<img src="ambking/jackpot.png" alt="jackpot" />
										) : game.provider === "RECG" ? (
											<img src="ambking/hot.png" alt="jackpot" />
										) : ( */}
										{game.logoMobileURL ? (
											// prefixState.info?.prefix === "JEDI" || prefixState.info?.prefix === "9HENG88" ? (
											// 	<img src={game.logoMobileURL.replace(/^https?:\/\/[^\/]+/, domainCDN + "/ambking")} alt={game.providerName} />
											// ) : (
												<img src={game.logoMobileURL} alt={game.providerName} />
											// )
										) : (
											<div className="provider-name-title"><span>{game.providerName}</span></div>
										)}
										{/* )} */}
									</div>
								))}
							</div>
						</div>
					)}

					{/* games */}
					{loadingProvider ? (
						<div className="ambbet-provider-skeleton-loading">
							{
								Array.from({ length: 24 }, (_, index) => (
									<div className="skeleton-body" key={index}>
										<div className="skeleton" />
									</div>
								))
							}
						</div>
					) : (
						<div style={{ flex: 'auto' }}>
							<div>
								<div className="ambking-games-topbar" ref={ambkTopbarRef}>
									<Space size={0}>
										<div
											onClick={() => setProvider('')}
											style={{ display: 'flex', fontSize: 20, padding: '8px 8px 8px 4px', cursor: 'pointer' }}
										>
											<i className="fa-solid fa-arrow-left"></i>
										</div>
										<Title level={5} className="title-provider" onClick={() => setProvider('')} style={{ cursor: 'pointer' }}>{providerName}</Title>
									</Space>
									<div className="search-games">
										<Input
											placeholder={`ค้นหาเกม ใน ${providerName}`}
											onChange={(e: any) => setFilterText(e.target.value)}
											prefix={<SearchOutlined style={{ fontSize: 18 }} />}
											style={{ padding: '8px 12px' }}
										/>
									</div>
								</div>
							</div>
							{(configGames && configGames.games.length > 0 ? (
								<div className="ambking-grid-row">
									{Object(configGames.games).map((game: any, key: number) => (
										<div key={`game_${provider}_${key}_${game.id}`} className={`ambking-grid-col ${game.id}`}>
											{pathName === "launch-game" ? (
												<>
													<Link to="/" href="#amb-king" className="game-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
														<div className="game-card-img-container" />
														{/* window.location.hostname */}
														{game.image.vertical ? (
															// prefixState.info?.prefix === "JEDI" || prefixState.info?.prefix === "9HENG88" ? (
															// 	<img src={game.image.vertical.replace(/^https?:\/\/[^\/]+/, domainCDN + "/ambking")} alt={`${game.id}-${game.gameName}`} className="game-card-img" />
															// ) : (
																<img src={game.image.vertical} alt={`${game.id}-${game.gameName}`} className="game-card-img" />
															// )
														) : (
															<img src="/image-placeholder.png" alt={`${game.id}-${game.gameName}`} className="game-card-img" />
														)}
														<div className="game-card-name">{game.gameName}</div>
														{game.rtp > 0 && (
															<div className="ambking-grid-col-rtp">
																<span>RTP</span> {format(game.rtp, 2)}%
															</div>
														)}
													</Link>
													<div className={`ambking-grid-col-fav ${activeFavorites[game.id] ? 'active' : ''}`} onClick={() => handleFavorite(game.id)}>
														<img src="/icons/3d/favorite.svg" alt="fav icon" />
													</div>
												</>
											) : (pathName === "home" && (
												<>
													<Link to={makePath('/login')} key={game.id} className="game-card">
														<div className="game-card-img-container" />
														{game.image.vertical ? (
															// prefixState.info?.prefix === "JEDI" || prefixState.info?.prefix === "9HENG88" ? (
															// 	<img src={game.image.vertical.replace(/^https?:\/\/[^\/]+/, domainCDN + "/ambking")} alt={`${game.id}-${game.gameName}`} className="game-card-img" />
															// ) : (
																<img src={game.image.vertical} alt={`${game.id}-${game.gameName}`} className="game-card-img" />
															// )
														) : (
															<img src="/image-placeholder.png" alt={`${game.id}-${game.gameName}`} className="game-card-img" />
														)}
														<div className="game-card-name">{game.gameName}</div>
														{game.rtp > 0 && (
															<div className="ambking-grid-col-rtp">
																<span>RTP</span> {format(game.rtp, 2)}%
															</div>
														)}
													</Link>
													<div className={`ambking-grid-col-fav`} onClick={() => history.push(makePath('/login'))}>
														<img src="/icons/3d/favorite.svg" alt="fav icon" />
													</div>
												</>
											))}
										</div>
									))}
								</div>
							)
								:
								<div style={{ display: 'flex', width: '100%' }}>
									<Empty
										description='ไม่พบเกมส์'
										image="/icons/new/empty-box.svg"
										imageStyle={{ height: 88 }}
										style={{ flex: 'auto', marginTop: 24 }}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default GameList
