import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api from '../helpers/api'
import { ApplicationState } from '../store'
import { requestUser } from '../store/user/user.actions'
import { Button, Modal } from 'antd'
import ModalTerms from '../components/Elements/ModalTerms';

interface WheelResponse {
	index: number
	reward: string
}
const LuckyWheel = () => {
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info
	const [openTerms, setOpenTerms] = useState<boolean>(false)
	const handleModalTerms = () => setOpenTerms(!openTerms)

	const wheel = useRef<any>()
	const isMounted = useRef(false)
	const active = useRef(false)
	const [loading, setLoading] = useState(false)
	const [reward, setReward] = useState('')
	const [isRewardModal, setIsRewardModal] = useState(false)

	const triggerLuckyWheel = async () => {
		setLoading(true)

		try {
			const res = await api.post<void, WheelResponse>('/member/wheel')

			const diff = Math.floor(Math.random() * (28 - 6) + 6)
			wheel.current.animation.stopAngle = 36 * res.index - diff
			wheel.current.animation.spins = 8
			wheel.current.stopAnimation(true)
			wheel.current.rotationAngle = 0
			wheel.current.draw()
			wheel.current.startAnimation()

			active.current = true
			setReward(res.reward)
		} catch ({ message }: any) {
			setLoading(false)
			toast.error(message as string)
		}
	}

	const luckyWheelCallback = useCallback(() => {
		if (active.current) {
			active.current = false
			setLoading(false)
			dispatch(requestUser())
			setIsRewardModal(true)
		}
	}, [active, dispatch])

	useEffect(() => {
		const loadWinwheelScript = async () => {
			const TweenMaxScript = document.createElement('script');
			TweenMaxScript.src = '/scripts/TweenMax.min.js';
			TweenMaxScript.async = true;

			TweenMaxScript.onload = () => {
				const WinwheelScript = document.createElement('script');
				WinwheelScript.src = '/scripts/Winwheel.js';
				WinwheelScript.async = true;

				WinwheelScript.onload = () => {
					isMounted.current = true;
					wheel.current = new window.Winwheel({
						numSegments: 10,
						outerRadius: 150,
						drawMode: 'image',
						drawText: true,
						textFontSize: 0,
						responsive: false,
						animation: {
							type: 'spinToStop',
							duration: 5,
							spins: 8,
							callbackFinished: () => {
								luckyWheelCallback();
							},
						},
					});

					const loadedImage = new Image();
					loadedImage.onload = () => {
						wheel.current.wheelImage = loadedImage;
						wheel.current.draw();
					};
					loadedImage.src = prefixState.info?.wheel.resources['reward'] || '';
				};

				document.body.appendChild(WinwheelScript);
			};

			document.body.appendChild(TweenMaxScript);
		};

		loadWinwheelScript();
	}, [prefixState, luckyWheelCallback]);

	if (!prefix || !prefix.wheel.enable) {
		return <div>ไม่สามารถใช้งานกงล้อเสี่ยงโชคได้ในขณะนี้</div>
	}

	return (
		<>
			<div className="lucky-wheel-wrapper">
				<div className="lucky-wheel">
					<div className="lucky-wheel-frame">
						<img
							src={prefix.wheel.resources['background']}
							className="animate-form"
							alt="background"
						/>
						<img
							src={prefix.wheel.resources['background2']}
							className="animate-to"
							alt="background-2"
						/>
					</div>

					<div className="lucky-wheel-wheel">
						<canvas id="canvas" width="516" height="516">
							<p>
								Sorry, your browser doesn't support canvas. Please try
								another.
							</p>
						</canvas>
					</div>

					<div className="lucky-wheel-arrow">
						<img src={prefix.wheel.resources['arrow']} alt="arrow" />
					</div>
					<div className="lucky-wheel-logo">
						<img
							src={prefix.wheel.resources['logoWheel']}
							className="lucky-wheel-logo-bg"
							alt="logo-wheel"
						/>
						{/* <img
						src={prefix.wheel.resources['logo']}
						className="logo-wheel"
						alt="logo"
					/> */}
						{prefix.icon ? (
							<img
								src={prefix.icon}
								className="logo-wheel"
								alt="icon"
							/>
						) : (prefix.logo) && (
							<img
								src={prefix.logo}
								className="logo-wheel"
								alt="logo"
							/>
						)}
					</div>
					<div className="lucky-wheel-play">
						{loading ? (
							<img
								src={prefix.wheel.resources['spinOff']}
								className="lucky-wheel-play-disable"
								alt="play"
							/>
						) : (
							<img
								src={prefix.wheel.resources['spinOn']}
								className="lucky-wheel-play-enable"
								alt="play"
								onClick={triggerLuckyWheel}
							/>
						)}
					</div>
					{prefixState.info?.termandcondition?.wheelDescription && (
						<div className="term-luckwheel">
							<Button className="ant-btn-dark"
								size="small"
								onClick={handleModalTerms}
								icon={<i className="fa-solid fa-circle-info" style={{ fontSize: 16 }}></i>}>
								<span>เงื่อนไขและกติกา</span>
							</Button>
						</div>
					)}

					<Modal
						open={isRewardModal}
						className="ant-modal-deposit"
						title="ยินดีด้วยคุณได้รับ"
						footer={[
							<Button
								type="primary"
								shape="round"
								htmlType="submit"
								onClick={() => setIsRewardModal(false)}
								block
							>
								รับรางวัล
							</Button>
						]}
						onCancel={() => setIsRewardModal(false)}
						centered
					>
						<div className="heading">{reward}</div>
					</Modal>
					<ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} description={prefixState.info?.termandcondition?.wheelDescription || ""} />
				</div>
			</div>
		</>
	)
}

export default LuckyWheel
