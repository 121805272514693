import React, { FC } from 'react'
import { Member } from '../../@types/member'
import { getBankColor, getBankName, Banks } from '../../helpers/bank'
import WithdrawForm from './WithdrawForm'
import { format } from '../../helpers/number'
import { Col, Row, Typography } from 'antd'
import TurnWidget from '../Wallet/TurnWidget'

const { Text } = Typography

interface TruewalletInfoWidgetProps {
	credit: number
	info: Member
	paymentGateway: string
	user: any
}

const TruewalletInfoWidget: FC<TruewalletInfoWidgetProps> = ({ credit, info, paymentGateway, user }) => (
	<>
		<Text type="secondary" style={{ marginBottom: 6, display: 'block' }}>ถอนเงินเข้าบัญชีทรูมันนี่วอลเล็ท</Text>
		<div style={{ marginBottom: 24 }}>
			<div className="bank-card">
				<div
					className="bank-card-logo"
					style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info.bankCode)}` }}
				>
					<img
						src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
						alt={getBankName(info.bankCode)}
					/>
				</div>

				<div className="bank-card-info">
					<div><small>{getBankName(info.bankCode)}</small></div>
					<div className="bank-deposite-account">{info.bankNumber}</div>
					<div><small>{info.firstName} {info.lastName}</small></div>
				</div>
			</div>
		</div>
		<Row gutter={[12, 12]} style={{ marginBottom: 16 }}>
			<Col span={24}>
				<TurnWidget
					totalTurn={user.info?.totalTurn || 0}
					currentTurnover={user.currentTurnover}
					turnover={user.turnover}
					showTurnover={user.showTurnover || false}
				/>
			</Col>
		</Row>
		<WithdrawForm initAmount={credit} paymentGateway={paymentGateway} minWithdraw={info.minWithdraw} credit={format(credit)} />
	</>
)

export default TruewalletInfoWidget
