import { FC } from 'react'
import { ConfigProvider as ThemeProviders, theme, DatePicker } from 'antd'
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import thTH from 'antd/locale/th_TH';

dayjs.locale('th');

const buddhistYear = (date) => {
    if (!date) return date;
    return date.year(date.year() + 543);
};

const gregorianYear = (date) => {
    if (!date) return date;
    return date.year(date.year() - 543);
};

const thTHWithBuddhistYear = {
    ...thTH,
    DatePicker: {
        ...thTH.DatePicker,
        lang: {
            ...thTH.DatePicker.lang,
            format: "YYYY-MM-DD",
            dateFormat: "YYYY-MM-DD",
            dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
            weekFormat: "YYYY-Wo",
            monthFormat: "YYYY-MM",
        },
    },
};

const { darkAlgorithm } = theme

interface Props {
    children: React.ReactNode;
}

const ConfigProvider: FC<Props> = ({ children }) => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const primary = prefixState?.info?.themeColor?.primary || '#005de7'

    const generateDarkerColor = (hexColor: string, factor: number): string => {
        // แปลง hex เป็น RGB
        const rgb = parseInt(hexColor.slice(1), 16);
        let r = (rgb >> 16) & 0xff;
        let g = (rgb >> 8) & 0xff;
        let b = (rgb >> 0) & 0xff;

        // ลดความสว่างของสี
        r = Math.floor(r * factor);
        g = Math.floor(g * factor);
        b = Math.floor(b * factor);

        // รวมกลับเป็น hex
        const newHexColor = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;

        return newHexColor;
    };

    return (
        <ThemeProviders
            locale={thTH}
            theme={{
                // algorithm: darkAlgorithm,
                hashed: false,
                // token: themeSetting
                token: {
                    borderRadius: 10,
                    colorBgBase: '#121416',
                    colorBgContainer: '#0d0f10',
                    colorBorder: '#434343',
                    colorBorderSecondary: '#1b1d1f',
                    colorLink: primary,
                    colorLinkActive: generateDarkerColor(primary, 0.8),
                    colorLinkHover: generateDarkerColor(primary, 0.8),
                    colorPrimary: primary,
                    colorText: 'rgba(255, 255, 255, 0.88)',
                    colorTextQuaternary: 'rgba(255, 255, 255, 0.25)',
                    colorTextSecondary: 'rgba(255, 255, 255, 0.65)',
                    colorTextTertiary: 'rgba(255, 255, 255, 0.45)',
                    colorTextDescription: 'rgba(255, 255, 255, 0.75)',
                    colorTextDisabled: 'rgba(255, 255, 255, 0.25)',
                    colorTextHeading: 'rgba(255, 255, 255, 0.88)',
                    colorTextLabel: 'rgba(255, 255, 255, 0.65)',
                    colorTextPlaceholder: 'rgba(255, 255, 255, 0.25)',
                    controlHeight: 52,
                    fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                    fontSize: 24,
                    lineHeight: 1
                },
                components: {
                    Alert: {
                        algorithm: darkAlgorithm,
                        colorText: 'rgba(255, 255, 255, 0.88)',
                    }
                }
            }}>
            {children}
        </ThemeProviders>
    )
}

export default ConfigProvider