import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import api from '../../helpers/api'

const actionCreator = actionCreatorFactory('@@fcn/provider')

export const setProvider = actionCreator<any>('setProvider')

export const requestProviderDone = actionCreator<any>('requestProviderDone')
export const requestProviderFailed = actionCreator('requestProviderFailed')

export const requestProvider = (game: any) => async (dispatch: ThunkDispatch) => {
	try {
		if (game === 'ambking') {
			const res = await api.get<void, any>('/listgame/ambking')
			return dispatch(requestProviderDone(res))
		} else if (game === 'pgoon') {
			const res = await api.get<void, any>('/pgoon')
			return dispatch(requestProviderDone(res))
		} else if (game === '2goalbet') {
			const res = await api.get<void, any>('/askmebet')
			return dispatch(requestProviderDone(res))
		} else {
			return dispatch(requestProviderFailed())
		}
	} catch (e) {
		console.error(e)
		return dispatch(requestProviderFailed())
	}
}

export const requestProviderInPromotion = (game: any, isGamePromotion: boolean) => async (dispatch: ThunkDispatch) => {
	try {
		if (game === 'ambking' && isGamePromotion) {
			const res = await api.get<void, any>('/ambking/games/bonus')
			return dispatch(requestProviderDone(res))
		} else if (game === 'pgoon') {
			const res = await api.get<void, any>('/pgoon')
			return dispatch(requestProviderDone(res))
		} else if (game === '2goalbet') {
			const res = await api.get<void, any>('/askmebet')
			return dispatch(requestProviderDone(res))
		} else {
			return dispatch(requestProviderFailed())
		}
	} catch (e) {
		console.error(e)
		return dispatch(requestProviderFailed())
	}
}