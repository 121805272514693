import React, { FC, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'
import { ApplicationState } from '../../store'
import { format } from '../../helpers/number'
import { Button, Drawer, Space, Typography, Image, Flex, Tag, theme } from 'antd';
import { RightOutlined, PlusOutlined, PhoneOutlined, LoadingOutlined } from '@ant-design/icons';
import WalletMobileMenu from '../../components/Wallet/WalletMobileMenu'
import ChangePassword from '../../components/Profile/ChangePassword'
import { isMobile } from '../../helpers/utils'
import { Icon } from '@iconify/react';
import AuthService from '../../services/auth'
import {
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { setUser } from '../../store/user/user.actions'
const { useToken } = theme

const { Text } = Typography;

interface Props {
    prefix: any
    member?: Member
    setIsToggleSound?: (e: any) => void
}

const TopbarWidget: FC<Props> = ({ prefix, member, setIsToggleSound }) => {
    const { token } = useToken()
    const history = useHistory()
    const loc = useLocation()
    const pathname = loc.pathname
    const isMobileDevice = isMobile()

    const [changePassword, setChangePassword] = useState<boolean>(false)
    const handleChangePassword = () => setChangePassword(!changePassword)


    const [visibleiOS, setVisibleiOS] = useState<boolean>(false)
    const handleiOS = () => setVisibleiOS(!visibleiOS)
    const [visibleAndroid, setVisibleAndroid] = useState<boolean>(false)
    const handleAndroid = () => setVisibleAndroid(!visibleAndroid)

    const launchGame = () => {
        if (!member?.game.meta) {
            toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
            return
        }

        history.push(makePath('/launch-game'))
    }
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const userState = useSelector((state: ApplicationState) => state.user)
    const firstName = member?.firstName as string
    const lastName = member?.lastName as string
    const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isSound, setIsSound] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
    const [fetching, setFetching] = useState(false)
    const dispatch = useDispatch()

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const onGetLocalSound = () => {
        const getLocalSound = localStorage.getItem('sound')
        if (getLocalSound === 'On') {
            setIsSound(true)
        }
        if (getLocalSound === null) {
            localStorage.setItem('sound', 'On')
            setIsSound(true)
        }
    };

    const handleOutsideClick = (e: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    const refetchCredit = async () => {
        if (fetching) {
            return
        }
        setFetching(true)
        try {
            const res = await AuthService.getMemberInfo(true)
            dispatch(setUser(res))
        } catch ({ message }: any) {
            toast.error(message as string)
        } finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        onGetLocalSound()
    }, [isSound])

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
                if (value === "") {
                    return true;
                }
                return false;
            }) : true;

            setShowMenuSupport(isNullishSocialMedia)
        }
    }, [prefixState])

    return (
        <>
            <div className="topbar-widget">
                {pathname !== '/launch-game' && (
                    <Button
                        className="ant-btn-secondary"
                        onClick={launchGame}
                    >
                        เข้าเล่นเกม <RightOutlined style={{ fontSize: 14 }} rev={undefined} />
                    </Button>
                )}
                {((loc.pathname === '/wallet' || loc.pathname === '/launch-game') || !isMobileDevice) && (
                    <>
                        <div onClick={refetchCredit} className="balance-widget">
                            <div className="balance-widget-body">
                                <img className="balance-widget-icon" src={'/icons/new/baht.svg'} alt="baht" />
                                <Text className="balance-widget-number">
                                    {fetching ? (
                                        <LoadingOutlined style={{ fontSize: 18, marginLeft: 6 }} rev={undefined} />
                                    ) : (format(userState.credit))}
                                </Text>
                            </div>
                            <div className="balance-widget-button" >
                                {/* <PlusOutlined rev={undefined} style={{ color: '#fff', fontSize: 18 }} /> */}
                                <i className="fa-sharp fa-regular fa-arrows-rotate" style={{ fontSize: 16 }}></i>
                            </div>
                        </div>
                    </>
                )}
                <div className="menu-widget" ref={dropdownRef}>
                    <div className="menu-widget-button" onClick={toggleDropdown}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24">
                            <g><g fill="#000" fillRule="evenodd" clipRule="evenodd"><path d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1zM8 12a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1zM6 18a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z" fill="#ffffff" opacity="1" data-original="#000000"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <Drawer className="drawer-profile" placement="right" onClose={toggleDropdown} open={isDropdownOpen} >
                <div className="topbar-member box-content">
                    <div className="topbar-balance">
                        {(prefixState?.info?.functions.rank && prefixState?.info?.ranks) ? (
                            <Link to={makePath('/ranking')}>
                                <div className="topbar-profile-rank">
                                    <img src={`/ranks/${myRank.name === '' ? 'bronze' : myRank.name}.svg`} alt={myRank.name === '' ? 'bronze' : myRank.name} />
                                </div>
                            </Link>
                        ) : (
                            <Link to={makePath('/profile')}>
                                <div className="topbar-profile-rank" style={{ paddingLeft: 4 }}>
                                    <img src="/avatar.png" alt="avatar" />
                                </div>
                            </Link>
                        )}
                        <Link to={makePath('/profile')} className="topbar-balance-number" style={{ marginRight: 0 }}>
                            <Text>{firstName} {lastName}</Text>
                            <div>
                                <Text type="secondary">฿{format(userState.credit)}</Text>
                            </div>
                        </Link>
                    </div>
                </div>
                <div style={{ marginBottom: 16 }}>
                    <WalletMobileMenu
                        lineLink={prefixState.info?.lineLink || ''}
                        checkin={prefixState.info?.checkin.enable || false}
                        wheel={prefixState.info?.wheel.enable || false}
                        commission={prefixState.info?.commission || false}
                        showMenuSupport={showMenuSupport}
                        prefixState={prefixState}
                        ranking={prefixState.info?.functions.rank || false}
                        bonusFreeStatus={prefixState.info?.bonusFreeStatus || false}
                    />
                </div>
                <div className="topbar-install-app box-content" style={{ marginTop: 'auto' }}>
                    <div className="topbar-main">
                        {prefix?.icon && prefix?.icon !== "" ? (
                            <div className="topbar-image">
                                <img src={prefix?.icon} alt={prefix.title} style={{ width: 80 }} />
                            </div>
                        ) : prefix?.logo && prefix?.logo !== "" ?
                            <div className="topbar-image">
                                <img src={prefix?.logo} alt={prefix.title} style={{ width: 80, borderRadius: '20%' }} />
                            </div>
                            : ""}
                        <div className="topbar-balance-number" style={{ marginRight: 0 }}>
                            <Text>เพิ่มไปยังหน้า Home</Text>
                            <div>
                                <Text type="secondary">เข้าเว็บสะดวกง่ายและรวดเร็ว</Text>
                            </div>
                            <Space direction="horizontal" style={{ marginTop: 5 }}>
                                <Button size="small" type="primary" block onClick={handleiOS}>
                                    <Flex align={"center"} justify={"flex-start"}>
                                        <Icon icon="devicon:apple" />
                                        <span style={{ marginLeft: 5 }}>iOS</span>
                                    </Flex>
                                </Button>
                                <Button size="small" type="primary" className="ant-btn-secondary" block onClick={handleAndroid}>
                                    <Flex align={"center"} justify={"flex-start"}>
                                        <Icon icon="devicon:android" />
                                        <span style={{ marginLeft: 5 }}>Android</span>
                                    </Flex>
                                </Button>
                            </Space>
                        </div>
                    </div>
                </div>
                <Button className="ant-btn-primary" style={{ marginTop: 10 }} block onClick={handleChangePassword}>เปลี่ยนรหัสผ่าน</Button>
                <Link to={makePath('/logout')} style={{ marginTop: 10 }}>
                    <Button className="ant-btn-dark ant-btn-widget" block>ออกจากระบบ</Button>
                </Link>
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src="https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-app-ios.png"
                    preview={{
                        visible: visibleiOS,
                        src: 'https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-app-ios.png',
                        onVisibleChange: value => {
                            setVisibleiOS(value);
                        },
                        toolbarRender: (
                            _,
                            {
                                transform: { scale },
                                actions: { onZoomOut, onZoomIn },
                            },
                        ) => (
                            <Space size={12} className="toolbar-wrapper">
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} rev={undefined} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} rev={undefined} />
                            </Space>
                        ),
                    }}
                />
                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src="https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-app-android.png"
                    preview={{
                        visible: visibleAndroid,
                        src: 'https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-app-android.png',
                        onVisibleChange: value => {
                            setVisibleAndroid(value);
                        },
                        toolbarRender: (
                            _,
                            {
                                transform: { scale },
                                actions: { onZoomOut, onZoomIn },// onFlipY, onFlipX, onRotateLeft, onRotateRight, 
                            },
                        ) => (
                            <Space size={12} className="toolbar-wrapper">
                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} rev={undefined} />
                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} rev={undefined} />
                            </Space>
                        ),
                    }}
                />
            </Drawer>

            <ChangePassword isOpen={changePassword} onClose={() => setChangePassword(false)} />
        </>
    )
}

export default TopbarWidget
