import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { format } from '../helpers/number'
import { Link } from 'react-router-dom'
import { makePath } from '../helpers/path'
import { Button, Col, Row, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons';
const { Text } = Typography;

const ProfileMenu = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const userState = useSelector((state: ApplicationState) => state.user)
    const member = userState.info
    const firstName = member?.firstName as string
    const lastName = member?.lastName as string
    const charAvatar = firstName.substring(0, 1)
    const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

    const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)

    useEffect(() => {
        if (prefixState.info) {
            const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
                (Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
                : false;
            setShowMenuSupportLine(isNullishSocialMediaLine)
        }
    }, [prefixState])

    return (
        <Row justify="center">
            <Col xs={24} md={16} xl={12}>
                <Link to={makePath('/profile')}>
                    <div className="account-menu">
                        <div className="topbar-member">
                            <div className="topbar-profile">
                                {prefixState?.info?.functions?.rank ? (
                                    <div className="topbar-profile-rank">
                                        <img src={`/ranks/${myRank.name === '' ? 'bronze' : myRank.name}.svg`} alt={myRank.name === '' ? 'bronze' : myRank.name} />
                                    </div>
                                ) : (
                                    <div className="topbar-avatar">{charAvatar}</div>
                                )}
                                <div className="topbar-info">
                                    <Text className="topbar-name">{firstName} {lastName}</Text>
                                    <div>
                                        <Text type="secondary">฿ {format(userState.credit)}</Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RightOutlined rev={undefined} />
                    </div>
                </Link>

                {showMenuSupportLine && (
                    <>
                        <Text style={{ margin: '32px 0 12px', display: 'block' }}>ฝ่ายบริการลูกค้า</Text>
                        <Row gutter={[16, 16]}>
                            {prefixState.info?.socialMedia['line'] && (
                                <Col span={24}>
                                    <a href={prefixState.info?.socialMedia['line']} target="_blank" rel="noreferrer" className="account-menu">
                                        <div className="account-menu-name">
                                            <svg height="512" viewBox="0 0 36 36" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m18 18c-4.15 0-7.37-4.64-7.37-8.63a7.37 7.37 0 1 1 14.74 0c0 3.99-3.22 8.63-7.37 8.63zm0-13.87a5.24 5.24 0 0 0 -5.24 5.24c0 2.92 2.42 6.5 5.24 6.5s5.24-3.57 5.24-6.5a5.24 5.24 0 0 0 -5.24-5.24z" /><path d="m30.8 34h-25.6a1.07 1.07 0 0 1 -1.07-1.07v-3.2a9.66 9.66 0 0 1 2.81-6.79 9.53 9.53 0 0 1 6.79-2.81 1.07 1.07 0 0 1 .76.31l2.76 2.76a1.1 1.1 0 0 0 1.51 0l2.76-2.76a1.07 1.07 0 0 1 .75-.31 9.53 9.53 0 0 1 6.79 2.81 9.66 9.66 0 0 1 2.81 6.79v3.2a1.07 1.07 0 0 1 -1.07 1.07zm-24.53-2.13h23.46v-2.14a7.47 7.47 0 0 0 -7-7.45l-1.8 1.8-.63.63a3.29 3.29 0 0 1 -4.52 0l-2.48-2.43a7.41 7.41 0 0 0 -4.85 2.18 7.51 7.51 0 0 0 -2.19 5.28z" /></svg>
                                            <span>ติดต่อเรา</span>
                                        </div>
                                        <RightOutlined rev={undefined} />
                                    </a>
                                </Col>
                            )}
                        </Row>
                    </>
                )}

                <Link to={makePath('/logout')} >
                    <Button style={{ marginTop: 24 }} block>ออกจากระบบ</Button>
                </Link>
            </Col>
        </Row>
    )
}

export default ProfileMenu
