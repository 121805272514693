import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Transaction } from '../@types/transaction'
import DepositList from '../components/Transaction/DepositList'
import OtherList from '../components/Transaction/OtherList'
import WithdrawList from '../components/Transaction/WithdrawList'
import api from '../helpers/api'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Radio, RadioChangeEvent, Row } from 'antd'

const Transactions = () => {

	const history = useHistory()
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const [tab, setTab] = useState('deposit')
	// const onChange = (e: RadioChangeEvent) => setTab(e.target.value)

	const onChange = (e: RadioChangeEvent) => {
		setTab(e.target.value)
		const params = new URLSearchParams(location.search);
		params.set('tab', e.target.value);
		history.push({ search: params.toString() });
	}

	const [transactions, setTransactions] = useState<Transaction[]>([])
	const initTransactionsRequest = useCallback(async () => {
		try {
			const res = await api.get<void, Transaction[]>(
				'/member/transactions'
			)

			setTransactions(res)
		} catch ({ message }: any) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		initTransactionsRequest()
	}, [initTransactionsRequest])

	useMemo(() => {
		const paramsTab: any = params.get('tab') ? params.get('tab') : 'deposit';
		if (paramsTab) {
			setTab(paramsTab)
		}
	}, []) //eslint-disable-line

	return (
		<Row justify="center">
			<Col xs={24} md={12}>
				<Radio.Group
					buttonStyle="solid"
					className="transaction-tab"
					value={tab}
					defaultValue={tab}
					onChange={onChange}
					style={{ marginBottom: 16 }}
				>
					<Radio.Button value="deposit">เติมเงิน</Radio.Button>
					<Radio.Button value="withdraw">ถอนเงิน</Radio.Button>
					<Radio.Button value="other">อื่น ๆ</Radio.Button>
				</Radio.Group>

				<div className="tab-transaction-content">
					{tab === 'deposit' && (
						<DepositList
							data={transactions.filter(
								(tx) => tx.type === 'deposit'
							)}
						/>
					)}
					{tab === 'withdraw' && (
						<WithdrawList
							data={transactions.filter(
								(tx) => tx.type === 'withdraw'
							)}
						/>
					)}
					{tab === 'other' && (
						<OtherList
							data={transactions.filter(
								(tx) =>
									tx.type !== 'deposit' && tx.type !== 'withdraw'
							)}
						/>
					)}
				</div>
			</Col>
		</Row>
	)
}

export default Transactions
