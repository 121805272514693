import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Modal, Typography, Skeleton } from 'antd'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';

const { Link } = Typography

// interface PopupProps {
//     isOpen: boolean
//     data: any
//     onClose: () => void
//     setIsDontShow: (e: any) => void
// }

const Popup = () => { //{ isOpen, onClose, data }
    const [dataPopup, setDataPopup] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingImage, setLoadingImage] = useState<boolean>(true)

    const [openPopup, setOpenPopup] = useState(false)
    const [isDontShow, setIsDontShow] = useState<boolean>(false)

    const location = useLocation();
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    const { isOpenRedeemSuccess, setIsModalCheckin } = useAppContext();

    // url popup
    const urlPopupTarget = ''; // "_blank" or "_self"
    const [urlPopup, setUrlPopup] = useState('/deposit')

    const closePopup = () => {
        setOpenPopup(false)
        setIsModalCheckin(true)

        if (isDontShow) {
            const currentTime = new Date();
            currentTime.setHours(currentTime.getHours() + 1);
            const expirationTime = currentTime.getTime();
            localStorage.setItem('popup', "close")
            localStorage.setItem('popupExpiration', expirationTime.toString());
        }

    }


    useEffect(() => {
        if (prefixState.info && (location.pathname === '/launch-game' || location.pathname === '/wallet')) {
            // console.log(isOpenRedeemSuccess);
            if (prefixState.info?.popups.enable && prefixState.info?.popups.items && localStorage.getItem('popup') !== "close" && !isOpenRedeemSuccess) {
                setOpenPopup(true)
                setLoading(false)
                const popupData = prefixState.info.popups.items ? prefixState.info.popups.items.sort((a: any, b: any) => a.order - b.order) : prefixState.info.popups.items
                setDataPopup(popupData)
            }
        }

        const expirationTime = localStorage.getItem('popupExpiration');
        if (expirationTime && (new Date().getTime() > Number(expirationTime))) {
            localStorage.removeItem('popup');
            localStorage.removeItem('popupExpiration');
        }
    }, [prefixState, isOpenRedeemSuccess]) // eslint-disable-line

    const handleImageLoad = () => {
        setLoadingImage(false);
    };

    
    

    return (
        <Modal
            className="ant-popup"
            open={openPopup}
            title=""
            centered
            onCancel={closePopup}
            footer={null}
        >
            {loadingImage && (
                <div className="skeleton-popup-image">
                    {/* <div style={{ color: '#fff' }}>กำลังโหลด</div> */}
                    {/* <LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} rev={undefined} /> */}
                    <Skeleton.Image active={true} style={{ width: '100%' }} />
                </div>
            )}

            <>
                <Carousel
                    autoPlay={true}
                    showStatus={false}
                    showThumbs={false}
                    showArrows={false}
                    interval={2500}
                    infiniteLoop={true}
                    dynamicHeight={true}
                >
                    {dataPopup.map((item: any, idx: any) => {
                        return (
                            // url popup
                            <div key={`popup-${item.key}`}>
                                {urlPopupTarget ? (
                                    <Link href={urlPopup} className="test-slider" key={item.key} target={urlPopupTarget}>
                                        <img src={item.image} onLoad={handleImageLoad} alt="banner" style={{ display: loadingImage ? 'none' : 'block' }} />
                                    </Link>
                                ) : (
                                    <div className="test-slider" key={item.key}>
                                        <img src={item.image} onLoad={handleImageLoad} alt="banner" style={{ display: loadingImage ? 'none' : 'block' }} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </Carousel>
                <div className="ant-pop-checkbox">
                    <Checkbox onChange={() => setIsDontShow(!isDontShow)}>ไม่ต้องแสดงอีก</Checkbox>
                </div>
            </>

        </Modal >
    )
}

export default Popup
