import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import {
	Redirect
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const Logout = () => {
	const history = useHistory()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const layout = prefixState.info?.functions?.homePage //true = home, false = login
	const pathLogin = (layout) ? '/home' : makePath('/login')
	const [showChildren, setShowChildren] = useState(false)
	useEffect(() => {
		AuthService.revokeToken()
		setShowChildren(true)
		// history.push(makePath('/login'))

	}, [history])

	if (!showChildren) {
		return <div>กำลังออกจากระบบ ...</div>
	}

	return <Redirect to={{ pathname: pathLogin }} />
}

export default Logout

