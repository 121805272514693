import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AMBSuperAPIGameConfig, AMBSuperAPILoginResult } from '../../@types/ambsuperapi'
import api from '../../helpers/api'
import { isMobile } from '../../helpers/utils'

type FetchAMBSuperAPIGamesResponse = AMBSuperAPIGameConfig[] | AMBSuperAPILoginResult
const isAMBSuperAPILoginResult = (res: FetchAMBSuperAPIGamesResponse): res is AMBSuperAPILoginResult => {
	return 'url' in res
}

const AMBSuperAPIGame = () => {
	const [games, setGames] = useState<AMBSuperAPIGameConfig[] | null>(null)
	useEffect(() => {
		api.get<void, FetchAMBSuperAPIGamesResponse>('/ambsuperapi/games', {
			params: {
				isMobile: isMobile(),
			},
		}).then((res) => {
			if (isAMBSuperAPILoginResult(res)) {
				window.location.href = res.url
			} else {
				setGames(res)
			}
		})
	}, [])

	const forwardToGame = async (gameCode: string) => {
		try {
			const res = await api.post<void, AMBSuperAPILoginResult>('/ambsuperapi/login', {
				gameCode: gameCode,
				isMobile: isMobile(),
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	if (!games) {
		return null
	}
	return (
		<div className="wrapper">
			<div className="title-bar title-bar--sub-title">
				<h2>สล็อตออนไลน์</h2>
			</div>

			<div className="slot-wrapper">
				{games.map((game) => (
					<a
						href="#ambsuperapi-game"
						className="slot-card"
						onClick={() => forwardToGame(game.code)}
						key={game.code}
					>
						<img src={game.img} alt={game.name} className="slot-card__img" />
						<span>{game.name}</span>
					</a>
				))}
			</div>
		</div>
	)
}

export default AMBSuperAPIGame
