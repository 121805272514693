import React, { useEffect, useMemo, useState } from 'react'
import { DatePicker, Empty, Table, TimePicker, Typography, Button, Spin } from 'antd'
import StatisticCard from '../components/Partner/StatisticCard'
import { format } from '../helpers/number'
import api from '../helpers/api'
import { toast } from 'react-toastify'
import DateFilterTabs from '../components/Partner/DateFilterTabs';
import dayjs from 'dayjs';

const { Title } = Typography;

const Partner = () => {
	const [dataPartnerSummary, setDataPartnerSummary] = useState<any>();
	const [dataTop10, setDataTop10] = useState<any>();
	const [dataDeposit, setDataDeposit] = useState<any>();
	const [dataWithdraw, setDataWithdraw] = useState<any>();
	const [isFetching, setIsFetching] = useState(false);
	const [isSummaryFetching, setIsSummaryFetching] = useState(false);

	const useDatePaginationDeposit = () => {
		const [paginationDeposit, setPaginationDeposit] = useState<any>({ page: 1, pageSize: 5 })

		return {
			paginationDeposit,
			setPaginationDeposit,
		}
	}

	const useDatePaginationWithdraw = () => {
		const [paginationWithdraw, setPaginationWithdraw] = useState<any>({ page: 1, pageSize: 5 })

		return {
			paginationWithdraw,
			setPaginationWithdraw,
		}
	}

	const { paginationDeposit, setPaginationDeposit } = useDatePaginationDeposit()
	const { paginationWithdraw, setPaginationWithdraw } = useDatePaginationWithdraw()

	const columns = [
		{
			title: 'วันที่',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (text: any, record: any) => {
				return (
					<div style={{ whiteSpace: 'nowrap' }}>
						<div>{dayjs(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
					</div>
				)
			}
		},
		{
			title: 'สมาชิก',
			dataIndex: 'member',
			key: 'member',
			render: (text: any, record: any) => {
				return (
					<div style={{ whiteSpace: 'nowrap', lineHeight: 1 }}>
						<div>{record.phone}</div>
						<div style={{ fontSize: 20, color: 'rgba(255, 255, 255, 0.8)' }}>{record.firstName} {record.lastName}</div>
					</div>
				)
			}
		},
		{
			title: 'ยอดเงิน',
			dataIndex: 'amount',
			key: 'amount',
			width: '200px',
			render: (text: any, record: any) => {
				return (
					<div style={{ color: '#24d14f' }}>
						<span style={{ marginRight: 4 }}>฿</span>
						<span>{format(record.amount)}</span>
					</div>
				)
			}
		},
	];

	const [dateRange, setDateRange] = useState({
		start: dayjs().format('YYYY-MM-DD'),
		end: dayjs().format('YYYY-MM-DD'),
		sTime: '',
		eTime: '',
	})

	const [dateExRange, setDateExRange] = useState({
		start: '',
		end: '',
		sTime: '',
		eTime: '',
	})

	const submitDate = () => {
		setDateRange(dateExRange);
	};

	const handleStartDateChange = (type: string, date: any) => {
		if (type === 'start') {
			setDateExRange((prev) => ({
				...prev,
				start: dayjs(date).format('YYYY-MM-DD')
			}));
		} else if (type === 'end') {
			setDateExRange((prev) => ({
				...prev,
				end: dayjs(date).format('YYYY-MM-DD')
			}));
		} else if (type === 'sTime') {
			setDateExRange((prev) => ({
				...prev,
				sTime: dayjs(date).format('HH:mm')
			}));
		} else if (type === 'eTime') {
			setDateExRange((prev) => ({
				...prev,
				eTime: dayjs(date).format('HH:mm')
			}));
		}
	};

	const fetchSummaryData = async () => {
		if (isSummaryFetching) return; // ถ้ากำลัง fetch อยู่ ให้ไม่ทำอะไร

		setIsSummaryFetching(true); // ตั้งค่าเป็นกำลัง fetch
		try {
			const startDate = dateRange.start && dateRange.sTime ? `${dateRange.start} ${dateRange.sTime}:00` : dateRange.start;
			const endDate = dateRange.end && dateRange.eTime ? `${dateRange.end} ${dateRange.eTime}:59` : dateRange.end;

            const results = await Promise.allSettled([
                api.get('/partners/summary', { params: { start: startDate, end: endDate } }),
            ]);

			// จัดการผลลัพธ์ที่ได้
			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					if (index === 0) setDataPartnerSummary(result.value);
				} else {
					toast.error(result.reason.message);
				}
			});

			setIsSummaryFetching(false); // ตั้งค่าเป็นกำลัง fetch
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setIsSummaryFetching(false); // ตั้งค่าเป็นไม่กำลัง fetch
		}
	};

	const fetchData = async () => {
		if (isFetching) return; // ถ้ากำลัง fetch อยู่ ให้ไม่ทำอะไร

		setIsFetching(true); // ตั้งค่าเป็นกำลัง fetch
		try {
			const startDate = dateRange.start && dateRange.sTime ? `${dateRange.start} ${dateRange.sTime}:00` : dateRange.start;
			const endDate = dateRange.end && dateRange.eTime ? `${dateRange.end} ${dateRange.eTime}:59` : dateRange.end;

            const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

            const results = await Promise.allSettled([
                // Delayed API calls
                delay(3000).then(() => api.get('/partners/top10', { params: { start: startDate, end: endDate } })),
                delay(3000).then(() => api.get('/partners/deposit', { params: { start: startDate, end: endDate, limit: paginationDeposit.pageSize, page: paginationDeposit.page } })),
                delay(3000).then(() => api.get('/partners/withdraw', { params: { start: startDate, end: endDate, limit: paginationWithdraw.pageSize, page: paginationWithdraw.page } }))
            ]);

			// จัดการผลลัพธ์ที่ได้
			results.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					if (index === 0) setDataTop10(result.value);
					else if (index === 1) setDataDeposit(result.value);
					else if (index === 2) setDataWithdraw(result.value);
				} else {
					toast.error(result.reason.message);
				}
			});

			setIsFetching(false); // ตั้งค่าเป็นกำลัง fetch
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setIsFetching(false); // ตั้งค่าเป็นไม่กำลัง fetch
		}
	};

	useEffect(() => {
		fetchSummaryData();
		fetchData();
	}, [dateRange, paginationDeposit, paginationWithdraw]); // eslint-disable-line

	return (
		<div style={{ marginTop: 16 }}>
			<div className='transaction-card'>
				<div className='transaction-body' style={{ display: 'block' }}>

					{/* Filter */}
					<div className='filter-container'>
						<div className='range-picker-container'>
							<div className='range-picker'>
								<div className='range-picker-label'>เริ่ม</div>
								<div className='range-picker-data'>
									<DatePicker onChange={(e: any) => { handleStartDateChange('start', e) }} format='DD/MM/YYYY' disabled={isFetching} />
								</div>
								<div className='range-picker-time'>
									<TimePicker onChange={(e: any) => { handleStartDateChange('sTime', e) }} format='HH:mm' disabled={isFetching} />
								</div>
							</div>
							<div className='range-picker'>
								<div className='range-picker-label'>ถึง</div>
								<div className='range-picker-data'>
									<DatePicker onChange={(e: any) => { handleStartDateChange('end', e) }} format='DD/MM/YYYY' disabled={isFetching} />
								</div>
								<div className='range-picker-time'>
									<TimePicker onChange={(e: any) => { handleStartDateChange('eTime', e) }} format='HH:mm' disabled={isFetching} />
								</div>
							</div>
						</div>
						<Button className='ant-btn-secondary' onClick={() => { submitDate() }} disabled={isFetching}>ค้นหา</Button>
						<DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} isFetching={isFetching} />
					</div>

					{/* Summary */}
					<div className='summary'>
						<StatisticCard title='ยอดฝาก' amount={dataPartnerSummary?.depositTotal} item={0} prefix='฿' className='green' isFetching={isSummaryFetching} />
						<StatisticCard title='ยอดถอน' amount={dataPartnerSummary?.withdrawTotal} item={0} prefix='฿' className='red' isFetching={isSummaryFetching} />
						<StatisticCard title='กำไร' amount={dataPartnerSummary?.profitTotal} prefix='฿' className='blue' isFetching={isSummaryFetching} />
						<StatisticCard title='ยอดโบนัสอื่นๆ' amount={dataPartnerSummary?.bonusOtherTotal} prefix='฿' className='geekblue' isFetching={isSummaryFetching} />
						<StatisticCard title='ยอดโปรโมชั่น' amount={dataPartnerSummary?.promotionTotal} prefix='฿' className='orange' isFetching={isSummaryFetching} />
						<StatisticCard title='แคชแบ็ก' amount={dataPartnerSummary?.cashbackTotal} prefix='฿' className='cyan' isFetching={isSummaryFetching} />
						<StatisticCard title='ยูสทั้งหมด' amount={dataPartnerSummary?.allMemberTotal} suffix='คน' className='volcano' isFetching={isSummaryFetching} />
						<StatisticCard title='ยูสฝากแรกทั้งหมด' amount={dataPartnerSummary?.memberHasFirstDepositAllTotal} suffix='คน' className='teal' isFetching={isSummaryFetching} />
						<StatisticCard title='เปิดยูสใหม่' amount={dataPartnerSummary?.registerNewTotal} suffix='คน' className='navy' isFetching={isSummaryFetching} />
						<StatisticCard title='ยูสฝากแรก' amount={dataPartnerSummary?.memberFirstDepositTotal} suffix='คน' className='magenta' isFetching={isSummaryFetching} />
						<StatisticCard title='ยูสที่กลับมาฝาก' amount={dataPartnerSummary?.memberComebackDepositTotal} suffix='คน' className='purple' isFetching={isSummaryFetching} />
						<StatisticCard title='ยูสที่ฝาก' amount={dataPartnerSummary?.memberDepositTotal} suffix='คน' className='brown' isFetching={isSummaryFetching} />
					</div>

					{/* Top 10 */}
					<div className='list-card-container'>
						{/* ยอดฝากสูงสุด */}
						<div className='list-card'>
							<div className='list-card-head'>ยอดฝาก 10 อันดับ</div>
							<div className='list-card-body'>
								{!isFetching ? (
									<>
										{dataTop10 && dataTop10?.depositTxTop10 ? dataTop10?.depositTxTop10.map((item: any, index: number) => (
											<div className='list-card-item' key={index}>
												<div>
													<div className='list-card-item-user'>{item.phone}</div>
													<div className='list-card-item-bank'>{item.firstName} {item.lastName}</div>
												</div>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<div className='list-card-item-amount'>
														<span style={{ marginRight: 4 }}>฿</span>
														<span><span style={{ fontSize: 24, fontWeight: 'bold' }}>{format(item.amount)}</span></span>
													</div>
												</div>
											</div>
										)) : (
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)}
									</>
								) : (
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
										<Spin />
									</div>
								)}
							</div>
						</div>

						{/* ยอดถอนสูงสุด */}
						<div className='list-card'>
							<div className='list-card-head'>ยอดถอน 10 อันดับ</div>
							<div className='list-card-body'>
								{!isFetching ? (
									<>
										{dataTop10 && dataTop10?.withdrawTxTop10 ? dataTop10?.withdrawTxTop10.map((item: any, index: number) => (
											<div className='list-card-item' key={index}>
												<div>
													<div className='list-card-item-user'>{item.phone}</div>
													<div className='list-card-item-bank'>{item.firstName} {item.lastName}</div>
												</div>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<div className='list-card-item-amount'>
														<span style={{ marginRight: 4 }}>฿</span>
														<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(item.amount)}</span></span>
													</div>
												</div>
											</div>
										)) : (
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)}
									</>
								) : (
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
										<Spin />
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Table */}
					<div>
						{/* ฝาก */}
						<div className='table-card' style={{ marginBottom: 24 }}>
							<Title level={4} style={{ marginTop: 0 }}>รายการฝาก</Title>
							<Table
								dataSource={dataDeposit?.data}
								columns={columns}
								scroll={{ x: true }}
								loading={isFetching}
								pagination={{
									defaultPageSize: 5,
									pageSizeOptions: ['5', '10', '20'],
									total: dataDeposit?.pagination.Count || 0,
									current: paginationDeposit.page || 1,
									onChange: (page: number, pageSize: number) => {
										setPaginationDeposit({ page: page, pageSize: pageSize })
									}
								}}
							/>
						</div>

						{/* ถอน */}
						<div className='table-card'>
							<Title level={4} style={{ marginTop: 0 }}>รายการถอน</Title>
							<Table
								dataSource={dataWithdraw?.data}
								columns={columns}
								scroll={{ x: true }}
								loading={isFetching}
								pagination={{
									defaultPageSize: 5,
									pageSizeOptions: ['5', '10', '20'],
									total: dataWithdraw?.pagination.Count || 0,
									current: paginationWithdraw.page || 1,
									onChange: (page: number, pageSize: number) => {
										setPaginationWithdraw({ page: page, pageSize: pageSize })
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Partner
