export const makePath = (path: string, params: string[] = []) => {
	let suffixQuery = ''
	if (params.length > 0) {
		// suffixQuery += '&'
		suffixQuery += '?'
		suffixQuery += params.join('&')
	}
	// return path + '?prefix=' + localStorage.getItem('x-prefix') + suffixQuery
	return path + suffixQuery
}
