import React, { FC, useState, useEffect } from 'react';
import { Badge } from 'antd'

interface DepositMethodButtonProps {
    icon: string
    textRibbon?: string
    title: string
    subTitle?: string
    subTitleSmall?: string
    secondary?: boolean
}

const DepositMethodButton: FC<DepositMethodButtonProps> = ({
    icon,
    textRibbon,
    title,
    subTitle,
    subTitleSmall,
    secondary,
}) => {
    // State to store the random animation duration
    const [animationDuration, setAnimationDuration] = useState<number>(0);

    // Function to generate a random duration
    const generateRandomDuration = () => {
        const minDuration = 2000; // Minimum duration in milliseconds
        const maxDuration = 8000; // Maximum duration in milliseconds
        const randomDuration = Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;
        return randomDuration;
    };

    // Set the random duration when the component mounts
    useEffect(() => {
        const randomDuration = generateRandomDuration();
        setAnimationDuration(randomDuration);
    }, []);

    return (
        <div className={`deposit-method-wrapper ${textRibbon ? '' : 'hidden-ribbon'}`}>
            <Badge.Ribbon text={textRibbon} className="ant-ribbon-secondary">
                <div className={'deposit-method' + (secondary ? ' bg-secondary' : '')}>
                    <div className="deposit-method-icon">
                        <img src={icon} alt={title} style={{ animation: `dance ${animationDuration}ms linear infinite` }} />
                    </div>
                    <div className="deposit-method-name">
                        <span>{title}
                            {subTitleSmall && (<small style={{ marginLeft: 2 }}>{subTitleSmall}</small>)}
                        </span>

                        {subTitle && (
                            <>
                                <br />
                                <small>{subTitle}</small>
                            </>
                        )}
                    </div>
                    <div className="deposit-method-icon-right">
                        <i className="fa-solid fa-angle-right"></i>
                    </div>
                </div>
            </Badge.Ribbon>
        </div>
    )
}

export default DepositMethodButton
