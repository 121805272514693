import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { generateQRCodeURL } from '../../helpers/qrCode'

interface Props {
    peer2payData: any
    amount: string
}

const Peer2PayQrCodeCapture: FC<Props> = ({ peer2payData, amount }) => {
    const [loading, setLoading] = useState(false);
    const [dataUrl, setDataUrl] = useState<string | null>(null);

    useEffect(() => {
        const canvas = document.createElement('canvas');
        canvas.width = 320;
        canvas.height = 416;

        const ctx = canvas.getContext('2d');

        if (ctx) {
            const qrCode = new Image();
            const p2pLogo = new Image();
            qrCode.crossOrigin = "anonymous"

            // image
            qrCode.onload = () => {
                ctx.drawImage(qrCode, (canvas.width - 232) / 2, 64, 232, 232);
                ctx.drawImage(p2pLogo, 38, 292, 84, 84);

                p2pLogo.onload = () => {
                    ctx.drawImage(p2pLogo, (canvas.width - 120) / 2, 16, 120, 43);

                    const qrCodeDataURL = canvas.toDataURL();
                    setDataUrl(qrCodeDataURL);
                    setLoading(false);
                };

                p2pLogo.src = "/p2p/p2ptop.png";
            };
            qrCode.src = generateQRCodeURL(peer2payData.deeplink);

            // body
            ctx.fillStyle = '#ffffff';
            ctx.beginPath();
            ctx.roundRect(0, 0, canvas.width, canvas.height, [12]);
            ctx.fill();

            // body bottom
            ctx.fillStyle = '#172b68';
            ctx.beginPath();
            ctx.roundRect(0, (canvas.height - 92), canvas.width, 92, [0, 0, 12, 12]);
            ctx.fill();

            // ร้านค้า
            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'left';
            ctx.fillText('ร้านค้า', 16, (canvas.height - 56));

            // จำนวน
            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'left';
            ctx.fillText('จำนวน', 16, (canvas.height - 24));

            // ชื่อร้าน
            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'right';
            ctx.fillText(peer2payData?.merchantName, (canvas.width - 16), (canvas.height - 56));

            // ยอดเงิน (ตัวเลข)
            ctx.font = '24px DB HelvethaicaX';
            ctx.fillStyle = '#52c41a';
            ctx.textAlign = 'right';
            ctx.fillText(amount + ' THBP', (canvas.width - 16), (canvas.height - 24));
        }
    }, [amount, peer2payData]);

    const downloadImage = () => {
        if (dataUrl) {
            setLoading(true);

            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `qrcode_${Date.now()}.png`;
            link.click();

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {dataUrl && <img src={dataUrl} alt="qr code" style={{ display: 'none' }} />}
            <Button
                size="small"
                className="ant-btn-dark custom-icon"
                icon={<i className="fa-solid fa-arrow-down-to-bracket" style={{ fontSize: 16 }}></i>}
                onClick={downloadImage}
                loading={loading}
                style={{ fontSize: 20, height: 36, padding: '6.5px 12px' }}>
                {loading ? 'กำลังโหลด...' : 'ดาวน์โหลดรูป'}
            </Button>
        </div>
    );
};

export default Peer2PayQrCodeCapture