import React, { useState, useEffect, MouseEvent } from "react";
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Avatar, Button, Col, Flex, Row, Space, Typography } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import EffectsContainer from "../components/Effects/EffectsContainer";
import SetTheme from "../utils/SetTheme";
import store from '../store'
import api from '../helpers/api'
import { toast } from "react-toastify";
import { Redirect, useHistory } from 'react-router-dom'
import { makePath } from "../helpers/path";

const { Link, Text, Title } = Typography

const Seller = () => {
    // const storedMemberDataString: any = localStorage.getItem('move_web');


    // const storedMemberData = JSON.parse(storedMemberDataString);
    const [loading, setLoading] = useState<boolean>(false);
    const [storedMemberData, setStoredMemberData] = useState<string>("");

    const moveToWeb = async () => {
        setLoading(true);
        try {
            const res: any = await api.post<void, any>(`/member/move`, {
                prefixDestination: "AMBGAME"
            });

            if (res.message === "ดำเนินการย้ายสมาชิกสำเร็จ") {
                toast.success('ย้ายไปเว็บใหม่เรียบร้อย');
                window.location.href = "https://ambgame.me"; // ใช้ href แทน
            } else {
                toast.error('การย้ายสมาชิกไม่สำเร็จ');
                window.location.href = "https://ambgame.me"; // ใช้ href แทน
            }
        } catch ({ message }: any) {
            toast.error(message as string)
            window.location.href = "https://ambgame.me"; // ใช้ href แทน
        }

        setLoading(false);
    }

    const getMember = async () => {
        const res: any = await api.get<void, any>('/member')
        if (!res.move) {
            window.location.href = '/wallet'
        }
        setStoredMemberData(res.message);
    }

    useEffect(() => {
        getMember()
    }, []);

    return (
        <>
            <EffectsContainer />
            <Row
                justify="center"
                align="middle"
                style={{ height: '100%' }}
                className='hero'
            >
                <SetTheme />
                <div className="auth-container-seller">
                    {storedMemberData && (
                        <div className="wrapper">
                            <div className="form-card-seller">
                                <div className="form-card-seller-body">
                                    <div className="contact-text" style={{ marginBottom: 20, textAlign: 'center' }}>
                                        <Text>{storedMemberData}</Text>
                                    </div>
                                    <Flex justify="center" align="align">
                                        <Button type={"primary"} loading={loading} disabled={loading} onClick={moveToWeb}>คลิกเพื่อย้ายไปเว็บใหม่</Button>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Row >
        </>
    );
};

export default Seller;
