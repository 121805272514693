import React, { FC, MouseEvent, useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { AMBKingLoginResponse, AMBKingConfig, AMBKingGameProviderGames } from '../../../entities/AMBKing'
import { IPJJAZZLoginResponse, iPJJAZZListGame, BFProviderGames } from '../../../entities/PJJAZZType'
import api from '../../../helpers/api'
import { Button, Empty, Input, Space, Typography, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { findCategoryGame, typeCasino, typeFish, typeSlot } from '../../../utils/subCategoryGame'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makePath } from '../../../helpers/path'
import { Games } from '../../../helpers/games'
import ImageWithFallback from '../../Elements/ImageWithFallback'

const { Title } = Typography

interface GameListProps {
	games: AMBKingConfig | any
	allprovider: any
	provider: string
	gameType: string
	pathName: string
	loadingProvider: boolean

	setProvider: (name: string) => void
	setLoadingProvider: (e: boolean) => void
	setLoading: (e: boolean) => void
}

interface BetFlixLoginResponse {
	url: string

	setProvider: (name: string) => void
	setLoadingProvider: (e: boolean) => void
	setLoading: (e: boolean) => void
}

const GameList: FC<GameListProps> = ({
	games, allprovider, provider, gameType, pathName, loadingProvider,
	setProvider, setLoadingProvider, setLoading
}) => {
	const location = useLocation();
	const ambkProviderTabContainerRef: any = useRef();
	const ambkProviderTabRef: any = useRef();
	const ambkTopbarRef: any = useRef();
	const history = useHistory()
	const [messageApi, contextHolder] = message.useMessage(); // eslint-disable-line

	const [configGames, setConfigGames] = useState<BFProviderGames | any>(undefined)
	const [providerName, setProviderName] = useState('') // eslint-disable-line
	const [filterText, setFilterText] = useState('')
	const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(['all']);
	const [categoryGame, setCategoryGame] = useState([]);
	const [useEffectExecuted, setUseEffectExecuted] = useState(false);

	const params = new URLSearchParams(location.search);
	const gameFilter = params.get('gameFilter');

	const getGameCover = (gameCode: number) => {
		const gameCover = Games.filter((game) => game.gameCode === +gameCode)[0]
		return gameCover ? gameCover.image.vertical : '';
	}

	const triggerSlotLogin = (provider: string, gameID: string, providerOther: boolean) => async (e: MouseEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		if (providerOther) {
			try {
				// ----- เวลา login เข้าเล่นเกม pjjazz ใช้เส้นนี้
				const res = await api.post<void, BetFlixLoginResponse>(`/betflix/login?provider=${provider}&gamecode=${gameID}&providerOther=${providerOther}`)
				history.push(makePath('play?game=' + btoa(res.url) + '&path=' + btoa(history.location.pathname)))
				setTimeout(() => {
					setLoading(false)
				}, 2000);
			} catch ({ message }: any) {
				toast.error(message as string)
				setLoading(false)
			}
		} else {
			try {
				// ----- เวลา login เข้าเล่นเกม betflix ใช้เส้นนี้
				const res = await api.post<void, BetFlixLoginResponse>(`/betflix/login?provider=${provider}&gamecode=${gameID}`)
				history.push(makePath('play?game=' + btoa(res.url) + '&path=' + btoa(history.location.pathname)))
				setTimeout(() => {
					setLoading(false)
				}, 2000);
			} catch ({ message }: any) {
				toast.error(message as string)
				setLoading(false)
			}
		}
	}
	const triggerLogin = async (provider: string, gameID: string, listgame?: boolean) => {
		if (listgame) {
			setLoading(true)
			try {
				const res = await api.post<void, BetFlixLoginResponse>(`/betflix/login?provider=` + provider + `&gamecode=` + gameID)

				history.push(makePath('play?game=' + btoa(res.url) + '&path=' + btoa(history.location.pathname)))
				setTimeout(() => {
					setLoading(false)
				}, 2000);
			} catch ({ message }: any) {
				toast.error(message as string)
				setLoading(false)
			}
		}
	}

	// categories game filter
	const handleSubCategoryClick = (type: string) => {
		if (type === 'all') {
			setSelectedSubCategories(['all']);
		} else {
			const updatedTypes = selectedSubCategories.includes(type)
				? selectedSubCategories.filter((t) => t !== type)
				: [...selectedSubCategories, type];

			// ถ้ามีการเลือกประเภทอื่น ๆ ให้ in-active "ทั้งหมด"
			if (updatedTypes.length > 1 && updatedTypes.includes('all')) {
				const filteredTypes = updatedTypes.filter((t) => t !== 'all');
				setSelectedSubCategories(filteredTypes);
			} else {
				setSelectedSubCategories(updatedTypes);
			}
		}
	};

	const onChangeProvider = (provider: string) => {
		setLoadingProvider(true)
		setProvider(provider)
		setFilterText('')
		setTimeout(() => {
			setLoadingProvider(false)
		}, 500);
	}

	useEffect(() => {
		const carouselHeight = document.querySelector('.carousel-root') as HTMLElement;

		if (carouselHeight) {
			const marginCarouselHeight = window.innerWidth >= 768 ? 32 : 1;
			window.scrollTo({ top: carouselHeight.clientHeight + marginCarouselHeight, behavior: 'smooth' });
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [provider, gameType, selectedSubCategories])



	useEffect(() => {
		if (games && provider && gameType) {
			if (games === undefined) {
				setProvider("")
				history.push(makePath(history.location.pathname))
			} else {
				const typesGame: any = Array.from(new Set(games.games.map((item: any) => item.type)));
				setCategoryGame(typesGame)
				setProviderName(games.providerName)

				let filteredItems = games.games.filter((item: any) => {
					// กรองตามประเภทเกม
					let isSlotType;
					// if (provider === 'pp') {
					// 	if (gameType === "slot") {
					// 		isSlotType = item.type === "Video Slots" || item.type === "Classic Slots";
					// 	} else if (gameType === "casino") {
					// 		isSlotType = item.type !== "Video Slots" && item.type !== "Classic Slots";
					// 	}
					// } else if (provider === 'jl') {
					// 	if (gameType === "slot") {
					// 		isSlotType = item.type === "SLOT" || item.type === "TABLE";
					// 	} else if (gameType === "fish") {
					// 		isSlotType = item.type === "FH";
					// 	} else if (gameType === "casino") {
					// 		isSlotType = item.type === "TABLE";
					// 	}
					// } else if (provider === 'joker') {
					// 	if (gameType === "slot") {
					// 		isSlotType = item.type === "Slot" || item.type === "Bingo";
					// 	} else if (gameType === "fish") {
					// 		isSlotType = item.type === "Fishing";
					// 	} else if (gameType === "casino") {
					// 		isSlotType = item.type === "ECasino";
					// 	}
					// } else if (provider === 'sn') {
					// 	if (gameType === "slot") {
					// 		isSlotType = item.type === "slots" || item.type === "crash" || item.type === "arcade" || item.type === "arcade_bingo";
					// 	} else if (gameType === "fish") {
					// 		isSlotType = item.type === "fishing";
					// 	} else if (gameType === "casino") {
					// 		isSlotType = item.type === "tables";
					// 	}
					// } else {
					// 	isSlotType = item.type
					// }

					if (gameType === "slot") {
						isSlotType = typeSlot.includes(item.type.toUpperCase())
					} else if (gameType === "fish") {
						isSlotType = typeFish.includes(item.type.toUpperCase())
					} else if (gameType === "casino") {
						isSlotType = typeCasino.includes(item.type.toUpperCase())
					} else {
						isSlotType = item.type
					}

					// กรองตามข้อความค้นหา
					const matchesFilterText = item.name && item.name.toLowerCase().includes(filterText.toLowerCase());

					// กรองตามหมวดหมู่ย่อย
					const matchesSubCategories = selectedSubCategories.includes('all') ||
						(item.type && selectedSubCategories.some(category => item.type.includes(category)));

					return isSlotType && matchesFilterText && matchesSubCategories;
				});

				setConfigGames({ games: filteredItems });
			}
		} else {
			setProvider("")
			history.push(makePath(history.location.pathname))
		}
	}, [games, provider, gameType, filterText, selectedSubCategories]) //eslint-disable-line

	// auto scroll provider tab
	const funcScrollProviderTab = () => {
		const scrollContainer = ambkProviderTabContainerRef?.current;
		const scrollItems = ambkProviderTabRef?.current;

		if (scrollContainer && scrollItems) {
			const scrollItemsActive = scrollItems.querySelector('.amb-games-provider-tab-item.active');

			if (scrollItemsActive) {
				const containerRect = scrollItems.getBoundingClientRect();
				const activeItemRect = scrollItemsActive.getBoundingClientRect();

				const distanceFromTop = activeItemRect.top - containerRect.top;
				scrollContainer.scrollTo({ top: distanceFromTop, behavior: 'smooth' });
			}
		}
	};

	useEffect(() => {
		funcScrollProviderTab();

		if (!useEffectExecuted) {
			const gameFilterSearch = gameFilter?.split(', ')
			if (gameFilterSearch) {
				if (gameFilterSearch.length > 0) {
					setSelectedSubCategories(gameFilterSearch);
				}
			}

			setUseEffectExecuted(true);
		}
	}, [gameFilter, provider, selectedSubCategories, useEffectExecuted]);

	return (
		<>
			{contextHolder}
			<div className="amb-games-container">
				<div className="amb-games-filter">
					<Button
						key={`all`}
						size='small'
						onClick={() => handleSubCategoryClick('all')}
						className={selectedSubCategories.includes('all') ? 'active' : ''}
					>
						{findCategoryGame('all')}
					</Button>
					{categoryGame && categoryGame.length > 0 &&
						categoryGame.map((subCategory: any) => (
							gameType === "slot" && typeSlot.includes(subCategory.toUpperCase()) ? (
								<Button
									key={`filrer-${subCategory}`}
									size='small'
									onClick={() => handleSubCategoryClick(subCategory)}
									className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
								>
									{findCategoryGame(subCategory.toUpperCase())}
								</Button>
							) : (gameType === "fish" && typeFish.includes(subCategory.toUpperCase())) ? (
								<Button
									key={`filrer-${subCategory}`}
									size='small'
									onClick={() => handleSubCategoryClick(subCategory)}
									className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
								>
									{findCategoryGame(subCategory.toUpperCase())}
								</Button>
							) : (gameType === "casino" && typeCasino.includes(subCategory.toUpperCase())) && (
								<Button
									key={`filrer-${subCategory}`}
									size='small'
									onClick={() => handleSubCategoryClick(subCategory)}
									className={selectedSubCategories.includes(subCategory) ? 'active' : ''}
								>
									{findCategoryGame(subCategory.toUpperCase())}
								</Button>
							)
						))
					}
				</div>

				<div className="amb-games-content">
					{/* provider tab */}
					{allprovider && (
						<>
							<div className="amb-games-provider-tab-container" ref={ambkProviderTabContainerRef}>
								<div className="amb-games-provider-tab" ref={ambkProviderTabRef}>
									{Object.keys(allprovider).map((game: any, key: any) => (
										(allprovider[game].gameType.includes(gameType) && (
											<div
												key={`tab-provider-${allprovider[game].provider}-${key}`}
												onClick={() => {
													onChangeProvider(allprovider[game].providerKey)
													triggerLogin(allprovider[game].provider, "none", allprovider[game].games <= 0 ? true : false)
												}
												}
												className={`amb-games-provider-tab-item ${allprovider[game].providerKey === provider ? 'active' : ''}`}
											>
												{allprovider[game].logoMobileURL ? (
													<img src={allprovider[game].logoMobileURL} alt={allprovider[game].provider} />
												) : (
													<div className="provider-name-title"><span>{allprovider[game].provider}</span></div>
												)}
											</div>
										))
									))}
								</div>
							</div>
						</>
					)}

					{/* games */}
					{loadingProvider ? (
						<div className="ambbet-provider-skeleton-loading">
							{
								Array.from({ length: 24 }, (_, index) => (
									<div className="skeleton-body" key={index}>
										<div className="skeleton" />
									</div>
								))
							}
						</div>
					) : (
						<div style={{ flex: 'auto' }}>
							<div>
								<div className="ambking-games-topbar" ref={ambkTopbarRef}>
									<Space size={0}>
										<div
											onClick={() => setProvider('')}
											style={{ display: 'flex', fontSize: 20, padding: '8px 8px 8px 4px', cursor: 'pointer' }}
										>
											<i className="fa-solid fa-arrow-left"></i>
										</div>
										<Title level={5} className="title-provider" onClick={() => setProvider('')} style={{ cursor: 'pointer' }}>{games.providerName}</Title>
									</Space>
									<div className="search-games">
										<Input
											placeholder={`ค้นหาเกม ใน ${providerName}`}
											onChange={(e: any) => setFilterText(e.target.value)}
											prefix={<SearchOutlined style={{ fontSize: 18 }} />}
											style={{ padding: '8px 12px' }}
										/>
									</div>
								</div>
							</div>
							{(configGames && configGames.games.length > 0 ? (
								<div className="ambking-grid-row">
									{Object(configGames.games).map((game: any, key: number) => (
										<div key={`game_${provider}_${key}_${game.code}`} className={`ambking-grid-col ${game.code}`}>
											{pathName === "/launch-game" ? (
												<>
													{game.providerOther ? (
														<>
															<Link to={'/'} key={game.id} className="game-card-bf" onClick={triggerSlotLogin(games.productCode, game.gameCode, game.providerOther)}>
																<div className="game-card-img-container" />
																{game.code ? (
																	<img src={getGameCover(game.code)} alt={`${game.code}-${game.name}`} className="game-card-img" />
																) : (
																	<img src="/image-placeholder.png" alt={`${game.code}-${game.name}`} className="game-card-img" />
																)}
																<div className="game-card-name">{game.name}</div>
															</Link>
														</>
													) : (
														<Link to={'/'} key={game.id} className="game-card-bf" onClick={triggerSlotLogin(games.provider, game.code, game.providerOther)}>
															<div className="game-card-img-container" />
															{game.img.includes("https") ? (
																<ImageWithFallback
																	src={game.img.includes("spinix.com") ? game.img.replace("spinix.com", "x-gaming.com") : game.img}
																	alt={`${game.code}-${game.name}`}
																	fallbackSrc="/image-placeholder.png"
																	className="game-card-img"
																/>
															) : (
																<img src={`https://ardmhzelxcmj.ocrazeckyunc.com` + game.img} alt={`${game.code}-${game.name}`} className="game-card-img" />
															)}
															<div className="game-card-name">{game.name}</div>
														</Link>
													)}
												</>
											) : (pathName === "/home" && (
												<>
													{game.providerOther ? (
														<>
															<Link to={makePath('/login')} key={game.id} className="game-card-bf">
																<div className="game-card-img-container" />
																{game.code ? (
																	<img src={getGameCover(game.code)} alt={`${game.code}-${game.name}`} className="game-card-img" />
																) : (
																	<img src="/image-placeholder.png" alt={`${game.code}-${game.name}`} className="game-card-img" />
																)}
																<div className="game-card-name">{game.name}</div>
															</Link>
														</>
													) : (
														<>
															<Link to={makePath('/login')} key={game.id} className="game-card-bf">
																<div className="game-card-img-container" />
																{game.img.includes("https") ? (
																	<ImageWithFallback
																		src={game.img.includes("spinix.com") ? game.img.replace("spinix.com", "x-gaming.com") : game.img}
																		alt={`${game.code}-${game.name}`}
																		fallbackSrc="/image-placeholder.png"
																		className="game-card-img"
																	/>
																) : (
																	<img src={`https://ardmhzelxcmj.ocrazeckyunc.com` + game.img} alt={`${game.code}-${game.name}`} className="game-card-img" />
																)}
																<div className="game-card-name">{game.name}</div>
															</Link>
														</>
													)}

												</>
											))}
										</div>
									))}
								</div>
							)
								:
								<div style={{ display: 'flex', width: '100%' }}>
									<Empty
										description='ไม่พบเกมส์'
										image="/icons/new/empty-box.svg"
										imageStyle={{ height: 88 }}
										style={{ flex: 'auto', marginTop: 24 }}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div >
		</>
	)
}

export default GameList
