import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'

interface WalletMobileMenuProps {
	lineLink: string
	checkin: boolean
	wheel: boolean
	commission: boolean
	showMenuSupport?: boolean
	ranking?: boolean
	bonusFreeStatus?: boolean
	alert: () => void
	modalPromotions: () => void
	modalSupport: () => void
}
const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, checkin, wheel, commission, showMenuSupport, ranking, alert, modalPromotions, modalSupport, bonusFreeStatus }) => {
	return (
		<div className="menu-mobile-wrapper" style={{ marginTop: 24 }}>
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/deposit.svg`} alt="icon" />
					</div>
					เติมเงิน
				</Link>
			</div>
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/withdraw.svg`} alt="icon" />
					</div>
					ถอนเงิน
				</Link>
			</div>
			<div className="menu-mobile-col">
				<Link className="menu-mobile" to={makePath('/login')}>
					<div className="menu-mobile-icon">
						<img src={`/icons/webp/coupon.webp`} alt="icon" />
					</div>
					เติมโค้ด
				</Link>
			</div>
			{bonusFreeStatus && (
				<div className="menu-mobile-col">
					<Link className="menu-mobile" to={makePath('/login')}>
						<div className="menu-mobile-icon">
							<img src={`/icons/new/free.svg`} alt="icon" />
						</div>
						เครดิตฟรี
					</Link>
				</div>
			)}
			<div className="menu-mobile-col">
				<Link
					to={makePath('/login')}
					className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/history.svg`} alt="icon" />
					</div>
					ประวัติ
				</Link>
			</div>
			<div className="menu-mobile-col">
				<Link to={"#"} onClick={modalPromotions} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/new/promotion.svg`} alt="icon" />
					</div>
					โปรโมชั่น
				</Link>
			</div>
			{checkin && (
				<div className="menu-mobile-col">
					<Link to={makePath('/login')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/check-in.svg`} alt="wheel icon" />
						</div>
						เช็คอิน
					</Link>
				</div>
			)}
			{wheel && (
				<div className="menu-mobile-col">
					<Link
						to={makePath('/login')}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/wheel.svg`} alt="wheel icon" />
						</div>
						กงล้อ
					</Link>
				</div>
			)}
			{commission ? (
				<div className="menu-mobile-col">
					<Link
						to={makePath('/login')}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/affiliate.svg`} alt="icon" />
						</div>
						คอมมิชชั่น
					</Link>
				</div>
			) : (
				<div className="menu-mobile-col">
					<Link
						to={makePath('/login')}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/affiliate.svg`} alt="icon" />
						</div>
						ชวนเพื่อน
					</Link>
				</div>
			)}

			{ranking && (
				<div className="menu-mobile-col">
					<Link
						to={makePath('/login')}
						className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/ranking.svg`} alt="affiliate icon" />
						</div>
						จัดอันดับ
					</Link>
				</div>
			)}

			{!showMenuSupport && (
				<div className="menu-mobile-col">
					<Link to={"#"} onClick={modalSupport} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/help.svg`} alt="chat icon" />
						</div>
						ติดต่อเรา
					</Link>
				</div>
			)}

			{lineLink && showMenuSupport && (
				<div className="menu-mobile-col">
					<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/help.svg`} alt="icon" />
						</div>
						ติดต่อเรา
					</a>
				</div>
			)}

		</div>
	)
}

export default WalletMobileMenu
