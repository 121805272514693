import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import Marquee from 'react-fast-marquee';
import { useLocation } from 'react-router-dom';
import { ApplicationState } from '../../store'

interface Props {
    text: string
}
const Notice: FC<Props> = ({ text }) => {
    const loc = useLocation();
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const prefix: any = prefixState.info;
    const checkGame = (game:string)=>{
        if(game === "ambking"){
            return (loc.pathname === "/launch-game" || loc.pathname === "/home")
        }
        if(game === "betflix"){
            // if(prefix?.functions.betflixOnListgame){
            //     return (loc.pathname === "/launch-game" || loc.pathname === "/home")
            // }else{
            // }

            return (loc.pathname === "/launch-game" || loc.pathname === "/home")
        }
    }


    const isFUllNotice = checkGame(prefix.game)

    return (
        <div className={`notice-wrapper ${isFUllNotice ? "notice-full" : ""}`}>
            <div className="notice-icon">
                <img src="/icons/new/bullhorn.svg" alt="bullhorn icons" />
            </div>
            <div className="notice-text">
                <Marquee pauseOnHover gradient={false}>{text}</Marquee>
            </div>
        </div>
    )
}

export default Notice
