import React, { useEffect, useState } from 'react'
import { AMBKingConfig } from '../../entities/AMBKing'
import ProviderList from './Betflix/ProviderList'
import { Empty, Skeleton } from 'antd'
import { CategoryGameAMBKing1 } from '../../utils/categoryGame'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { useLocation, useHistory } from 'react-router-dom';
import MainSlider from '../Wallet/MainSlider'
import Footer from '../Layout/Footer'
import SeoContent from '../Layout/SeoContent'
import LoadingLayout from '../Layout/LoadingLayout'
import Popup from '../Popup'
import WalletPromotionContainer from '../Home/WalletPromotionContainer'
import CheckInModal from '../CheckIn/CheckInModal'
import GameList from './Betflix/GameList'
import { getImgGameTypeBetflix } from '../../utils/imgProviderBetFlix'

const BetflixHome = () => {
    const location = useLocation();
    const history = useHistory();

    const [config, setConfig] = useState<AMBKingConfig | undefined>(undefined)
    const [provider, setProvider] = useState("")
    const [gameType, setGameType] = useState<string>('slot')
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingAMBKing, setLoadingAMBKing] = useState<boolean>(false)
    const [allProvider, setAllProvider] = useState<any>(undefined)
    const [loadingProvider, setLoadingProvider] = useState<boolean>(false)

    const providerState = useSelector((state: ApplicationState) => state.provider)
    const providerHomeState = useSelector((state: ApplicationState) => state.gamesHome)
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    useEffect(() => {
        if (providerHomeState?.info !== undefined) {
            const dataObject = Object.keys(providerHomeState?.info?.data)
                .map(key => ({ key, value: providerHomeState?.info?.data[key] }))
                .sort((a, b) => a.value.order - b.value.order)
                .reduce((acc: any, { key, value }) => {
                    acc[key] = value;
                    return acc;
                }, {});

            // เก็บ array ที่เรียงแล้วใน state
            setConfig(providerHomeState?.info)

            setAllProvider(dataObject)

            if (Object.values(providerHomeState?.info?.data).length === 1) {
                setGameType(Object.keys(providerHomeState?.info?.data)[0]);
            }

            setGameType(providerHomeState?.info.tabs.filter((item: any) => item.enable === true)[0].game)
            // const params = new URLSearchParams(location.search);
            // params.set('gameType', providerHomeState?.info.tabs.filter((item: any) => item.enable === true)[0].game);
            // history.push({ search: params.toString() });

            setLoadingAMBKing(true)
        }
    }, [providerState?.info, providerHomeState?.info, location]) // eslint-disable-line

    useEffect(() => {
        if (!provider) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [provider])

    // get query string
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const gameTypeQuery = params.get('gameType');

        if (gameTypeQuery) {
            setGameType(gameTypeQuery);
        }

        setLoading(false)
    }, [location.search]);

    if (!config) {
        return (
            <div>
                <div className="ambbet-topbar-skeleton">
                    <div className="amb-button-skeleton">
                        {
                            Array.from({ length: 10 }, (_, index) => (
                                <Skeleton.Button key={index} active />
                            ))
                        }
                    </div>
                </div>

                <div className="ambbet-provider-skeleton">
                    {
                        Array.from({ length: 24 }, (_, index) => (
                            <Skeleton.Button key={index} active />
                        ))
                    }
                </div>
            </div>
        )
    }

    const handleGameType = (game: string) => {
        const params = new URLSearchParams(location.search);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setProvider("")
        setGameType(game)
        params.set('gameType', game);
        history.push({ search: params.toString() });
    }

    const desiredOrder = ['สล็อต', 'คาสิโน', 'กีฬา', 'ปลา'];

    const sortedTabs = providerHomeState?.info?.tabs.sort((a: any, b: any) => {
        return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
    });

    const TabGameType = loadingAMBKing && sortedTabs.filter((item: any) => item.enable === true).map((x: any, key: number) => {
        return (
            <div id={x.key} className={`ant-tabs-tab ${x.game === gameType ? 'ant-tabs-tab-active' : ''}`} key={`menu-tab-${x.game}`} onClick={() => { handleGameType(x.game) }}>
                <div className="ant-tabs-tab-btn">
                    <div className="menu-tab">
                        <div className="icon">
                            <img src={getImgGameTypeBetflix(x.game)} alt="nav" />
                        </div>
                        <span>
                            <strong>{x.label}</strong>
                        </span>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="ambking-container">
            <MainSlider prefixState={prefixState} />

            <div className="ant-tabs ant-tabs-top ambking-new-tab">
                {sortedTabs.filter((item: any) => item.enable === true).length > 1 && (
                    <div className="ant-tabs-nav betflix-list">
                        <div className="ant-tabs-nav-list">
                            {TabGameType}
                        </div>
                    </div>
                )}
                <div className="ambking-tab betflix-tab">
                    {loading ? (
                        <LoadingLayout prefix={prefixState} />
                    ) : (
                        !provider ? (
                            (allProvider !== undefined ? (
                                <ProviderList
                                    key={`ProviderList_${gameType}_${gameType}`}
                                    config={allProvider}
                                    type={gameType}
                                    setProvider={setProvider}
                                    setGameType={setGameType}
                                />
                            ) :
                                <div style={{ display: 'flex', width: '100%', marginTop: '12px' }}>
                                    <Empty
                                        description='ไม่พบเกมส์'
                                        image="/icons/new/empty-box.svg"
                                        imageStyle={{ height: 88 }}
                                        style={{ flex: 'auto', marginTop: 24 }}
                                    />
                                </div>
                            )
                        ) : (
                            <GameList
                                games={allProvider[provider]}
                                allprovider={allProvider}
                                provider={provider as string}
                                gameType={gameType}
                                loadingProvider={loadingProvider}
                                setProvider={setProvider}
                                setLoadingProvider={setLoadingProvider}
                                setLoading={setLoading}
                                pathName={location.pathname}
                            />
                        )
                    )}
                </div>
            </div>

            {/* Promotion */}
            {(prefixState.info?.promotions.length || 0) > 0 && !prefixState.info?.functions.homePromotionPage && location.pathname === '/home' && (
                <WalletPromotionContainer promotions={prefixState.info?.promotions} />
            )}

            {location.pathname === '/home' && <SeoContent />}

            <Footer prefixState={prefixState} />

            {/* Popup */}
            <Popup />

            {/* CheckIn */}
            {prefixState.info?.checkin.enable && !prefixState.info?.checkin.pupupStartDisable && <CheckInModal />}

        </div>
    )
}

export default BetflixHome
