import React, { createContext, FC, useContext, useState } from 'react';

interface AppContextProps {
    // เปิด/ปิด Popup ผลการรับโบนัสฟรี
    isOpenRedeemSuccess: boolean;
    setIsOpenRedeemSuccess: (value: boolean) => void;

    // เปิด/ปิด Popup ผลการรับโบนัสฟรี
    redeemAmount: number;
    setRedeemAmount: (value: number) => void;

    // โหลดรูปภาพสำหรับ Popup ผลการรับโบนัสฟรี
    isLoadedImagesRedeemSuccess: boolean;
    setIsLoadedImagesRedeemSuccess: (value: boolean) => void;

    // เปิด/ปิด Popup Form เติมโค้ด
    isOpenRedeemDialog: boolean;
    setIsOpenRedeemDialog: (value: boolean) => void;

    // เปิด/ปิด Modal
    isModalSocket: boolean;
    setIsModalSocket: (value: boolean) => void;

    // เปิด/ปิด Modal Checkin
    isModalCheckin: boolean;
    setIsModalCheckin: (value: boolean) => void;

    // Extra Text Success Redeem
    extraRedeemSuccess: string;
    setExtraRedeemSuccess: (value: string) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: FC = ({ children }) => {
    // เปิด/ปิด Popup ผลการรับโบนัสฟรี
    const [isOpenRedeemSuccess, setIsOpenRedeemSuccess] = useState<boolean>(false)

    // จำนวนเงินที่ได้รับจากโบนัสฟรี
    const [redeemAmount, setRedeemAmount] = useState<number>(0)

    // โหลดรูปภาพสำหรับ Popup ผลการรับโบนัสฟรี
    const [isLoadedImagesRedeemSuccess, setIsLoadedImagesRedeemSuccess] = useState<boolean>(false)

    // เปิด/ปิด Popup Form เติมโค้ด
    const [isOpenRedeemDialog, setIsOpenRedeemDialog] = useState<boolean>(false)

    // เปิด/ปิด Modal
    const [isModalSocket, setIsModalSocket] = useState<boolean>(true)

    // เปิด/ปิด Modal Checkin
    const [isModalCheckin, setIsModalCheckin] = useState<boolean>(false)

    // เปิด/ปิด Modal Checkin
    const [extraRedeemSuccess, setExtraRedeemSuccess] = useState<string>("")

    return (
        <AppContext.Provider value={{
            isOpenRedeemSuccess,
            setIsOpenRedeemSuccess,
            redeemAmount,
            setRedeemAmount,
            isLoadedImagesRedeemSuccess,
            setIsLoadedImagesRedeemSuccess,
            isOpenRedeemDialog,
            setIsOpenRedeemDialog,
            isModalSocket,
            setIsModalSocket,
            isModalCheckin,
            setIsModalCheckin,
            extraRedeemSuccess,
            setExtraRedeemSuccess
        }}>
            {children}
        </AppContext.Provider>
    );
};

// สร้าง Custom Hook เพื่อใช้งาน AppContext
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};