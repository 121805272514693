import React, { useEffect } from 'react'
import { urlLineLink } from '../@types/member';
import api from '../helpers/api'
import { toast } from 'react-toastify'

const LineLogin = () => {

    const getLinkLine = async () => {
        try {
            const res = await api.get<void, urlLineLink>('/generate-link-line')
            window.location.href = res.url;
        } catch ({ message }: any) {
            toast.error(message as string)
        }
    }

    useEffect(() => {
        getLinkLine()
    }, [])

    return (
        <></>
    )
}

export default LineLogin