import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Col, Row, Space, Typography } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const { Link, Text } = Typography

const Support = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const prefix = prefixState.info

    const [lineID, setLineID] = useState<string>("")
    const [instagram, setInstagram] = useState<string>("")

    useEffect(() => {
        if (prefix?.socialMedia) {
            if (prefix?.socialMedia.line) {
                const re = "line://ti/p/"
                setLineID(prefix?.socialMedia.line.replace(re, ""))
            }
            if (prefix?.socialMedia.instagram) {
                const re = "https://www.instagram.com/"
                const ig = prefix?.socialMedia.instagram.replace(re, "@")
                setInstagram(ig.replace("/", ""))
            }
        }
    }, [prefix])


    return (

        <Row justify="center" className="margin-form-card">
            <Col xs={24} md={16} xl={12}>
                <div className="form-card">
                    <div className="form-card-body">
                        <Row gutter={[{ xs: 16, sm: 24 }, { xs: 16, sm: 24 }]}>
                            {prefix?.socialMedia && prefix?.socialMedia.phoneNumber &&
                                <Col xs={24} sm={12}>
                                    <Link href={`tel:${prefix?.socialMedia.phoneNumber}`}>
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/phone.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'phone'}
                                                    effect={'blur'}
                                                    src={`contacts/phone.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Space direction="vertical">
                                                    <Text strong>เบอร์โทรศัพท์</Text>
                                                    {prefix?.socialMedia.phoneNumber}
                                                </Space>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {prefix?.socialMedia && prefix?.socialMedia.line &&
                                <Col xs={24} sm={12}>
                                    <Link href={prefix?.socialMedia.line} target="_blank" rel="noreferrer">
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/line.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'line'}
                                                    effect={'blur'}
                                                    src={`contacts/line.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Space direction="vertical">
                                                    <Text strong>Line</Text>
                                                    {lineID}
                                                </Space>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {prefix?.socialMedia && prefix?.socialMedia.telegram &&
                                <Col xs={24} sm={12}>
                                    <Link href={prefix?.socialMedia.telegram} target="_blank" rel="noreferrer">
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/telegram.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'telegram'}
                                                    effect={'blur'}
                                                    src={`contacts/telegram.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Text strong>Telegram</Text>
                                                {/* {prefix?.socialMedia.telegram} */}
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {prefix?.socialMedia && prefix?.socialMedia.facebook &&
                                <Col xs={24} sm={12}>
                                    <Link href={prefix?.socialMedia.facebook} target="_blank" rel="noreferrer">
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/facebook.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'facebook'}
                                                    effect={'blur'}
                                                    src={`contacts/facebook.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Text strong>Facebook</Text>
                                                {/* {prefix?.socialMedia.facebook} */}
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {prefix?.socialMedia && prefix?.socialMedia.x &&
                                <Col xs={24} sm={12}>
                                    <Link href={prefix?.socialMedia.x} target="_blank" rel="noreferrer">
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/facebook.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'x'}
                                                    effect={'blur'}
                                                    src={`contacts/x.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Text strong>X(Twitter)</Text>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }

                            {prefix?.socialMedia && prefix?.socialMedia.instagram &&
                                <Col xs={24} sm={12}>
                                    <Link href={prefix?.socialMedia.instagram} target="_blank" rel="noreferrer">
                                        <div className="contact-card">
                                            <div className="contact-icon">
                                                {/* <img src={`contacts/ig.svg`} alt="" /> */}
                                                <LazyLoadImage
                                                    alt={'ig'}
                                                    effect={'blur'}
                                                    src={`contacts/ig.svg`}
                                                />
                                            </div>
                                            <div className="contact-text">
                                                <Space direction="vertical">
                                                    <Text strong>Instagram</Text>
                                                    {/* {instagram} */}
                                                </Space>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
        // <Row justify="center">
        //     <Col xs={24} md={16}>

        //     </Col>
        // </Row>
    );
};

export default Support;
