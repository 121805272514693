import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Prefix } from '../../@types/prefix'
import { requestPrefixDone, requestPrefixFailed } from './prefix.actions'

export interface State {
	isLoaded: boolean
	info?: Prefix
}

export const defaultState: State = {
	isLoaded: false,
	info: undefined,
}

export default reducerWithInitialState(defaultState)
	.case(requestPrefixDone, (state, payload: Prefix) => ({
		...state,
		isLoaded: true,
		info: payload,
	}))
	.case(requestPrefixFailed, (state, _) => ({
		...state,
		isLoaded: true,
		info: undefined,
	}))
	.default((state) => state)
