import React, { FC, MouseEvent, useState } from 'react'
import api from '../../helpers/api'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import { requestPrefix } from '../../store/prefix/prefix.actions'
import { requestUser } from '../../store/user/user.actions'
import ModalTermsAll from '../../components/Elements/ModalTermsAll';

import { useAppContext } from '../../contexts/AppContext';

interface WalletMobileMenuProps {
	lineLink: string
	checkin: boolean
	wheel: boolean
	commission: boolean
	showMenuSupport?: boolean
	ranking?: boolean
	bonusFreeStatus?: boolean
	prefixState: any
}

interface IBonusFree {
	amount: number
	message: string
}

const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, checkin, wheel, commission, showMenuSupport, ranking, prefixState, bonusFreeStatus }) => {
	const dispatch: ThunkDispatch = useDispatch()
	const [openTerms, setOpenTerms] = useState<boolean>(false)
	const handleModalTerms = () => setOpenTerms(!openTerms)

	const {
		setIsOpenRedeemSuccess,
		setRedeemAmount,
		setIsLoadedImagesRedeemSuccess,
		setIsOpenRedeemDialog
	} = useAppContext();


	const handleOpenRedeemDialog = () => {
		setIsOpenRedeemDialog(true)
	}

	const handleOpenRedeemSuccess = async (e: MouseEvent<any>) => {
		e.preventDefault()
		try {
			const res = await api.post<void, IBonusFree>('/member/bonus-free')

			setRedeemAmount(res.amount)
			dispatch(requestPrefix())
			dispatch(requestUser())
			setIsOpenRedeemSuccess(true)
			setIsLoadedImagesRedeemSuccess(true)
		} catch ({ message }: any) {
			toast.error(message as string)
			setIsOpenRedeemSuccess(false)
			setIsLoadedImagesRedeemSuccess(false)
		}
	}
	return (
		<>
			<div className="menu-mobile-wrapper">
				<div className="menu-mobile-col">
					<Link to={makePath('/deposit')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/deposit.svg`} alt="icon" />
						</div>
						เติมเงิน
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={makePath('/withdraw')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/withdraw.svg`} alt="icon" />
						</div>
						ถอนเงิน
					</Link>
				</div>
				<div className="menu-mobile-col">
					<div className="menu-mobile" onClick={handleOpenRedeemDialog}>
						<div className="menu-mobile-icon">
							<img src={`/icons/webp/coupon.webp`} alt="icon" />
						</div>
						เติมโค้ด
					</div>
				</div>
				{bonusFreeStatus && (
					<div className="menu-mobile-col">
						<div className="menu-mobile" onClick={handleOpenRedeemSuccess}>
							<div className="menu-mobile-icon">
								<img src={`/icons/new/free.svg`} alt="icon" />
							</div>
							เครดิตฟรี
						</div>
					</div>
				)}
				<div className="menu-mobile-col">
					<Link to={makePath('/transactions')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/history.svg`} alt="icon" />
						</div>
						ประวัติ
					</Link>
				</div>
				<div className="menu-mobile-col">
					<Link to={makePath('/promotion')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/promotion.svg`} alt="icon" />
						</div>
						โปรโมชั่น
					</Link>
				</div>
				{checkin && (
					<div className="menu-mobile-col">
						<Link to={makePath('/check-in')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/check-in.svg`} alt="wheel icon" />
							</div>
							เช็คอิน
						</Link>
					</div>
				)}
				{wheel && (
					<div className="menu-mobile-col">
						<Link to={makePath('/lucky-wheel')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/wheel.svg`} alt="wheel icon" />
							</div>
							กงล้อ
						</Link>
					</div>
				)}
				{commission ? (
					<div className="menu-mobile-col">
						<Link to={makePath('/commission')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/affiliate.svg`} alt="icon" />
							</div>
							คอมมิชชั่น
						</Link>
					</div>
				) : (
					<div className="menu-mobile-col">
						<Link to={makePath('/affiliate')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/affiliate.svg`} alt="icon" />
							</div>
							ชวนเพื่อน
						</Link>
					</div>
				)}
				{ranking && (
					<div className="menu-mobile-col">
						<Link to={makePath('/ranking')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/ranking.svg`} alt="affiliate icon" />
							</div>
							จัดอันดับ
						</Link>
					</div>
				)}
				<div className="menu-mobile-col">
					<Link to={makePath('/profile')} className="menu-mobile">
						<div className="menu-mobile-icon">
							<img src={`/icons/new/account.svg`} alt="icon" />
						</div>
						โปรไฟล์
					</Link>
				</div>

				{!showMenuSupport && (
					<div className="menu-mobile-col">
						<Link to={makePath('/support')} className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/help.svg`} alt="chat icon" />
							</div>
							ติดต่อเรา
						</Link>
					</div>
				)}

				{lineLink && showMenuSupport && (
					<div className="menu-mobile-col">
						<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
							<div className="menu-mobile-icon">
								<img src={`/icons/new/help.svg`} alt="icon" />
							</div>
							ติดต่อเรา
						</a>
					</div>
				)}

				{(prefixState.info?.termandcondition?.wheelDescription ||
					prefixState.info?.termandcondition?.cashbackDescription ||
					prefixState.info?.termandcondition?.affiliateDescription ||
					prefixState.info?.termandcondition?.termandconditionDescription) && (
						<div className="menu-mobile-col">
							<Link to={"#"} className="menu-mobile" onClick={handleModalTerms}>
								<div className="menu-mobile-icon">
									<img src={`/icons/new/info.svg`} alt="chat icon" />
								</div>
								กติกา
							</Link>
						</div>
					)}
			</div>
			<ModalTermsAll isOpen={openTerms} onClose={() => setOpenTerms(false)} />
		</>
	)
}
export default WalletMobileMenu
