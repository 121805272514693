import React, { useLayoutEffect } from 'react'
import HomeLayout from '../../components/Layout/HomeLayout'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import Footer from '../../components/Layout/Footer'

const WhyUs = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)

    useLayoutEffect(() => {
        setTimeout(() => {
            document.title = 'สล็อตเว็บตรง กับ VEGA98 ทำไมต้องเล่นกับเรา ?';
        }, 300);
    }, []);

    return (
        <HomeLayout>
            <div className="achieve-container">
                <h1>สล็อตเว็บตรง กับ VEGA98 ทำไมต้องเล่นกับเรา ?</h1>
                <img src="/vega98/why-vega98.webp" alt="ทำไมต้องเล่น กับ vega98" style={{ marginBottom: 16 }} />
                <p>เว็บไซต์ <b>VEGA98</b> ถือเป็นตัวเลือกยอดนิยมสำหรับนักเดิมพันที่ชื่นชอบการเล่นเกม <b>สล็อตเว็บตรง</b> โดยเฉพาะในไทย จุดเด่นของเราคือความน่าเชื่อถือที่มาพร้อมรากฐานที่มั่นคง ทำให้ทุกท่านมั่นใจในการเดิมพัน ได้รับเงินจริง 100% สำหรับข้อแตกต่างของเราเมื่อเทียบกับเว็บไซต์อื่นๆ มีดังนี้</p>

                <h2>1.เว็บใหญ่ การันตีความมั่นคง</h2>
                <p>เรามีผู้เล่นมากกว่า 10,000 คน ที่คุณสามารถเชื่อมั่นได้อย่างเต็มที่ ด้วยใบรับรองถูกต้องตามกฎหมาย หากคุณมองหาเว็บสล็อตที่ปลอดภัย และมั่นคง ทางเราคือคำตอบที่ดีที่สุด</p>

                <h2>2.สล็อตเว็บตรง ที่ไม่ผ่านเอเย่นต์</h2>
                <p>เว็บของเราเป็นศูนย์กลางเกม <b>สล็อตเว็บตรง</b>ที่ไม่ผ่านเอเย่นต์ ลิขสิทธิ์แท้ 100% ด้วยมาตรฐานความปลอดภัยระดับสูง ทำให้ผู้เล่นมั่นใจในการใช้งานและทำกำไรได้อย่างสบายใจ เรายังการันตีความปลอดภัย 100% สำหรับผู้ใช้ทุกคน</p>

                <h2>3.รองรับการฝากถอน ทรูวอลเลท</h2>
                <p>เว็บไซต์ของเรามีบริการฝากถอนหลากหลายช่องทาง ไม่ว่าจะผ่านธนาคารทุกแห่ง หรือ ทรูวอลเลท โดยเว็บสล็อตของเรานั้นเป็นเว็บตรง แตกง่าย ไม่มีขั้นต่ำ จึงรองรับทุกความต้องการของผู้เล่นได้อย่างครบถ้วน ทำให้ทุกคนสามารถเลือกใช้ช่องทางฝากถอนได้ตามความสะดวก</p>

                <h2>4.เล่นได้ทุกเกม โดยไม่ต้องโยกเงิน</h2>
                <p>คุณสามารถสลับเกมได้ทันทีโดยไม่ต้องย้ายเงินให้ยุ่งยาก เรามีกระเป๋าเงินเดียวที่รองรับการเล่นทุกเกมบนเว็บ สะดวกสบาย ไม่มีขั้นตอนที่ซับซ้อน</p>

                <h2>5.จ่ายจริง ไม่มีโกง</h2>
                <p>เมื่อคุณชนะเดิมพันในการเล่นเกม<b>สล็อตเว็บตรง</b>กับ<b>VEGA98</b> ไม่ว่าจำนวนเงินจะมากแค่ไหน คุณสามารถถอนกำไรได้ไม่อั้น ทุกยอดเดิมพันที่ชนะจะถูกจ่ายออกจริงทุกบาททุกสตางค์ ไม่มีการโกงแน่นอน</p>
            </div>
            <Footer prefixState={prefixState} />
        </HomeLayout>
    )
}

export default WhyUs
