import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Transaction } from '../../@types/transaction'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { ApplicationState } from '../../store'
import { makePath } from '../../helpers/path'
import StatusBadge from './StatusBadge'
import { Button, Empty, Flex, Typography } from 'antd'

const { Text } = Typography

interface DepositListProps {
	data: Transaction[]
}
const DepositList: FC<DepositListProps> = ({ data }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)

			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	if (data.length <= 0) {
		return (
			<div style={{ padding: '24px 0' }}>
				<Empty
					description='คุณไม่มีรายการฝากเงิน'
					image="/icons/new/empty-box.svg"
					imageStyle={{ height: 88 }}
				/>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => (
				<div className="transaction-card" key={tx.id}>
					<div className="transaction-header">
						<div className="transaction-info">
							<div className="transaction-title">เติมเงิน</div>
						</div>
						<div className="transaction-title">฿ {format(tx.amount)}</div>
					</div>
					<div className="transaction-body-remark">
						<Text>
							<Flex gap={'small'} align='center'>จาก
								<img
									src={`/bank-logo/${(tx.transferFrom).toLowerCase()}.svg`}
									alt={tx.transferFrom}
									height={24}
									width={24}
								/> {getBankName(tx.transferFrom)}
							</Flex>
						</Text>
					</div>
					<div className="transaction-body">
						<div className="tag-default">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</div>
						<StatusBadge status={tx.status} />
					</div>

					{tx.remark !== "" && (
						<div className="transaction-footer">
							<div className="transaction-remark">หมายเหตุ : {tx.remark}</div>

							{showMenuSupportLine && (
								<a href={prefixState.info?.socialMedia['line']} target="_blank" rel="noreferrer">
									<Button type="primary" style={{ width: '100%' }}>
										<i className="fa-regular fa-headset" style={{ fontSize: 18, marginRight: 12 }}></i>
										<span>ติดต่อเจ้าหน้าที่</span>
									</Button>
								</a>
							)}

							{!prefix?.lineLink && !showMenuSupport && !showMenuSupportLine && (
								<NavLink to={makePath('/support')}>
									<Button type="primary" style={{ width: '100%' }}>
										<i className="fa-regular fa-headset" style={{ fontSize: 18, marginRight: 12 }}></i>
										<span>ติดต่อเจ้าหน้าที่</span>
									</Button>
								</NavLink>
							)}

							{prefix?.lineLink && !showMenuSupportLine && (
								<a href={prefix.lineLink} target="_blank" rel="noreferrer">
									<Button type="primary" style={{ width: '100%' }}>
										<i className="fa-regular fa-headset" style={{ fontSize: 18, marginRight: 12 }}></i>
										<span>ติดต่อเจ้าหน้าที่</span>
									</Button>
								</a>
							)}
						</div>
					)}
				</div>
			))}
		</>
	)
}

export default DepositList
